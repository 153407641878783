import dayjs from "dayjs";

export default function addHistoryData(lessons, lessonHistories) {
  let newLessons = [];
  for (const [index, item] of lessons.entries()) {
    let maxDate = "";
    let minDate = "";
    let futureDates = lessonHistories
      .filter((pd) => {
        return pd.lesson_id === item.lesson_id;
      })
      .filter((pd) => {
        return pd.class_date > dayjs(new Date()).format();
      });
    let pastDates = lessonHistories
      .filter((pd) => {
        return pd.lesson_id === item.lesson_id;
      })
      .filter((pd) => {
        return pd.class_date < dayjs(new Date()).format();
      });
    if (pastDates.length > 0) {
      maxDate = new Date(
        Math.max(
          ...pastDates.map((element) => {
            return new Date(element.class_date);
          })
        )
      );
    }
    if (futureDates.length > 0) {
      minDate = new Date(
        Math.min(
          ...futureDates.map((element) => {
            return new Date(element.class_date);
          })
        )
      );
    }
    item["last_class"] = maxDate;
    item["past_class_count"] = pastDates.length;
    item["future_class_count"] = futureDates.length;
    item["next_class"] = minDate;
    newLessons = lessons.with(index, item);
  }
  return newLessons;
}
