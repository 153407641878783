export default function getLessonFromCache(lesson_id) {
  let levels = JSON.parse(localStorage.getItem("levels"));
  let lesson = "";
  for (const level of levels) {
    let levelId = level.level_id;
    let levelLessons = JSON.parse(localStorage.getItem("level_" + levelId));
    lesson = levelLessons.find((obj) => {
      return obj.lesson_id === lesson_id;
    });
    if (lesson !== undefined) {
      break;
    }
  }
  return lesson;
}
