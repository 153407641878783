import { useState, useRef, useEffect } from "react";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function PasswordReset({ userSettings, setMfaStatus }) {
  const [passwordValue, setPasswordValue] = useState();
  const [passwordValid, setPasswordValid] = useState(false);
  const [confirmPasswordValue, setConfirmPasswordValue] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [resetMessage, setResetMessage] = useState("");
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  function checkPasswordCompliance() {
    let validationRegex = [
      { regex: /.{8,}/ }, // min 8 letters,
      { regex: /[0-9]/ }, // numbers 0 - 9
      { regex: /[a-z]/ }, // letters a - z (lowercase)
      { regex: /[A-Z]/ }, // letters A-Z (uppercase),
    ];
    validationRegex.forEach((regex) => {
      if (regex.regex.test(passwordValue)) {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
    });
  }

  const onResetPassword = async (e) => {
    e.preventDefault();
    if (!passwordValue) {
      passwordRef.current.focus();
    } else if (!confirmPasswordValue) {
      confirmPasswordRef.current.focus();
    } else if (passwordValue !== confirmPasswordValue) {
      setResetMessage("Your passwords don't match.");
    } else if (!passwordValid) {
      confirmPasswordRef.current.focus();
      setResetMessage("Your password is not valid.");
    } else {
      let newPassword = {
        admin_id: userSettings.admin_id,
        new_password: passwordValue,
      };
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/reset-password`;
      const response = await fetchData(url, "PUT", newPassword);
      if (response) {
        setResetMessage("Password updated.");
        setPasswordValue("");
        setConfirmPasswordValue("");
      } else {
        setResetMessage("Whoops, something went wrong, please try again.");
      }
    }
  };

  function onResetMfa(e) {
    e.preventDefault();
    setMfaStatus(false);
  }

  useEffect(() => {
    checkPasswordCompliance();
  }, [passwordValue]);

  return (
    <div>
      <h3>Password reset </h3>
      <div className="password-compliance-text">
        <p className="mfa-enabled-text">MFA is enabled.</p>
        To reset you password, please enter a new password of at least 8
        characters. The password must contain at least one uppercase and one
        lowercase letter, and one number.
      </div>
      <form className="password-form">
        <div className="form-group">
          <input
            ref={passwordRef}
            name="password"
            id="password"
            className="schedule-form-password"
            type={showPassword ? "text" : "password"}
            value={passwordValue}
            onChange={(e) => setPasswordValue(e.target.value)}
            placeholder="Password"
          />
        </div>
        <div className="form-group">
          <input
            ref={confirmPasswordRef}
            name="confirmPassword"
            id="confirmPassword"
            className="schedule-form-password"
            type={showPassword ? "text" : "password"}
            value={confirmPasswordValue}
            onChange={(e) => setConfirmPasswordValue(e.target.value)}
            placeholder="Confirm Password"
          />
        </div>
        <div className="form-group">
          <label className="form-check-label" htmlFor="showpasswordcheck">
            <input
              type="checkbox"
              checked={showPassword}
              className="login-show-password-checkbox"
              name="showpasswordcheck"
              id="showpasswordcheck"
              onChange={() => setShowPassword(!showPassword)}
            />
            Show passwords
          </label>
        </div>
        <div className="form-group">
          <button
            className="schedule-form-button"
            onClick={(e) => onResetPassword(e)}
          >
            Reset Password
          </button>
          <button
            className="schedule-form-button"
            onClick={(e) => onResetMfa(e)}
          >
            Reset MFA
          </button>
        </div>
        <div className="message-div">{resetMessage}</div>
      </form>
    </div>
  );
}
