import { useEffect, useState } from "react";
import NewModalCloseButton from "./NewModalCloseButton";

export default function PublishErrorList({ errorList, setShowNewModal }) {
  const [errorMessage, setErrorMessage] = useState();
  const [errors, setErrors] = useState([]);

  // If results = false, message either schedule and/or (?) 'class_list' array of objects with id and error
  const getErrors = () => {
    if (errorList.class_list) {
      const errors = errorList.class_list.map((error, index) => {
        return (
          <div className="modal-clashlist-item" key={index}>
            {!["One", "Two"].includes(error.error)
              ? error.error
              : error.error + " with [OBS Class ID]"}
          </div>
        );
      });
      setErrors(errors);
      setErrorMessage(errors);
    }
  };

  useEffect(() => {
    getErrors();
  }, []);

  return (
    <div className="centered-column">
      <h3>Whoops!</h3>
      <p>
        You have {errors.length} clash{errors.length > 1 && "es"}
      </p>
      <div id="modal-content" className="modal-clashlist">
        {errorMessage}
      </div>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </div>
  );
}
