import React, { useEffect, useState } from "react";

import Select from "react-select";
import "./style/selectors.css";

export default function ContentTypeSelector({
  centre,
  classObj,
  handleClassObj,
  isSelecting,
  isLoaded,
}) {
  const [contentTypeOptions, setContentTypeOptions] = useState([]);

  const clearDependencies = () => {
    if (!isSelecting) {
      classObj.contentLevel = "";
      classObj.pathway = "";
      classObj.theme = "";
      classObj.lesson = "";
    }
  };

  const contentOptions = [
    { value: 1, label: "Themes" },
    { value: 3, label: "Lesson groups" },
    { value: 2, label: "Activities" },
  ];

  /* Clear dependent content elements before handling change */
  const handleContentTypeChange = (data) => {
    clearDependencies();
    handleClassObj(data, { name: "contentType" }, false);
  };

  const loadContentTypeOptions = () => {
    const options = contentOptions.map((option) => {
      return {
        value: option.value,
        label: option.label,
      };
    });
    setContentTypeOptions(options);
  };

  useEffect(() => {
    loadContentTypeOptions();
  }, [centre]);

  return (
    <Select
      className="schedule-form-content-type-selector"
      value={classObj.contentType}
      placeholder="Content type"
      cacheOptions
      isDisabled={!isLoaded}
      options={contentTypeOptions}
      onChange={(data) => handleContentTypeChange(data)}
    />
  );
}
