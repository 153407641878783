import CryptoJS from "crypto-js";

export default function encrypt(mode, str) {
  if (str) {
    let _key = process.env.REACT_APP_ENCRYPTION_KEY;
    if (mode === "encrypt") {
      return CryptoJS.AES.encrypt(str, _key).toString();
    } else if (mode === "decrypt") {
      return CryptoJS.AES.decrypt(str, _key).toString(CryptoJS.enc.Utf8);
    }
  }
}
