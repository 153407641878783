import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import fetchData from "./scripts/fetchData.js";
import "./style/selectors.css";

export default function ThemeSelector({
  centre,
  classObj,
  handleClassObj,
  isSelecting,
  setIsSelecting,
}) {
  const [strandOptions, setStrandOptions] = useState([]);
  const [strandPlaceholder, setStrandPlaceholder] = useState();
  const selectRef = useRef();

  function getLocales() {
    let locales = [];
    if (centre) {
      Object.keys(centre).forEach((key) => {
        ["centre_code", "country_code", "cluster"].includes(key) &&
          centre[key] &&
          locales.push(centre[key].toUpperCase());
      });
      locales.push("GBL");
      return locales.reverse();
    } else {
      return ["GBL"];
    }
  }

  const getStrandOptions = async () => {
    if (classObj.contentType?.value === 3) {
      if (!isSelecting) {
        selectRef.current.clearValue();
        setStrandPlaceholder("Lesson group");
      }
      let localeArray = getLocales();
      let strandLevel = classObj.contentLevel?.label;
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands/${strandLevel}`;
      const response = await fetchData(url, "POST", localeArray);
      if (response) {
        const strandList = response.map((option) => {
          return {
            value: option._id,
            label:
              option.strand_code +
              ": " +
              option.strand_name +
              " (" +
              option.lessons?.length +
              ")",
          };
        });
        setStrandOptions(strandList);
      }
    }
  };

  useEffect(() => {
    getStrandOptions();
  }, [classObj.contentLevel]);

  return (
    <Select
      ref={selectRef}
      className="schedule-form-strand-selector"
      value={classObj.strand}
      isSearchable
      placeholder={strandPlaceholder}
      options={strandOptions}
      onFocus={() => setIsSelecting(false)}
      onChange={(data) => handleClassObj(data, { name: "strand" })}
    />
  );
}
