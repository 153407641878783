import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import "./style/selectors.css";
import setSyllabusLevelCache from "./scripts/setCountryLevelCache.js";

export default function ThemeSelector({
  centre,
  classObj,
  handleClassObj,
  isSelecting,
  setIsSelecting,
  isLoaded,
}) {
  const [themeOptions, setThemeOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Choose theme..");
  const selectRef = useRef();

  const getThemeOptions = async () => {
    if (classObj.contentType?.value === 1) {
      if (!isSelecting) {
        setThemeOptions([]);
        selectRef.current.clearValue();
      }
      if (centre && classObj.contentLevel && classObj.pathway) {
        let themes = [];
        const levelId = classObj.contentLevel.value;
        const pathway = classObj.pathway.label;
        themes = JSON.parse(localStorage.getItem("level_" + levelId));
        if (!themes) {
          themes = await setSyllabusLevelCache(centre.centre_id, levelId);
        }
        const filteredThemes = themes.filter((theme) => {
          return theme.pathway === pathway;
        });
        const themeArray = Array.from(
          new Set(
            filteredThemes.map(
              (item) =>
                item.theme_code + ":" + item.theme_name + ":" + item.theme_id
            )
          )
        );
        const sortedThemes = [...themeArray].sort((a, b) => (a > b ? 1 : -1));
        const themeList = sortedThemes.map((option) => {
          return {
            value: option.split(":")[0],
            label: option.split(":")[0] + ": " + option.split(":")[1],
            theme_id: option.split(":")[2],
          };
        });
        setThemeOptions(themeList);
      }
    }
  };

  useEffect(() => {
    getThemeOptions();
  }, [classObj.pathway]);

  return (
    <Select
      ref={selectRef}
      className="schedule-form-content-theme-selector"
      value={classObj.theme}
      isSearchable
      isDisabled={!isLoaded}
      placeholder={placeholder}
      options={themeOptions}
      onFocus={() => setIsSelecting(false)}
      onChange={(data) => handleClassObj(data, { name: "theme" })}
    />
  );
}
