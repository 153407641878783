import React, { useState, useRef } from "react";
import fetchData from "../schedule/scripts/fetchData.js";

export default function RedisTests() {
  const [identifier, setIdentifier] = useState("");
  const [myStr, setMyStr] = useState("");
  const [displayStr, setDisplayStr] = useState("");
  const [responseStr, setResponseStr] = useState();
  const identifierRef = useRef();
  const myStrRef = useRef();
  const formData = {
    str: myStr,
  };

  async function setRedisString(e) {
    e.preventDefault();
    if (!myStr) {
      myStrRef.current.focus();
      return;
    }
    console.log("Setting string in Redis");
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/redis`;
    const response = await fetchData(url, "POST", formData);
    setResponseStr(response);
    setDisplayStr(response.identifier);
  }

  async function getRedisString(e) {
    e.preventDefault();
    if (!identifier) {
      identifierRef.current.focus();
      return;
    }
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/redis/${identifier}`;
    const response = await fetchData(url, "GET");
    setResponseStr(response);
    setDisplayStr(response.response);
  }

  async function deleteRedisString(e) {
    e.preventDefault();
    if (!identifier) {
      identifierRef.current.focus();
      return;
    }
    console.log("Deleting string from Redis");
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/redis/${identifier}`;
    const response = await fetchData(url, "DELETE");
    setResponseStr(response);
    setDisplayStr("");
  }
  console.log(responseStr);

  return (
    <div>
      <h2>Redis Tests</h2>
      <p>Testing Redis functionality</p>
      <form>
        <div>
          <input
            ref={myStrRef}
            type="text"
            name="myStr"
            id="myStr"
            value={myStr}
            placeholder="String ..."
            onChange={(e) => setMyStr(e.target.value)}
          />
          <button
            className="schedule-form-button"
            onClick={(e) => setRedisString(e)}
          >
            Set
          </button>
        </div>
        <div>
          <input
            ref={identifierRef}
            type="text"
            name="identifier"
            id="identifier"
            value={identifier}
            placeholder="Identifier ..."
            onChange={(e) => setIdentifier(e.target.value)}
          />
          <button
            className="schedule-form-button"
            onClick={(e) => getRedisString(e)}
          >
            Get
          </button>
          <button
            className="schedule-form-button"
            onClick={(e) => deleteRedisString(e)}
          >
            Delete
          </button>
        </div>
      </form>
      <div>{displayStr}</div>
    </div>
  );
}
