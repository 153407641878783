import NewModalCloseButton from "./NewModalCloseButton";
import success_icon from "./images/success_icon.png";

export default function PublishSuccess({ setShowNewModal }) {
  return (
    <div>
      <img src={success_icon} alt="Success icon" className="success-icon" />
      <p>Your classes have beeen published successfully.</p>
      <p>Have a nice day.</p>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </div>
  );
}
