export default async function fetchData(url, method, body) {
  const token = localStorage.getItem("token");
  if (!token) {
    redirectToLogin();
    return { result: false, message: "No token found, redirecting to login." };
  }

  const options = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      const errorMessage = `HTTP error! status: ${response.status}`;
      console.error(errorMessage);
      return { result: false, message: errorMessage };
    }
    const data = await response.json();
    return { result: true, data };
  } catch (error) {
    console.error("Fetch error:", error);
    return { result: false, message: error.message };
  }
}

function redirectToLogin() {
  localStorage.removeItem("token");
  window.location.href = "/login";
}
