import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import AddTimeslot from "./AddTimeslot";
import ClassroomSelector from "./ClassroomSelector";
import LevelSelector from "./LevelSelector";
import TeacherSchedule from "./TeacherSchedule.js";
import NewModal from "./NewModal.js";
import { useUserSettings } from "../../auth/useUserSettings";
import { v4 as uuid } from "uuid";
import { nanoid } from "nanoid";
import dayjs from "dayjs";

import setTeacherCache from "./scripts/setTeacherCache.js";
import encrypt from "./scripts/encrypt.js";
import fetchData from "./scripts/fetchData.js";
import schedule_icon from "./images/schedule_icon.png";

export default function Teachers({
  centre,
  centreCalendar,
  loadedSchedule,
  mergeSchedule,
  selectedSession,
  disabledDates,
  closeModal,
  teacherId,
  refreshTeachers,
}) {
  const today = dayjs(new Date()).format();
  const [userSettings] = useUserSettings();
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState();
  const [clashList, setClashList] = useState([]);
  const [teacherTimeslots, setTeacherTimeslots] = useState({
    teacher_id: "",
    schedule: [],
  });
  const [teacherHours, setTeacherHours] = useState(0);
  const [selectedTimeslots, setSelectedTimeslots] = useState([]);
  const [startDate, setStartDate] = useState(dayjs(today).valueOf());
  const [endDate, setEndDate] = useState(dayjs(today).valueOf());
  const [dateList, setDateList] = useState([]);
  const [orderedDays, setOrderedDays] = useState([1, 2, 3, 4, 5, 6, 0]);
  const [classCount, setClassCount] = useState(0);
  const [classObj, setClassObj] = useState({
    classroom: "",
    capacity: "",
    levels: "",
    products: "",
    credit: "",
  });
  const [isAddField, setIsAddField] = useState(false);
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const teacherRef = useRef();

  function centreCalendarCheck(classStart, classEnd) {
    for (const item of centreCalendar) {
      if (
        dayjs.utc(classStart).format() <= item.end_date &&
        item.start_date <= dayjs.utc(classEnd).format() &&
        item.is_non_teaching === true
      ) {
        // console.log("Overlap!" + item.start_date + ":" + classStart);
        return true;
      }
    }
  }

  /* Display weekday order by user settings */
  function reorderOrderedDays() {
    let firstDay = userSettings?.user_settings.first_day_of_week;
    let days = [...orderedDays];
    let index = days.indexOf(firstDay);
    let newOrder = days.slice(index);
    let beforeDays = days.slice(0, index);
    newOrder.push(...beforeDays);
    setOrderedDays(newOrder);
  }

  /* Dress class start and end times for clash check */
  function dressStartDate(date, timeslot) {
    return dayjs(date).startOf("day").add(timeslot.value, "minute").format();
  }
  function dressEndDate(date, timeslot, duration) {
    return dayjs(date)
      .startOf("day")
      .add(timeslot.value, "minute")
      .add(duration.value, "minute")
      .format();
  }

  function createDateList() {
    const newDateList = [];
    const loopStartDate = new Date(startDate);
    const loopEndDate = endDate;

    for (
      let day = loopStartDate;
      day <= loopEndDate;
      day.setDate(day.getDate() + 1)
    ) {
      const selectedItems = teacherTimeslots.schedule.filter((item) =>
        selectedTimeslots.includes(item.id)
      );

      for (const item of selectedItems) {
        const isSameDay = item.day.value === day.getDay();
        const isFutureDate =
          dressStartDate(day, item.timeslot) > dayjs(new Date()).format();

        if (isSameDay && isFutureDate) {
          const classStart = dressStartDate(day, item.timeslot);
          const classEnd = dressEndDate(day, item.timeslot, item.duration);
          const isCentreCalendarCheckPassed =
            centreCalendarCheck(classStart, classEnd) !== true;

          if (isCentreCalendarCheckPassed) {
            newDateList.push({
              dateStr: classStart,
              timeslot: item.timeslot,
              duration: item.duration,
            });
          }
        }
      }
    }
    setDateList(newDateList);
    setClassCount(newDateList.length);
  }

  async function addClassesFromTeacherTemplate() {
    if (!selectedTeacher) {
      teacherRef.current.focus();
    } else {
      const teacherClassList = dateList.map((item) => {
        const newClass = {
          ...classObj,
          temp_id: uuid(),
          centre: centre,
          dateStr: item.dateStr,
          timeslot: item.timeslot,
          duration: item.duration,
          teacher: selectedTeacher,
          classroom: classObj.classroom,
          capacity: classObj.capacity,
          levels: classObj.levels,
          products: classObj.products,
          credit: classObj.credit,
          isClosed: "",
          contentType: "",
          contentLevel: "",
          module: "",
          pathway: "",
          theme: "",
          lesson: "",
          virtualCentres: "",
        };
        return newClass;
      });
      if (teacherClassList.length > 0) {
        mergeSchedule(null, teacherClassList, true);
      } else {
        setNewModalContent("No dates or timeslots selected.");
        setShowNewModal(true);
      }
    }
  }

  function getTeacherHours() {
    let sum = 0;
    teacherTimeslots.schedule?.forEach((element) => {
      sum += element.duration.value;
    });
    sum = createTimeSlot(sum);
    setTeacherHours(sum);
    return sum;
  }

  const getTeachers = async () => {
    let user_option = userSettings.user_settings.teacher_search;
    let url = "";
    if (centre) {
      let countryId = centre.country_id;
      let centreId = centre.value;
      let isVirtual = centre.is_virtual;
      let teachers = [];
      if (isVirtual === true) {
        url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/virtual-teachers/${countryId}`;
        let response = await fetchData(url, "GET");
        teachers = response.data;
      } else {
        teachers = JSON.parse(
          encrypt("decrypt", localStorage.getItem("teachers"))
        );
        if (!teachers) {
          teachers = await setTeacherCache(centre.country_id);
        }
      }
      if (teachers) {
        let filteredTeachers = teachers;
        if (user_option === 0 && isVirtual === false) {
          filteredTeachers = teachers.filter(
            (teacher) => teacher.centre_id === centreId
          );
        }
        const sortedTeachers = [...filteredTeachers].sort((a, b) =>
          a.first_name > b.first_name ? 1 : -1
        );
        createTeacherOptions(sortedTeachers);
      }
    }
  };

  function createTeacherOptions(teachers) {
    setTeacherOptions(
      teachers.map((option) => {
        return {
          value: option.teacher_id,
          label: option.first_name + " " + option.last_name,
        };
      })
    );
  }

  const getTeacherScheduleTemplate = async (teacher) => {
    const teacherId = teacher?.value || 0;
    setSelectedTeacher(teacher);
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/teachers-schedule-template/${teacherId}`;
    const response = await fetchData(url, "GET");
    response.teacher_id
      ? setTeacherTimeslots(response)
      : setTeacherTimeslots({
          ...teacherTimeslots,
          teacher_id: selectedTeacher?.value,
          schedule: [],
        });
  };

  function setSessionDates() {
    let date = dayjs(new Date()).valueOf();
    setStartDate(dayjs(selectedSession?.from_date).valueOf() || date);
    setEndDate(dayjs(selectedSession?.to_date).valueOf() || date);
  }

  function preLoadTeacher() {
    if (teacherId) {
      const teacher = teacherOptions.find(
        (option) => option.value === teacherId
      );
      setSelectedTeacher(teacher);
      getTeacherScheduleTemplate(teacher);
    }
  }

  useEffect(() => {
    getTeachers();
    setSessionDates();
  }, [centre]);
  useEffect(() => {
    getTeacherHours();
  }, [teacherTimeslots]);
  useEffect(() => {
    createDateList();
  }, [startDate, endDate, selectedTimeslots]);
  useEffect(() => {
    reorderOrderedDays();
  }, [userSettings]);
  useEffect(() => {
    preLoadTeacher();
  }, [teacherOptions]);

  async function updateTeacherTimeslots(updatedSchedule) {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/teachers-schedule-template/${selectedTeacher?.value}`;
    await fetchData(url, "POST", updatedSchedule);
  }

  function slotClashCheck(slot) {
    let clashArray = [];
    let slotStart = slot.day.value * 10000 + slot.timeslot.value;
    let slotEnd = slotStart + slot.duration.value;
    for (const item of teacherTimeslots.schedule) {
      let itemStart = item.day.value * 10000 + item.timeslot.value;
      let itemEnd = itemStart + item.duration.value;
      // console.log(itemStart + ":" + itemEnd + ":" + slotStart + ":" + slotEnd);
      if (slotStart < itemEnd && itemStart < slotEnd) {
        clashArray.push(item.id);
      }
    }
    setClashList(clashArray);
    return clashArray;
  }

  const addTimeslot = async (slotList) => {
    if (!selectedTeacher) {
      teacherRef.current.focus();
    } else {
      for (const slot of slotList) {
        if (slotClashCheck(slot).length > 0) {
          console.log("Clash.");
        } else {
          let newState = {
            ...teacherTimeslots,
            schedule: [...teacherTimeslots.schedule, ...slotList],
          };
          let sortedTimeslots = [...newState.schedule].sort(
            (a, b) => a.timeslot?.value - b.timeslot?.value
          );
          newState = { ...newState, schedule: sortedTimeslots };
          setTeacherTimeslots(newState);
          updateTeacherTimeslots(newState);
        }
      }
    }
    refreshTeachers && refreshTeachers();
  };

  const removeTimeslot = async (slotId) => {
    const newTimeslots = teacherTimeslots.schedule.filter((slot) => {
      return slot.id !== slotId;
    });
    let newState = {
      ...teacherTimeslots,
      schedule: newTimeslots,
    };
    setTeacherTimeslots(newState);
    updateTeacherTimeslots(newState);
    setSelectedTimeslots((current) => current.filter((id) => id !== slotId));
    refreshTeachers && refreshTeachers();
  };

  function createTimeSlot(ts) {
    let minutes = ts % 60;
    let hours = Math.floor(ts / 60);
    let output =
      hours.toString().padStart(2, "0") +
      ":" +
      minutes.toString().padStart(2, "0");
    return output;
  }

  const selectTimeslots = (slotId) => {
    if (!selectedTimeslots.includes(slotId)) {
      setSelectedTimeslots((currentList) => [...currentList, slotId]);
    } else {
      setSelectedTimeslots((current) => current.filter((id) => id !== slotId));
    }
  };

  function handleClassObj(data, formElement, mode) {
    if (formElement.name) {
      setClassObj((prevState) => ({
        ...prevState,
        [formElement.name]: data,
      }));
    } else {
      setClassObj({ ...classObj, [formElement]: data });
    }
  }

  function showAdditionalFormOptions() {
    if (!isAddField) {
      setIsAddField(true);
    } else {
      setIsAddField(false);
      for (const item of [
        "classroom",
        "capacity",
        "levels",
        "products",
        "credit",
      ]) {
        setClassObj((prevState) => ({ ...prevState, [item]: "" }));
      }
    }
  }

  function closeTeacherSchedule() {
    setShowNewModal(false);
  }

  const showTeacherSchedule = async () => {
    setNewModalContent(
      <TeacherSchedule
        centre={centre}
        teacher={selectedTeacher}
        loadedSchedule={loadedSchedule}
        closeModal={closeTeacherSchedule}
      />
    );
    setShowNewModal(true);
  };

  const teacherScheduleDisplay = orderedDays.map((day) => {
    const morningTimeslots = [];
    const afternoonTimeslots = [];
    const eveningTimeslots = [];

    teacherTimeslots?.schedule?.forEach((item) => {
      if (item.day.value === day) {
        if (item.timeslot.value < 720) {
          morningTimeslots.push(item);
        } else if (item.timeslot.value < 1080) {
          afternoonTimeslots.push(item);
        } else {
          eveningTimeslots.push(item);
        }
      }
    });

    const renderTimeslots = (timeslots) =>
      timeslots.map((item) => (
        <span
          className={`${
            clashList.includes(item.id) ? "timeslot-clash" : undefined
          } ${
            selectedTimeslots.includes(item.id)
              ? "selected_timeslot"
              : undefined
          } timeslot-div`}
          key={item.id}
          onClick={() => selectTimeslots(item.id)}
        >
          {createTimeSlot(item.timeslot.value)} ({item.duration.value})
          <span
            className="teacher-schedule-remove-icon"
            onClick={() => removeTimeslot(item.id)}
          >
            x
          </span>
        </span>
      ));

    return (
      <tr key={nanoid()}>
        <td className="day-cell">{weekdays[day]}</td>
        <td className="daypart-cell">{renderTimeslots(morningTimeslots)}</td>
        <td className="daypart-cell">{renderTimeslots(afternoonTimeslots)}</td>
        <td className="daypart-cell">{renderTimeslots(eveningTimeslots)}</td>
      </tr>
    );
  });

  return (
    <div className="teacher-schedule-modal-content">
      <h2>Teacher schedule</h2>
      {/* <img src={teacher} alt="Teacher" className="teacher-icon" /> */}
      <div className="teacher-selector-div">
        <Select
          ref={teacherRef}
          className="schedule-form-teacher-selector"
          value={selectedTeacher}
          placeholder="Choose teacher"
          isSearchable
          isClearable
          options={teacherOptions}
          onChange={(data) => getTeacherScheduleTemplate(data)}
        />
        {selectedTeacher && (
          <button
            className="teacher-schedule-button"
            title="Show all active schedule classes"
            onClick={() => showTeacherSchedule()}
          >
            <img
              src={schedule_icon}
              alt="Show teacher schedule"
              className="schedule-icon"
            />
          </button>
        )}
      </div>
      <div className="header-div">
        <div>
          <AddTimeslot
            addTimeslot={addTimeslot}
            userSettings={userSettings}
            isEnabled={selectedTeacher}
          />
        </div>
        <div className="hours-div">
          <p>Total hours: {teacherHours}</p>
        </div>
      </div>
      <table className="teacher-schedule-table">
        <thead>
          <tr>
            <th>Day</th>
            <th>Morning</th>
            <th>Afternoon</th>
            <th>Evening</th>
          </tr>
        </thead>
        <tbody>{teacherScheduleDisplay}</tbody>
      </table>
      {loadedSchedule ? (
        <div>
          <div className="selectors-div">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              todayButton="Today"
              monthsShown={userSettings?.user_settings.calendar_display || 2}
              selected={startDate}
              minDate={new Date()}
              calendarStartDay={1}
              excludeDates={disabledDates}
              highlightDates={disabledDates}
              onChange={(startDate) => setStartDate(startDate)}
            />
            <DatePicker
              dateFormat="dd/MM/yyyy"
              className="date-picker"
              monthsShown={userSettings?.user_settings.calendar_display || 2}
              selected={endDate}
              minDate={new Date()}
              calendarStartDay={1}
              excludeDates={disabledDates}
              highlightDates={disabledDates}
              onChange={(endDate) => setEndDate(endDate)}
            />
            <button
              className={`${isAddField && "added-field"} schedule-form-button`}
              onClick={showAdditionalFormOptions}
            >
              {isAddField ? "-" : "+"}
            </button>
            <div>
              <button
                className="schedule-form-button"
                onClick={addClassesFromTeacherTemplate}
              >
                Insert
              </button>
              {/* <button
                className="schedule-form-button"
                onClick={closeModal}
              >
                Close
              </button> */}
            </div>
            <div className="class-count-div">
              Dates:{" "}
              {selectedSession
                ? `${selectedSession.session_name}. `
                : "Not selected. "}
              Classes to add: {classCount}
            </div>
          </div>
          <div
            className={`${isAddField ? "add-field-div" : "hide-add-field-div"}`}
          >
            <div className="schedule-form-class-div">
              {/* <div>Classroom: </div> */}
              <ClassroomSelector
                centre={centre}
                loadedSchedule={loadedSchedule}
                isVirtual={false}
                isLoaded={loadedSchedule}
                classObj={classObj}
                handleClassObj={handleClassObj}
              />
              <LevelSelector
                centre={centre}
                classObj={classObj}
                isLoaded={loadedSchedule}
                handleClassObj={handleClassObj}
                loadedSchedule={loadedSchedule}
              />
              {/* <ProductSelector
                classObj={classObj}
                handleClassObj={handleClassObj}
                centre={centre}
                loadedSchedule={loadedSchedule}
              /> */}
              {/* <CreditSelector
                classObj={classObj}
                handleClassObj={handleClassObj}
                loadedSchedule={loadedSchedule}
              /> */}
            </div>
          </div>
        </div>
      ) : (
        <div>
          {!teacherId && (
            <div className="teacher-message-div">
              Create a new schedule or open an existing schedule to add classes.
            </div>
          )}
          <div className="modal-button-div">
            <button className="schedule-form-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
