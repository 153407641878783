import dayjs from "dayjs";
import { useState, useEffect } from "react";

export default function ScheduleList({
  centre,
  isLoaded,
  schedules,
  loadSchedule,
  viewSchedule,
  mergeSchedule,
  archiveSchedule,
  confirmDeleteSchedule,
}) {
  const [scheduleArray, setScheduleArray] = useState([]);
  // const getClassCount = (schedule) => schedule.schedule_classlist.length;

  useEffect(() => {
    setScheduleArray(schedules);
  }, [schedules]);

  const scheduleList = scheduleArray?.map((schedule) => {
    return (
      <tr key={schedule._id}>
        <td
          className={
            schedule.checked_out
              ? "schedule-checked-out"
              : "schedule-checked-in"
          }
        >
          {" "}
        </td>
        <td className="schedule-table-centred-column">{schedule.centre}</td>
        <td>{schedule.schedule_name}</td>
        <td className="centered-column">
          {schedule.is_archive ? "Archive" : "Working"}
        </td>
        {/* <td className="numeric-column class-count-column">
          {getClassCount(schedule)}
        </td> */}
        <td className="numeric-column class-count-column">
          {schedule.schedule_classlist_count}
        </td>
        <td className="numeric-column">
          {schedule.schedule_classlist_published}
        </td>
        {/* <td className="numeric-column">
          {schedule.schedule_classlist.reduce(
            (acc, cur) => (cur.hasOwnProperty("class_id") ? ++acc : acc),
            0
          )}
        </td> */}
        <td className="centered-column">
          {dayjs(schedule.update_date).format("DD/MM/YYYY HH:mm")}
        </td>
        <td>{schedule.schedule_author}</td>
        <td className="centered-column">
          {dayjs(schedule.createdAt).format("DD/MM/YYYY HH:mm")}
        </td>
        <td>{schedule.schedule_note}</td>
        <td className="schedule-table-centred-column">
          <>
            <button
              className="schedule-list-action-button"
              onClick={(event) => viewSchedule(schedule._id)}
            >
              View
            </button>
            {!isLoaded && (
              <button
                className="schedule-list-action-button"
                onClick={(event) => loadSchedule(schedule._id)}
              >
                Load
              </button>
            )}
            {isLoaded && (
              <button
                className="schedule-list-action-button"
                onClick={(event) => mergeSchedule(schedule._id, null, true)}
              >
                Merge
              </button>
            )}
            <button
              className="schedule-list-action-button"
              onClick={() => archiveSchedule(schedule._id)}
            >
              Archive
            </button>
            <button
              className="schedule-list-action-button"
              onClick={() => confirmDeleteSchedule(false, schedule._id)}
            >
              Delete
            </button>
          </>
        </td>
      </tr>
    );
  });
  return (
    <>
      <div className="schedule-button-div">
        <div className="schedule-button-section-left-div">
          <h2>
            Working schedules
            {centre && ` - ${centre.centre_code}`}
          </h2>
        </div>
      </div>
      <table className="schedule-table">
        <thead>
          <tr>
            <th></th>
            <th>Centre</th>
            <th>Name</th>
            <th>Status</th>
            <th>Classes</th>
            <th>Pub.</th>
            <th>Last updated</th>
            <th>Updated by</th>
            <th>Created</th>
            <th>Note</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>{scheduleList}</tbody>
      </table>
    </>
  );
}
