import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import "./style/selectors.css";
import setCountryLevelCache from "./scripts/setCountryLevelCache.js";

export default function LevelSelector({
  centre,
  classObj,
  handleClassObj,
  isLoaded,
}) {
  const animatedComponents = makeAnimated();
  const [levelOptions, setLevelOptions] = useState([]);

  const sortArrayObjects = (arrayObjects) => {
    let newArrayObject = [];
    if (arrayObjects.length > 0) {
      newArrayObject = arrayObjects.sort((arrayObject1, arrayObject2) => {
        return arrayObject1.value - arrayObject2.value;
      });
    }
    return newArrayObject;
  };

  const getLevelOptions = async () => {
    let levels = [];
    if (centre) {
      levels = JSON.parse(localStorage.getItem("levels"));
      if (!levels) {
        levels = await setCountryLevelCache(centre.centre_id);
      }
      const levelList = levels.map((option) => {
        return {
          value: option.level_id,
          label: option.level_code,
          level_name: option.level_name,
        };
      });
      setLevelOptions(levelList);
    }
  };

  useEffect(() => {
    getLevelOptions();
  }, [centre]);

  return (
    <Select
      className="schedule-form-target-level-selector"
      value={sortArrayObjects(classObj.levels)}
      isMulti
      placeholder="Level(s)"
      cacheOptions
      isDisabled={!isLoaded}
      components={animatedComponents}
      options={levelOptions}
      onChange={(data) => handleClassObj(data, { name: "levels" })}
    />
  );
}
