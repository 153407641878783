import React from "react";

export default function Error({ errorMessage, closeModal }) {
  return (
    <div>
      <h3>Oops! Something went wrong.</h3>
      <p>
        The error has been logged. It does not affect the creation of classes on
        OBS.
      </p>
      <p>{errorMessage}</p>
      <button className="schedule-form-button" onClick={closeModal}>
        Close
      </button>
    </div>
  );
}
