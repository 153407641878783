export default function Help({ helpTitle, helpText }) {
  return (
    <div className="centered-column">
      <h3>{helpTitle}</h3>
      <div
        id="modal-content"
        className="help-text-div"
        dangerouslySetInnerHTML={{ __html: helpText }}
      ></div>
    </div>
  );
}
