import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LoginHistory from "./LoginHistory";
import NewModal from "../components/schedule/NewModal";
import dayjs from "dayjs";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function AdminAccounts() {
  const [adminAccounts, setAdminAccounts] = useState([]);
  const [centreArray, setCentreArray] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [newModalContent, setNewModalContent] = useState();

  const getCentreCountryNames = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/centre-country-names`;
    const response = await fetchData(url, "GET");
    setCentreArray(response);
  };

  const getAdminAccounts = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/admin`;
    const response = await fetchData(url, "GET");
    for (const user of response) {
      let lastLogin = await getLastLogin(user.username);
      Object.assign(user, { last_login: lastLogin ? lastLogin : "" });
    }
    setAdminAccounts(response);
  };

  const getLastLogin = async (username) => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/admin/last-login/${username}`;
    const response = await fetchData(url, "GET");
    return response[0]?.updatedAt;
  };

  useEffect(() => {
    getCentreCountryNames();
    getAdminAccounts();
  }, []);

  function showLoginHistory(username) {
    setNewModalContent(
      <LoginHistory username={username} setShowNewModal={setShowNewModal} />
    );
    setShowNewModal(true);
  }

  const orderedAccounts = [...adminAccounts]?.sort((a, b) =>
    a.last_login > b.last_login ? -1 : 1
  );

  const adminAccountList = orderedAccounts.map((account) => {
    return (
      <tr key={account._id}>
        <td>
          <span
            className="link"
            onClick={() => showLoginHistory(account.username)}
          >
            {account._id}
          </span>
        </td>
        <td className="numeric-column">{account.admin_id}</td>
        <td>{account.username}</td>
        <td>{account.email}</td>
        <td>
          {
            centreArray[
              centreArray
                .map((centre) => centre.centre_id)
                .indexOf(account.centre_id)
            ].centre_name
          }
        </td>
        <td>
          {
            centreArray[
              centreArray
                .map((centre) => centre.centre_id)
                .indexOf(account.centre_id)
            ].country_name
          }
        </td>
        <td className="centered_column">
          {account.last_login &&
            dayjs(account.last_login).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="centered_column">
          {dayjs(account.updatedAt).format("DD/MM/YYYY HH:mm")}
        </td>
        <td className="centered_column">
          {dayjs(account.createdAt).format("DD/MM/YYYY HH:mm")}
        </td>
      </tr>
    );
  });

  return (
    <>
      <h2>Users</h2>
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-left-div"></div>
        <div className="schedule-container-right-div">
          <Link to="/new-admin-account">
            {/* <button className="schedule-form-button">New account</button> */}
          </Link>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <td>ID</td>
            <td>OBS ID</td>
            <td>Username</td>
            <td>Email</td>
            <td>Centre</td>
            <td>Country</td>
            <td>Last login</td>
            <td>Updated</td>
            <td>Created</td>
          </tr>
        </thead>
        <tbody>{adminAccounts && adminAccountList}</tbody>
      </table>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </>
  );
}
