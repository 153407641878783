import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import fetchData from "./scripts/fetchData.js";

import "./style/selectors.css";

export default function PathwaySelector({
  centre,
  classObj,
  handleClassObj,
  isSelecting,
  setIsSelecting,
}) {
  const [activityTypeOptions, setActivityTypeOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Activity type..");
  const selectRef = useRef();

  function clearDependencies() {
    if (!isSelecting || classObj.contentLevel) {
      selectRef.current.clearValue();
      classObj.contentLevel = "";
      classObj.pathway = "";
      classObj.theme = "";
      classObj.module = "";
      classObj.lesson = "";
    }
  }

  const getActivityTypeOptions = async () => {
    clearDependencies();
    if (centre) {
      const centreId = centre?.centre_id;
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/activities/${centreId}`;
      const response = await fetchData(url, "GET");
      const activities = Array.from(
        new Set(response.data.map((item) => item.activity_type))
      );
      const sortedActivities = [...activities].sort((a, b) => (a > b ? 1 : -1));
      const activityTypeList = sortedActivities.map((option) => {
        return {
          value: option,
          label: option,
        };
      });
      setActivityTypeOptions(activityTypeList);
    }
  };

  useEffect(() => {
    getActivityTypeOptions();
  }, [classObj.contentType]);

  return (
    <Select
      ref={selectRef}
      className="schedule-form-content-pathway-selector"
      value={classObj.theme}
      placeholder={placeholder}
      options={activityTypeOptions}
      onFocus={() => setIsSelecting(false)}
      onChange={(data) => handleClassObj(data, { name: "theme" })}
    />
  );
}
