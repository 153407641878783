import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { useUserSettings } from "../../auth/useUserSettings";
import DatePicker from "react-datepicker";
import ScheduleList from "./ScheduleList";
import CentreCalendar from "./CentreCalendar";
import CentreSelector from "./CentreSelector";
import TimeslotSelector from "./TimeslotSelector";
import ClassroomSelector from "./ClassroomSelector";
import ContentTypeSelector from "./ContentTypeSelector";
import ContentLevelSelector from "./ContentLevelSelector";
import ActivityTypeSelector from "./ActivityTypeSelector";
import ThemeSelector from "./ThemeSelector";
import StrandSelector from "./StrandSelector";
import PathwaySelector from "./PathwaySelector";
import LessonSelector from "./LessonSelector";
import Lessons from "./Lessons";
import TeacherSelector from "./TeacherSelector";
import ProductSelector from "./ProductSelector";
import LevelSelector from "./LevelSelector";
import VirtualCentreSelector from "./VirtualCentreSelector";
import CreditSelector from "./CreditSelector.js";
import ColumnFilter from "./ColumnFilter.js";
import SessionForm from "./SessionForm.js";
import Teachers from "./Teachers";
import Classroom from "./Classroom";
import Sessions from "./Sessions";
import ScheduleSettings from "./ScheduleSettings.js";
import PublishSchedule from "./PublishSchedule.js";
import CreateUploadSchedule from "./CreateUploadSchedule";
import TeacherSchedule from "./TeacherSchedule.js";
import LessonHistory from "./LessonHistory.js";
import ClashMessage from "./ClashMessage.js";
import FeedbackForm from "./FeedbackForm.js";
import NewModal from "./NewModal";
import DeleteConfirmation from "./DeleteConfirmation.js";
import MergeConfirmation from "./MergeConfirmation.js";
import DeleteClass from "./DeleteClass.js";
import ScheduleView from "./ScheduleView.js";
import DayPickerComponent from "./DayPickerComponent.js";
import News from "./News.js";
import Error from "./Error";

/* Import icons */
import closed_icon from "./images/closed_icon.svg";
import open_icon from "./images/open_icon.svg";
import history_icon from "./images/history_icon.svg";
import delete_icon from "./images/delete_icon.svg";
import clear_icon from "./images/clear_icon.png";
import clear_icon_off from "./images/clear_icon_off.png";
import undo_icon from "./images/undo_icon.png";
import undo_icon_off from "./images/undo_icon_off.png";
import redo_icon from "./images/redo_icon.png";
import redo_icon_off from "./images/redo_icon_off.png";
import session_icon from "./images/session_icon.png";
import session_icon_off from "./images/session_icon_off.png";
import migrate_icon from "./images/migrate_icon.png";
import schedule_icon from "./images/schedule_icon.png";

/* Style files */
import "react-datepicker/dist/react-datepicker.css";
import "./style/schedule.css";
// import "./style/modal.css";

/* Import scripts */
// import checkMatches from "./scripts/checkObjMatches";
import createLessonArray from "./scripts/createLessonArray.js";
import arrToStr from "./scripts/arrayToString.js";
import obsClassLink from "./scripts/obsClassLink.js";
import fetchData from "./scripts/fetchData.js";
import mergeModules from "./scripts/mergeModules.js";

import dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import MigrateClass from "./MigrateClass.js";
dayjs.extend(utc);
dayjs.extend(timezone);

export default function Schedule() {
  /* User */
  const [userSettings] = useUserSettings();
  const location = useLocation();
  const [selectedSession, setSelectedSession] = useState();

  /* Centre */
  const [centre, setCentre] = useState();
  const isVirtual = Number(centre?.is_virtual || 0);

  /* Schedule management */
  const [schedules, setSchedules] = useState([]);
  const [loadedSchedule, setLoadedSchedule] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [centreCalendar, setCentreCalendar] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [isSaved, setIsSaved] = useState(true);

  /* Modal management */
  const [showNewModal, setShowNewModal] = useState(false);
  const [newModalContent, setNewModalContent] = useState();

  /* Class lists */
  const [classList, setClassList] = useState([]);
  const [newClassList, setNewClassList] = useState([]);
  const [classListHistory, setClassListHistory] = useState([]);
  const [classListHistoryPos, setclassListHistoryPos] = useState(0);
  const [updateClassList, setUpdateClassList] = useState(true);
  const [selectedClassList, setSelectedClassList] = useState([]);
  const [clashClassList, setClashClassList] = useState([]);
  const [lessonArray, setLessonArray] = useState([]);
  const [filterObj, setFilterObj] = useState({});
  const [filterIdList, setFilterIdList] = useState([]);
  const lessonKeys = [
    "contentType",
    "contentLevel",
    "pathway",
    "strand",
    "theme",
  ];

  /* Class creation states */
  const today = dayjs(new Date()).format();
  const [dateCount, setDateCount] = useState(0);
  const [dateList, setDateList] = useState([]);
  const [startDate, setStartDate] = useState(dayjs(today).valueOf());
  const [endDate, setEndDate] = useState(dayjs(today).endOf("date").valueOf());
  const [weekday, setWeekday] = useState([]);
  const [ctrlKeyUsed, setCtrlKeyUsed] = useState(false);
  const [altKeyUsed, setAltKeyUsed] = useState(false);
  const tableRef = useRef(null);
  const timeslotRef = useRef();
  const durationRef = useRef();

  const classObjInit = {
    centre: "",
    dateStr: "",
    timeslot: "",
    duration: "",
    classroom: "",
    capacity: "",
    teacher: "",
    contentType: "",
    contentLevel: "",
    pathway: "",
    theme: "",
    lesson: "",
    strand: "",
    module: "",
    isClosed: { value: false },
    credit: "",
    levels: "",
    products: "",
    virtualCentres: "",
  };

  /* Check passed from login indicate whether news update needed */

  function checkRequireNewsUpdate() {
    location.state === true && showNewsModal();
    window.history.replaceState({}, "");
  }

  /* Get centre calendar for modal and set exclusion dates */

  const getCentreCalendar = async () => {
    if (centre) {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/calendar/${centre?.centre_code}`;
      const response = await fetchData(url, "GET");
      setCentreCalendar(response);
      const disabledDateList = response.reduce((list, item) => {
        if (item.is_non_teaching) {
          for (let i = 0; i <= item.duration - 1; i++) {
            list.push(
              new Date(
                dayjs(item.start_date).add(i, "day").format("YYYY/MM/DD")
              )
            );
          }
        }
        return list;
      }, []);
      setDisabledDates(disabledDateList);
    }
  };

  /* On select class(es) in schedule check classObj to retain common fields */

  function checkMatches(classArray) {
    const classArrays = ["products", "levels"];
    const formList = classArray.filter((item) =>
      selectedClassList.includes(item.temp_id)
    );

    const isSameDate = (dateStr1, dateStr2) =>
      dayjs(dateStr1).isSame(dayjs(dateStr2));
    const isSameValue = (obj1, obj2, key) =>
      obj1[key]?.value === obj2[key]?.value;
    const isSameArray = (obj1, obj2, key) =>
      JSON.stringify(Object.values(obj1[key])) ===
      JSON.stringify(Object.values(obj2[key]));

    Object.keys(formList[0]).forEach((key) => {
      const allMatch = formList.every((classObj) => {
        if (!classObj[key]) return false;
        if (key === "dateStr")
          return isSameDate(classObj["dateStr"], formList[0]["dateStr"]);
        if (classArrays.includes(key))
          return isSameArray(classObj, formList[0], key);
        return isSameValue(classObj, formList[0], key);
      });

      handleClassObj(allMatch ? formList[0][key] : "", { name: key });
    });

    handleClassObj("", { name: "temp_id" });
    setStartDate("");
    setEndDate("");
  }

  /* Main class object, stores all form input */
  const [classObj, setClassObj] = useState(classObjInit);

  /* Handle all form input using class object state */

  function handleClassObj(data, formElement) {
    // console.log(data.value + "," + formElement);
    // if (data || data === false) {
    let objectArrays = ["levels", "products", "virtualCentres"];
    if (objectArrays.includes(formElement.name)) {
      setClassObj((prevState) => ({
        ...prevState,
        [formElement.name]: [...prevState[formElement.name]],
        [formElement.name]: data,
      }));
    } else if (formElement.name) {
      setClassObj((prevState) => ({
        ...prevState,
        [formElement.name]: data,
      }));
    } else {
      setClassObj({ ...classObj, [formElement]: data });
    }
    // }
  }

  // console.log(classObj);
  // console.log(lessonArray);
  // console.log(classList);
  // console.log(selectedClassList);
  // console.log(clashClassList);

  /* Load class from selected classList into class object state */
  const [isSelecting, setIsSelecting] = useState(false);

  /* Date management functions */

  function setSessionDates() {
    let date = dayjs(new Date()).valueOf();
    setStartDate(dayjs(selectedSession?.from_date).valueOf() || date);
    setEndDate(dayjs(selectedSession?.to_date).valueOf() || date);
  }

  function handleStartDate(dateObj, source) {
    if (source === "start") {
      setStartDate(dateObj);
      dateObj > endDate && setEndDate(dayjs(dateObj).endOf().valueOf());
    } else {
      setEndDate(dateObj);
      dateObj < startDate && setStartDate(dayjs(dateObj).startOf().valueOf());
      // checkSameDay();
    }
  }

  function dressStartDate(date, timeslot) {
    return dayjs(date)
      .startOf("day")
      .add(timeslot?.value || 0, "minute")
      .format();
  }
  function dressEndDate(date, timeslot, duration) {
    return dayjs(date)
      .startOf("day")
      .add(timeslot?.value || 0, "minute")
      .add(duration?.value || 0, "minute")
      .format();
  }

  function createDateList() {
    let newDateList = [];
    let loopStartDate = new Date(startDate);
    let end_date = endDate;
    for (
      let day = loopStartDate;
      day <= end_date;
      day.setDate(day.getDate() + 1)
    ) {
      if (
        weekday.includes(dayjs(day).get("day")) === true &&
        dressStartDate(day, classObj.timeslot) > dayjs(new Date()).format()
      ) {
        let classStart = dressStartDate(day, classObj.timeslot);
        let classEnd = dressEndDate(day, classObj.timeslot, classObj.duration);
        if (centreCalendarCheck(classStart, classEnd) !== true) {
          newDateList.push({
            dateStr: classStart,
            timeslot: classObj.timeslot,
            duration: classObj.duration,
          });
        }
      }
    }
    setDateList(newDateList);
    setDateCount(newDateList.length);
  }

  function loadClassObj(classId) {
    if (ctrlKeyUsed) {
      setSelectedClassList((current) => [...current, classId]);
    } else if (altKeyUsed) {
      selectClassRange(classId);
    } else {
      let newClassList = [];
      setSelectedClassList([...newClassList, classId]);
    }
    const selectedClass = classList.find((obj) => {
      return obj.temp_id === classId;
    });
    Object.keys(selectedClass).forEach((key) => {
      handleClassObj(selectedClass[key], { name: key });
    });
    !selectedClass.hasOwnProperty("class_id") && delete classObj.class_id;
    setStartDate(dayjs(selectedClass.dateStr).valueOf());
    setEndDate("");
  }

  /* Reset class object state */

  function clearClassObj(e) {
    e && e.preventDefault();
    // checkMatches(classList);
    setClassObj(classObjInit);
    setLessonArray([]);
    setWeekdayArray(null, null, false);
  }

  /* Add or remove classes from the selected class list */

  function handleSelectedClassList(classId) {
    // let scl = selectedClassList.length;
    if (!classId) {
      if (selectedClassList.length > 0) {
        setSelectedClassList([]);
        setIsSelecting(false);
      } else {
        setSelectedClassList(
          classList
            .filter((item) => {
              return !filterIdList.includes(item.temp_id);
            })
            .map((item) => {
              return item.temp_id;
            })
        );
        setIsSelecting(true);
      }
    } else {
      if (selectedClassList.includes(classId)) {
        setIsSelecting(selectedClassList.length > 0);
        setSelectedClassList((currentList) =>
          currentList.filter((id) => {
            return id !== classId;
          })
        );
      } else {
        loadClassObj(classId);
        setIsSelecting(true);
      }
    }
  }

  function getColumnFilter(filterColumn) {
    setNewModalContent(
      <ColumnFilter
        filterName={filterColumn}
        tableList={classList}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        getFilterIdList={getFilterIdList}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  }

  function getFilterIdList(filterList) {
    let idList = [];
    let arrayFilters = ["levels", "products", "virtualCentres"];
    for (const filter of Object.keys(filterList)) {
      for (const item of classList) {
        if (filterList[filter].includes("(Blanks)") && !item[filter]) {
          idList.push(item.temp_id);
          selectedClassList.includes(item.temp_id) &&
            handleSelectedClassList(item.temp_id);
        } else if (
          arrayFilters.includes(filter)
            ? filterList[filter].includes(JSON.stringify(item[filter]))
            : filter === "day"
            ? filterList[filter].includes(dayjs(item.dateStr).format("d"))
            : filter === "week"
            ? filterList[filter].includes(
                JSON.stringify(
                  dayjs(item.dateStr).subtract(1, "day").startOf("week")
                )
              )
            : filterList[filter].includes(item[filter]?.label)
        ) {
          idList.push(item.temp_id);
          selectedClassList.includes(item.temp_id) &&
            handleSelectedClassList(item.temp_id);
        }
      }
    }
    return idList;
  }

  useEffect(() => {
    setFilterIdList(getFilterIdList(filterObj));
  }, [filterObj]);

  /* Get list of modules from current schedule */
  const getModules = () => {
    let moduleList = classList
      .filter((item) => {
        return item.module;
      })
      .map((item) => {
        return item.module?.value;
      });
    let modList = Array.from(new Set(moduleList));
    return modList;
  };

  /* Clash clash check function, returns an array obj to display */

  function classClashCheck(
    targetClassList,
    classStart,
    classEnd,
    identifier,
    type
  ) {
    for (const item of targetClassList) {
      let dateStrEnd = dayjs(item.dateStr)
        .add(item.duration?.value, "minute")
        .subtract(1, "second")
        .format();
      if (
        classStart < dateStrEnd &&
        item.dateStr < classEnd &&
        item[type]?.value === identifier
      ) {
        let clash = {
          dateStr: item.dateStr,
          type: cfl(type),
          id: item.temp_id,
        };
        return clash;
      }
    }
  }

  function createClashMessage(clashList) {
    setNewModalContent(
      <ClashMessage clashList={clashList} setShowNewModal={setShowNewModal} />
    );
    setShowNewModal(true);
  }

  /* update selected class(es) with form changes */

  function checkForPublishedClass(targetList) {
    let publishedCount = 0;
    for (const classInst of targetList) {
      classInst["class_id"] && publishedCount++;
    }
    return publishedCount;
  }

  const updateSelectedClass = (e) => {
    e.preventDefault();
    let clashCheckArray = [];
    let tempClassList = JSON.parse(JSON.stringify(classList));
    let changeClassList = JSON.parse(JSON.stringify(sortedClassList));
    changeClassList = changeClassList.filter((item) => {
      return selectedClassList.includes(item.temp_id);
    });
    if (checkForPublishedClass(changeClassList) === 0) {
      for (const [index, item] of changeClassList.entries()) {
        Object.keys(classObj).forEach((key) => {
          if (lessonArray.length && key === "lesson") {
            if (classObj.lesson) {
              const lessonData = createLessonArray(
                lessonArray,
                classObj.contentType?.value
              );
              let i =
                index >= lessonArray.length
                  ? index % lessonArray.length
                  : index;
              Object.assign(item, { lesson: lessonData[0][i] });
              Object.assign(item, { pathway: lessonData[1][i] });
              Object.assign(item, { theme: lessonData[2][i] });
              Object.assign(item, { contentLevel: classObj.contentLevel });
              Object.assign(item, { contentType: classObj.contentType });
              Object.assign(item, { strand: classObj.strand });
            }
          } else if (key === "dateStr") {
            /* set the class time with item + timeslot */
            Object.assign(item, {
              [key]: dayjs(!startDate ? item.dateStr : startDate)
                .startOf("date")
                .minute(
                  classObj.timeslot
                    ? classObj.timeslot?.value
                    : item.timeslot.value
                )
                .format(),
            });
            // } else if (key === "module") {
            //   Object.assign(item, { [key]: classObj[key] });
          } else if (
            classObj[key] ||
            (classObj[key] instanceof Array && classObj[key].length > 0)
          ) {
            if (!lessonKeys.includes(key)) {
              Object.assign(item, { [key]: classObj[key] });
            }
          }
        });
        tempClassList = tempClassList.filter((c) => {
          return c.temp_id !== item.temp_id;
        });
        let checkList = ["teacher", "classroom"];
        let classStart = item.dateStr;
        let classEnd = dayjs(item.dateStr)
          .minute(item.duration?.value || 0)
          .format();
        for (const checkType of checkList) {
          if (classObj[checkType]) {
            let clashCheck = classClashCheck(
              tempClassList,
              classStart,
              classEnd,
              classObj[checkType]?.value,
              checkType
            );
            clashCheck && clashCheckArray.push(clashCheck);
          }
        }
        tempClassList.push(item);
      }
      if (!clashCheckArray.length) {
        setUpdateClassList(true);
        setClassList(tempClassList);
      } else {
        setClashClassList(clashCheckArray);
        createClashMessage(clashCheckArray);
      }
    } else {
      showInfoInModal("You cannot modify a published class.");
    }
  };

  function checkActivitySelection(selectedClass) {
    let changeList = [];
    if (
      selectedClassList.length > 0 &&
      selectedClassList.includes(selectedClass.temp_id)
    ) {
      for (const tempId of selectedClassList) {
        let classToFind = classList.find((c) => {
          return c.temp_id === tempId;
        });
        changeList.push(classToFind);
      }
    } else {
      changeList.push(selectedClass);
    }
    const allEqual = (arr) =>
      arr.every((val) => val.lesson.type === arr[0].lesson.type);
    if (allEqual(changeList)) {
      showSessionForm(changeList);
    } else {
      showInfoInModal(
        "You can only add session details to activities. Please review your selection."
      );
    }
  }

  function addSessionDetails(tempId, lesson) {
    const classListCopy = JSON.parse(JSON.stringify(classList));
    const updatedClassList = classListCopy.map((c) =>
      c.temp_id === tempId ? { ...c, lesson: lesson } : c
    );
    setClassList(updatedClassList);
  }

  function clearClassColumns(tempId, cObj) {
    setUpdateClassList(true);
    setClassList(
      classList.map((c) => (c.temp_id === tempId ? { ...c, cObj } : c))
    );
  }

  /* functions for setting and clearing form weekday selection in weekday state */
  const setWeekdayArray = (checked, day, mode) => {
    if (mode === true) {
      if (checked === true) {
        setWeekday([...weekday, day]);
      } else {
        setWeekday((currentArray) =>
          currentArray.filter((val) => {
            return val !== day;
          })
        );
      }
    } else if (mode === false) {
      setWeekday([]);
      setSessionDates();
    }
  };

  // function showFrequencyForm() {
  //   setNewModalContent(<FrequencyForm />);
  //   setShowNewModal(true);
  // }

  function setClassObjForm() {
    selectedLessonIdList.length > 1 && checkMatches(classList);
  }

  const selectedLessonIdList = classList
    .filter((obj) => {
      return selectedClassList.includes(obj.temp_id);
    })
    .map((obj) => {
      return obj.lesson?.value;
    });

  function getClickType(e) {
    setCtrlKeyUsed(e.ctrlKey);
    setAltKeyUsed(e.altKey);
  }

  useEffect(() => {
    const element = tableRef.current;
    element.addEventListener("click", (e) => getClickType(e));
    return () => {
      element.removeEventListener("click", (e) => getClickType(e));
    };
  }, []);
  useEffect(() => {
    getSchedules();
  }, [centre, isLoaded]);
  useEffect(() => {
    createDateList();
  }, [weekday, startDate, endDate, classObj.timeslot]);
  useEffect(() => {
    setClassObjForm();
  }, [selectedClassList]);
  useEffect(() => {
    saveClasslist();
  }, [classList]);
  useEffect(() => {
    getCentreCalendar();
  }, [centre]);
  useEffect(() => {
    checkRequireNewsUpdate();
  }, []);

  function centreCalendarCheck(classStart, classEnd) {
    for (const item of centreCalendar) {
      if (
        dayjs.utc(classStart).format() <= item.end_date &&
        item.start_date <= dayjs.utc(classEnd).format() &&
        item.is_non_teaching === true
      ) {
        // console.log("Overlap!");
        return true;
      }
    }
  }

  /* Main function for creating/adding new classes */
  async function addClassesFromDateRange(e) {
    e.preventDefault();
    setClashClassList([]);
    if (!classObj.timeslot) {
      timeslotRef.current.focus();
    } else if (!classObj.duration) {
      durationRef.current.focus();
    } else {
      const lessonData = createLessonArray(
        lessonArray,
        classObj.contentType?.value
      );
      let clashCheckArray = [];
      let lessonDataPos = 0;

      /* create class for each date in date list */
      const nextClassList = dateList.map((date) => {
        const newClass = {
          ...classObj,
          temp_id: uuid(),
          centre: centre,
          dateStr: date.dateStr,
          contentType: classObj.lesson ? classObj.contentType : "",
          contentLevel: classObj.lesson ? classObj.contentLevel : "",
          pathway: classObj.lesson ? lessonData[1][lessonDataPos] : "",
          theme: classObj.lesson ? lessonData[2][lessonDataPos] : "",
          lesson: classObj.lesson ? lessonData[0][lessonDataPos] : "",
        };

        /* Run class clash check with existing class list */
        let checkList = ["teacher", "classroom"];
        for (const checkType of checkList) {
          if (classObj[checkType]) {
            let clashCheck = classClashCheck(
              classList,
              date.dateStr,
              dressEndDate(date.dateStr, classObj.timeslot, classObj.duration),
              classObj[checkType].value,
              checkType
            );
            clashCheck && clashCheckArray.push(clashCheck);
          }
        }
        lessonDataPos === lessonArray.length - 1
          ? (lessonDataPos = 0)
          : lessonDataPos++;
        return newClass;
      });
      if (!clashCheckArray.length) {
        if (nextClassList.length > 0) {
          setNewClassList(nextClassList);
          setUpdateClassList(true);
          if (!classList) {
            setClassList(newClassList);
          } else {
            setClassList([...classList, ...nextClassList]);
          }
        } else {
          showInfoInModal("No dates selected");
        }
      } else {
        setClashClassList(clashCheckArray);
        createClashMessage(clashCheckArray);
      }
    }
  }

  function deleteClass(tempId) {
    setUpdateClassList(true);
    selectedClassList.includes(tempId) && handleSelectedClassList(tempId);
    setClassList((currentClassList) =>
      currentClassList.filter((item) => {
        return item.temp_id !== tempId;
      })
    );
  }

  function confirmActionClass(selectedClass, action) {
    let actionList = [];
    if (
      selectedClassList.length > 0 &&
      selectedClassList.includes(selectedClass.temp_id)
    ) {
      for (const tempId of selectedClassList) {
        let classToFind = classList.find((c) => {
          return c.temp_id === tempId;
        });
        actionList.push(classToFind);
      }
    } else {
      actionList.push(selectedClass);
    }
    if (action === "delete") {
      setNewModalContent(
        <DeleteClass
          deleteList={actionList}
          deleteClass={deleteClass}
          isVirtual={isVirtual}
          clearClassColumns={clearClassColumns}
          setShowNewModal={setShowNewModal}
        />
      );
    } else if (action === "migrate") {
      setNewModalContent(
        <MigrateClass
          centre={centre}
          migrateList={actionList}
          saveSchedule={saveSchedule}
          deleteClass={deleteClass}
          getSchedules={getSchedules}
          loadedScheduleId={loadedSchedule?._id}
          setShowNewModal={setShowNewModal}
        />
      );
    }
    setShowNewModal(true);
  }

  function confirmDeleteAllClasses(e) {
    e.preventDefault();
    if (
      checkForPublishedClass(
        selectedClassList.length > 0 ? selectedClassList : classList
      ) === 0
    ) {
      let classCount =
        selectedClassList.length > 0
          ? selectedClassList.length
          : classList.length;
      setNewModalContent(
        <DeleteConfirmation
          deleteMessage={`Are you sure you want to delete these ${classCount} classes?`}
          deleteFunction={removeAllClasses}
          setShowNewModal={setShowNewModal}
        />
      );
      setShowNewModal(true);
    } else {
      showInfoInModal("You cannot delete published classes.");
    }
  }

  function removeAllClasses(e) {
    e.preventDefault();
    setUpdateClassList(true);
    if (selectedClassList.length > 0) {
      for (const classId of selectedClassList) {
        deleteClass(classId);
      }
    } else {
      setSelectedClassList([]);
      setClassList([]);
    }
    setShowNewModal(false);
  }

  /* User can select range of class by using alt key */

  function selectClassRange(classId) {
    let startPos = sortedClassList.findIndex(
      (item) => item.temp_id === selectedClassList[selectedClassList.length - 1]
    );
    let endPos = sortedClassList.findIndex((item) => item.temp_id === classId);
    if (startPos > endPos) {
      [startPos, endPos] = [endPos - 1, startPos];
    }
    for (let i = startPos + 1; i <= endPos; i++) {
      if (!filterIdList.includes(sortedClassList[i].temp_id)) {
        if (!selectedClassList.includes(sortedClassList[i].temp_id)) {
          setSelectedClassList((current) => [
            ...current,
            sortedClassList[i].temp_id,
          ]);
        }
      }
    }
  }

  /* Schedule undo/redo function */

  async function undoClassList(e, action) {
    try {
      e.preventDefault();
      let canMove = false;
      let undoPos = 0;
      if (action === 0) {
        if (classListHistoryPos > 0) {
          undoPos = classListHistoryPos - 1;
          canMove = true;
        } else {
          canMove = false;
        }
      } else if (action === 1) {
        if (classListHistoryPos < classListHistory.length - 1) {
          undoPos = classListHistoryPos + 1;
          canMove = true;
        } else {
          canMove = false;
        }
      }
      if (canMove) {
        setUpdateClassList(false);
        let undoClassId = classListHistory[undoPos];
        let redisUrl = `${process.env.REACT_APP_ROOT_URL}/schedule/redis/${undoClassId}`;
        let redisResponse = await fetchData(redisUrl, "GET");
        setClassList(redisResponse.response.classlist);
        setclassListHistoryPos(undoPos);
        JSON.stringify(redisResponse.response.classlist) ===
        JSON.stringify(loadedSchedule.schedule_classlist)
          ? setIsSaved(true)
          : setIsSaved(false);
      }
    } catch (error) {
      console.error(error);
    }
  }

  /* Save classlist in db or redis for undo/redo function */

  const saveClasslist = async () => {
    if (updateClassList) {
      if (classListHistory.length - classListHistoryPos) {
        classListHistory.splice(
          classListHistoryPos + 1,
          classListHistory.length - classListHistoryPos
        );
      }
    }
    if (isLoaded && updateClassList) {
      let newList = {
        schedule_id: loadedSchedule?._id,
        classlist: classList,
      };
      await saveClassListToRedis(newList);
      updateSchedule();
    }
  };

  async function saveClassListToRedis(newList) {
    let redisUrl = `${process.env.REACT_APP_ROOT_URL}/schedule/redis`;
    let redisResponse = await fetchData(redisUrl, "POST", {
      newList,
    });
    setClassListHistory((current) => [...current, redisResponse.identifier]);
    setclassListHistoryPos(classListHistory.length);
  }

  /* Schedule functions - save, update, load new, load, checkout, unload, merge, delete, view, get all */

  async function saveSchedule(scheduleName, scheduleClassList, checkedOut) {
    let centreCode = centre?.centre_code;
    let newSchedule = {
      centre: centreCode,
      schedule_name: scheduleName
        ? scheduleName
        : centreCode +
          "_schedule_" +
          dayjs(new Date()).format("YYYYMMDDHHmmss"),
      schedule_author: userSettings.username,
      schedule_note: "",
      schedule_classlist: scheduleClassList,
      checked_out: checkedOut,
    };
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule`;
    const response = await fetchData(url, "POST", newSchedule);
    return response;
  }

  const updateSchedule = async () => {
    let updatedSchedule = {
      ...loadedSchedule,
      schedule_name: loadedSchedule.schedule_name,
      schedule_author: userSettings.username,
      schedule_classlist: classList,
      update_date: dayjs(new Date()).format(),
    };
    setLoadedSchedule(updatedSchedule);
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${loadedSchedule._id}`;
    await fetchData(url, "PUT", updatedSchedule);
    setIsSaved(true);
  };

  const updateScheduleTitle = (scheduleName) => {
    setLoadedSchedule({
      ...loadedSchedule,
      schedule_name: scheduleName,
    });
    setIsSaved(false);
  };

  const loadNewSchedule = async () => {
    const newSchedule = await saveSchedule(null, classList, true);
    setLoadedSchedule(newSchedule);
    setUpdateClassList(true);
    setClassList([]);
    setSessionDates();
    setIsLoaded(true);
  };

  const loadSchedule = async (scheduleId) => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    const response = await fetchData(url, "GET");
    toggleScheduleCheckout(scheduleId, true);
    setLoadedSchedule(response);
    setUpdateClassList(false);
    setClassList(response.schedule_classlist);
    setSessionDates();
    setIsLoaded(true);
    await saveClassListToRedis({
      schedule_id: response._id,
      classlist: response.schedule_classlist,
    });
  };

  const reloadPublishedSchedule = async (scheduleId) => {
    setUpdateClassList(false);
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    const response = await fetchData(url, "GET");
    setClassList(response.schedule_classlist);
    let redisUrl = `${process.env.REACT_APP_ROOT_URL}/schedule/redis`;
    let savedClasList = {
      schedule_id: scheduleId,
      classlist: response.schedule_classlist,
    };
    const newList = {
      schedule_id: scheduleId,
      classlist: savedClasList,
    };
    let redisResponse = await fetchData(redisUrl, "POST", { newList });
    setClassListHistory([redisResponse.identifier]);
    setclassListHistoryPos(0);
  };

  const archiveSchedule = async (scheduleId) => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    await fetchData(url, "PUT", { is_archive: true });
    getSchedules();
  };

  const toggleScheduleCheckout = async (scheduleId, mode) => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    await fetchData(url, "PUT", { checked_out: mode });
  };

  const unloadSchedule = async (scheduleId) => {
    scheduleId && toggleScheduleCheckout(scheduleId, false);
    setClassList([]);
    setLessonArray([]);
    setSelectedClassList([]);
    setClassListHistory([]);
    setclassListHistoryPos(0);
    setClashClassList([]);
    setFilterObj({});
    setLoadedSchedule("");
    // clearClassObj();
    setClassObj(classObjInit);
    setWeekdayArray(null, null, false);
    setIsLoaded(false);
  };

  const mergeSchedule = async (scheduleId, schedule, closeModal) => {
    let scheduleToMerge = [];
    if (scheduleId) {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
      const response = await fetchData(url, "GET");
      scheduleToMerge = response.schedule_classlist;
    } else {
      scheduleToMerge = schedule;
    }
    /* Run the room and teacher clash check */
    let clashCheckArray = [];
    let checkList = ["teacher", "classroom"];
    for (const mergingClass of scheduleToMerge) {
      for (const checkType of checkList) {
        if (mergingClass[checkType]) {
          let classEnd = dayjs(mergingClass.dateStr)
            .add(mergingClass.timeslot?.value || 0, "minute")
            .format();
          let clashCheck = classClashCheck(
            classList,
            mergingClass.dateStr,
            classEnd,
            mergingClass[checkType].value,
            checkType
          );
          clashCheck && clashCheckArray.push(clashCheck);
        }
      }
    }
    if (!clashCheckArray.length) {
      setUpdateClassList(true);
      setClashClassList([]);
      let mergedClassList = mergeModules(classList, scheduleToMerge);
      if (scheduleId) {
        confirmMergeSchedules(scheduleId, mergedClassList);
      } else {
        setClassList(mergedClassList);
        closeModal && setShowNewModal(false);
      }
    } else {
      setClashClassList(clashCheckArray);
      createClashMessage(clashCheckArray);
    }
  };

  function confirmMergeSchedules(scheduleId, mergedClassList) {
    setNewModalContent(
      <MergeConfirmation
        scheduleId={scheduleId}
        deleteSchedule={deleteSchedule}
        mergedClassList={mergedClassList}
        setClassList={setClassList}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  }

  const getSchedules = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedules/${centre?.centre_code}`;
    const response = await fetchData(url, "GET");
    const filteredScheduleList = response.filter(
      (schedule) => schedule._id !== loadedSchedule?._id
    );
    const sortedScheduleList = [...filteredScheduleList].sort((a, b) =>
      b.update_date > a.update_date ? 1 : -1
    );
    setSchedules(sortedScheduleList);
  };

  const viewSchedule = async (scheduleId) => {
    setNewModalContent(
      <ScheduleView
        centre={centre}
        scheduleId={scheduleId}
        isLoaded={isLoaded}
        loadSchedule={loadSchedule}
        mergeSchedule={mergeSchedule}
        closeModal={closeModal}
      />
    );
    setShowNewModal(true);
  };

  async function confirmDeleteSchedule(open, scheduleId) {
    // e.preventDefault();
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    const response = await fetchData(url, "GET");
    if (checkForPublishedClass(response.schedule_classlist) === 0) {
      setNewModalContent(
        <DeleteConfirmation
          deleteMessage="Are you sure you want to delete this schedule?"
          deleteFunction={() => deleteSchedule(open, scheduleId)}
          setShowNewModal={setShowNewModal}
        />
      );
      setShowNewModal(true);
    } else {
      showInfoInModal(
        "You cannot delete a schedule with published classes, please archive it instead."
      );
    }
  }

  const deleteSchedule = async (open, scheduleId) => {
    if (open === true) {
      unloadSchedule(scheduleId);
    }
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    await fetchData(url, "DELETE");
    getSchedules();
    setShowNewModal(false);
  };

  const getExportList = () => {
    let exportList = JSON.parse(JSON.stringify(classList));
    if (selectedClassList.length > 0) {
      exportList = exportList.filter((item) =>
        selectedClassList.includes(item.temp_id)
      );
    }
    return exportList;
  };

  const sortedClassList = [...classList].sort((a, b) =>
    a.dateStr > b.dateStr ? 1 : -1
  );

  function cfl(str) {
    return str && str.charAt(0).toUpperCase() + str.slice(1);
  }

  /* Show modal functions */

  /* Show teacher schedules in modal */
  function showTeacherTemplates() {
    setNewModalContent(
      <Teachers
        centre={centre}
        centreCalendar={centreCalendar}
        loadedSchedule={loadedSchedule}
        mergeSchedule={mergeSchedule}
        disabledDates={disabledDates}
        selectedSession={selectedSession}
        classObj={classObj}
        handleClassObj={handleClassObj}
        closeModal={closeModal}
      />
    );
    setShowNewModal(true);
  }

  /* Show centre calendar in modal */
  function showCentreCalendar() {
    setNewModalContent(
      <CentreCalendar
        centre={centre}
        centreCalendar={centreCalendar}
        setCentreCalendar={setCentreCalendar}
        setDisabledDates={setDisabledDates}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  }

  /* Show centre classrooms in modal */
  function showClassrooms() {
    setNewModalContent(
      <Classroom centre={centre} setShowNewModal={setShowNewModal} />
    );
    setShowNewModal(true);
  }

  function showErrorModal(errorMessage) {
    setNewModalContent(
      <Error errorMessage={errorMessage} closeModal={closeModal} />
    );
    setShowNewModal(true);
  }

  /* Show date ranges (renamed from sessions) in modal */
  function showSessions() {
    setNewModalContent(
      <Sessions
        centre={centre}
        disabledDates={disabledDates}
        selectedSession={selectedSession}
        setSelectedSession={setSelectedSession}
        closeModal={closeModal}
      />
    );
    setShowNewModal(true);
  }

  /* Show user settings in modal */
  function showSettings() {
    setNewModalContent(<ScheduleSettings setShowNewModal={setShowNewModal} />);
    setShowNewModal(true);
  }

  /* Show module-lesson config in modal */
  function showLessonConfig(lessons) {
    setNewModalContent(
      <Lessons
        centre={centre}
        lessonArray={lessonArray}
        setLessonArray={setLessonArray}
        lessons={lessons}
        classObj={classObj}
        setClassObj={setClassObj}
        handleClassObj={handleClassObj}
        closeModal={closeModal}
        getModules={getModules}
        selectedLessonIdList={selectedLessonIdList}
      />
    );
    setShowNewModal(true);
  }

  function closeModal() {
    setShowNewModal(false);
  }

  /* Show teacher schedule from current schedule in modal */
  function showTeacherSchedule(teacher) {
    setNewModalContent(
      <TeacherSchedule
        centre={centre}
        teacher={teacher}
        loadedSchedule={loadedSchedule}
        closeModal={closeModal}
      />
    );
    setShowNewModal(true);
  }

  /* Show selected lesson or activity history in modal */
  const showLessonHistory = async (
    lessonId,
    lessonTitle,
    classType,
    teacherName
  ) => {
    setNewModalContent(
      <LessonHistory
        centre={centre}
        lessonId={lessonId}
        lessonTitle={lessonTitle}
        classType={classType}
        teacherName={teacherName}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  };

  /* Show activity session details form in modal */
  const showSessionForm = async (activitylist) => {
    let listClone = [...activitylist];
    setNewModalContent(
      <SessionForm
        activityList={listClone}
        addSessionDetails={addSessionDetails}
        setShowNewModal={setShowNewModal}
        mode="edit"
      />
    );
    setShowNewModal(true);
  };

  /* Show news modal (if update since user login or click version link) */
  function showNewsModal() {
    setNewModalContent(<News setShowNewModal={setShowNewModal} />);
    setTimeout(() => {
      setShowNewModal(true);
    }, "3000");
  }

  /* Show user feedback form in modal */
  function showFeedbackForm() {
    setNewModalContent(<FeedbackForm setShowNewModal={setShowNewModal} />);
    setShowNewModal(true);
  }

  /* Show basic info modal, no buttons */
  function showInfoInModal(info) {
    setNewModalContent(info);
    setShowNewModal(true);
  }

  /* Filter functions */

  /* Remove selected filter and dependent filters */
  function clearFilterObj(filter) {
    let filterObjCopy = { ...filterObj };
    if (Object.keys(filterObjCopy).length > 0) {
      let filterIndex = Object.keys(filterObjCopy).indexOf(filter);
      while (Object.keys(filterObjCopy)[filterIndex]) {
        delete filterObjCopy[Object.keys(filterObjCopy)[filterIndex]];
      }
      setFilterObj((filterObj) => ({
        ...filterObjCopy,
      }));
    }
  }

  /* Display applied filters */
  const filterAlerts = Object.keys(filterObj).map((key, index) => {
    return (
      <div className="filter-div" key={index}>
        <span className="filter-span" onClick={() => getColumnFilter(key)}>
          Filter: {key}
        </span>
        <img
          src={delete_icon}
          className="delete-icon"
          alt="Clear filter icon"
          title="Clear filter"
          onClick={() => clearFilterObj(key)}
        />
      </div>
    );
  });

  return (
    <>
      <div className="sticky">
        <div className="schedule-container-div">
          <div className="schedule-container-left-div">
            Select centre to begin.
            {selectedSession
              ? ` Selected dates: ${selectedSession.session_name} (${dayjs(
                  selectedSession.from_date
                ).format("DD/MM/YYYY")} to ${dayjs(
                  selectedSession.to_date
                ).format("DD/MM/YYYY")}). `
              : " No dates selected. "}
            Classes to add = {dateCount}.
          </div>
          {centre && (
            <div className="schedule-container-right-div">
              <span className="link" onClick={showTeacherTemplates}>
                Teacher template
              </span>{" "}
              |{" "}
              <span className="link" onClick={showCentreCalendar}>
                Centre calendar
              </span>{" "}
              |{" "}
              <span className="link" onClick={showSessions}>
                Dates
              </span>{" "}
              |{" "}
              {!isVirtual && (
                <span>
                  <span className="link" onClick={showClassrooms}>
                    Classrooms
                  </span>{" "}
                  |{" "}
                </span>
              )}
              <span className="link" onClick={showFeedbackForm}>
                Feedback
              </span>{" "}
              |{" "}
              <span className="link" onClick={showSettings}>
                Settings
              </span>
            </div>
          )}
        </div>
        <div name="class-form" id="class-form" className="schedule-form">
          <div className="schedule-form-input-div">
            <div className="schedule-form-centre-div">
              <CentreSelector
                centre={centre}
                setCentre={setCentre}
                loadedSchedule={loadedSchedule}
              />
              {isLoaded && (
                <>
                  <input
                    name="schedule_name"
                    className="schedule-form-title"
                    placeholder="Schedule name ..."
                    maxLength={50}
                    value={loadedSchedule && loadedSchedule.schedule_name}
                    onChange={(e) => updateScheduleTitle(e.target.value)}
                  />
                  <div className="schedule-form-header-div">Last updated:</div>
                  <div className="schedule-form-header-div-content">
                    {loadedSchedule &&
                      dayjs(loadedSchedule.update_date).format(
                        "DD/MM/YYYY HH:mm"
                      )}
                  </div>
                  <div className="schedule-form-header-div">Classes:</div>
                  <div className="schedule-form-header-div-content numeric-column">
                    {loadedSchedule && classList.length}
                  </div>
                </>
              )}
            </div>

            <div className="schedule-form-class-div">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                todayButton="Today"
                calendarStartDay={1}
                className="schedule-form-date"
                monthsShown={userSettings.user_settings.calendar_display}
                disabled={!isLoaded}
                minDate={new Date(today)}
                selected={startDate}
                excludeDates={disabledDates}
                highlightDates={disabledDates}
                onChange={(startDate) =>
                  handleStartDate(
                    dayjs(startDate).startOf("date").valueOf(),
                    "start"
                  )
                }
              />
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="schedule-form-date"
                calendarStartDay={1}
                monthsShown={userSettings.user_settings.calendar_display}
                disabled={!isLoaded}
                minDate={startDate}
                selected={endDate || startDate}
                excludeDates={disabledDates}
                highlightDates={disabledDates}
                onChange={(endDate) =>
                  handleStartDate(dayjs(endDate).endOf("date").valueOf(), "end")
                }
              />
              <DayPickerComponent
                weekday={weekday}
                isEnabled={isLoaded}
                setWeekdayArray={setWeekdayArray}
              />
              {/* <span
                title="Set frecuency (not working yet)"
                onClick={showFrequencyForm}
                className="weekday-selector"
              >
                F
              </span> */}
              <TimeslotSelector
                centre={centre}
                classObj={classObj}
                timeslotRef={timeslotRef}
                handleClassObj={handleClassObj}
                isLoaded={isLoaded}
              />
              <input
                ref={durationRef}
                name="duration"
                id="duration"
                title="Duration"
                disabled={!isLoaded}
                placeholder="Dur."
                className="schedule-form-duration"
                value={classObj?.duration.value || ""}
                onChange={(e) =>
                  handleClassObj(
                    { value: e.target.value, label: e.target.value },
                    "duration"
                  )
                }
              ></input>
              <ClassroomSelector
                centre={centre}
                classObj={classObj}
                handleClassObj={handleClassObj}
                isVirtual={isVirtual}
                isLoaded={isLoaded}
              />
              <div className="class-type-selector" title="Open or closed class">
                <input
                  type="checkbox"
                  id="isClosed"
                  name="isClosed"
                  disabled={!isLoaded}
                  value={classObj.isClosed.value}
                  checked={classObj.isClosed.value}
                  className="class-type-selector"
                  onChange={(e) =>
                    handleClassObj(
                      { value: classObj.isClosed.value ? false : true },
                      "isClosed"
                    )
                  }
                />
                <label htmlFor="isClosed" className="closed-label">
                  <img
                    className="class-type-selector-icon"
                    src={classObj.isClosed.value ? closed_icon : open_icon}
                    alt={classObj.isClosed.value ? "Closed" : "Open"}
                    title={classObj.isClosed.value ? "Closed" : "Open"}
                  />
                </label>
              </div>
              <TeacherSelector
                centre={centre}
                classObj={classObj}
                handleClassObj={handleClassObj}
                isLoaded={isLoaded}
              />
              {classObj.teacher && (
                <button
                  className="teacher-schedule-button"
                  title="Show all working schedule classes"
                  onClick={() => showTeacherSchedule(classObj.teacher)}
                >
                  <img
                    src={schedule_icon}
                    alt="Show teacher schedule"
                    className="schedule-icon"
                  />
                </button>
              )}
            </div>
            <div className="schedule-form-class-div">
              <ContentTypeSelector
                classObj={classObj}
                handleClassObj={handleClassObj}
                isSelecting={isSelecting}
                isLoaded={isLoaded}
              />
              {classObj.contentType.value === 2 && (
                <ActivityTypeSelector
                  centre={centre}
                  classObj={classObj}
                  handleClassObj={handleClassObj}
                  isSelecting={isSelecting}
                  setIsSelecting={setIsSelecting}
                  isLoaded={isLoaded}
                />
              )}
              {[1, 3].includes(classObj.contentType?.value) && (
                <ContentLevelSelector
                  centre={centre}
                  classObj={classObj}
                  handleClassObj={handleClassObj}
                  setIsSelecting={setIsSelecting}
                  isLoaded={isLoaded}
                />
              )}
              {[1].includes(classObj.contentType?.value) && (
                <>
                  <PathwaySelector
                    centre={centre}
                    classObj={classObj}
                    handleClassObj={handleClassObj}
                    isSelecting={isSelecting}
                    setIsSelecting={setIsSelecting}
                    isLoaded={isLoaded}
                  />
                  <ThemeSelector
                    centre={centre}
                    classObj={classObj}
                    handleClassObj={handleClassObj}
                    isSelecting={isSelecting}
                    setIsSelecting={setIsSelecting}
                    isLoaded={isLoaded}
                  />
                </>
              )}
              {classObj.contentType.value === 3 && (
                <StrandSelector
                  centre={centre}
                  classObj={classObj}
                  handleClassObj={handleClassObj}
                  isSelecting={isSelecting}
                  setIsSelecting={setIsSelecting}
                  isLoaded={isLoaded}
                  showLessonConfig={showLessonConfig}
                />
              )}
              <LessonSelector
                centre={centre}
                classObj={classObj}
                handleClassObj={handleClassObj}
                isSelecting={isSelecting}
                setIsSelecting={setIsSelecting}
                lessonArray={lessonArray}
                setLessonArray={setLessonArray}
                isLoaded={isLoaded}
                showLessonConfig={showLessonConfig}
                showSessionForm={showSessionForm}
                selectedLessonIdList={selectedLessonIdList}
              />
              <ProductSelector
                classObj={classObj}
                handleClassObj={handleClassObj}
                centre={centre}
                isLoaded={isLoaded}
              />
              <LevelSelector
                centre={centre}
                classObj={classObj}
                handleClassObj={handleClassObj}
                isLoaded={isLoaded}
              />
              {isVirtual === 1 && (
                <VirtualCentreSelector
                  centre={centre}
                  classObj={classObj}
                  handleClassObj={handleClassObj}
                  isLoaded={isLoaded}
                />
              )}
              <CreditSelector
                classObj={classObj}
                handleClassObj={handleClassObj}
                isLoaded={isLoaded}
              />
              <img
                src={isLoaded ? clear_icon : clear_icon_off}
                alt="Clear form icon"
                title="Clear form"
                className="clear-icon"
                onClick={(e) => clearClassObj(e)}
              />
              {Object.keys(filterObj).length > 0 && <>{filterAlerts}</>}
              {clashClassList.length > 0 && (
                <div className="filter-div">
                  <span>Clear clash list</span>
                  <img
                    src={delete_icon}
                    className="delete-icon"
                    alt="Clear filter icon"
                    title="Clear filter"
                    onClick={() => setClashClassList([])}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="schedule-form-button-div">
            {isLoaded && (
              <button
                className={`schedule-form-button ${
                  !isSaved && "save-button-active"
                }`}
                onClick={updateSchedule}
              >
                Save
              </button>
            )}

            {isLoaded && (
              <button
                className="schedule-form-button"
                onClick={() => unloadSchedule(loadedSchedule._id)}
              >
                Close
              </button>
            )}
            {loadedSchedule && (
              <div className="half-button-div">
                <button
                  className="schedule-form-button  undo-button"
                  onClick={(e) => undoClassList(e, 0)}
                >
                  <img
                    src={classListHistoryPos === 0 ? undo_icon_off : undo_icon}
                    alt="Undo"
                    id="undo"
                    name="undo"
                    className="undo-icon"
                  />
                </button>
                <button
                  className="schedule-form-button  undo-button"
                  onClick={(e) => undoClassList(e, 1)}
                >
                  <img
                    src={
                      classListHistoryPos < classListHistory.length - 1
                        ? redo_icon
                        : redo_icon_off
                    }
                    alt="Redo"
                    className="undo-icon"
                  />
                </button>
              </div>
            )}
            {centre && !isLoaded && (
              <button
                className="schedule-form-button"
                onClick={loadNewSchedule}
              >
                New schedule
              </button>
            )}
            {isLoaded && selectedClassList.length === 0 && (
              <button
                onClick={(e) => addClassesFromDateRange(e)}
                className="schedule-form-button"
              >
                Add classes
              </button>
            )}
            {isLoaded && selectedClassList.length > 0 && (
              <button
                onClick={updateSelectedClass}
                className="schedule-form-button"
              >
                Update
              </button>
            )}
          </div>
          <div className="schedule-form-button-div">
            {isLoaded && (
              <button
                className="schedule-form-button"
                onClick={() => confirmDeleteSchedule(true, loadedSchedule?._id)}
              >
                Delete
              </button>
            )}
            {isLoaded && (
              <CreateUploadSchedule
                centre={centre}
                getExportList={getExportList}
                // classList={classList}
                setNewModalContent={setNewModalContent}
                setShowNewModal={setShowNewModal}
              />
            )}
            {isLoaded && (
              <PublishSchedule
                centre={centre}
                scheduleId={loadedSchedule?._id}
                getExportList={getExportList}
                reloadPublishedSchedule={reloadPublishedSchedule}
                setShowNewModal={setShowNewModal}
                setNewModalContent={setNewModalContent}
                classList={classList}
                setClashClassList={setClashClassList}
                showErrorModal={showErrorModal}
              />
            )}
          </div>
        </div>
      </div>
      <table className="schedule-table" ref={tableRef}>
        <thead>
          <tr>
            <th
              title="Select all/none"
              className="centered-column"
              onClick={() => handleSelectedClassList()}
            >
              <input
                type="checkbox"
                name="selectAll"
                id="selectAll"
                checked={selectedClassList.length || false}
                className="table-header-checkbox"
                onChange={() => handleSelectedClassList()}
              />
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("centre")}
              >
                Class ID
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("week")}
              >
                Date
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("timeslot")}
              >
                Time
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("day")}
              >
                Day
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("duration")}
              >
                Dur.
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("teacher")}
              >
                Teacher
              </span>
            </th>
            <th className={isVirtual ? "hidden-column" : undefined}>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("classroom")}
              >
                Room
              </span>
            </th>
            <th title="Capacity">
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("capacity")}
              >
                Cap.
              </span>
            </th>
            <th title="Module">
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("module")}
              >
                Mod.
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("pathway")}
              >
                Pth.
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("theme")}
              >
                Theme
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("lesson")}
              >
                Lesson
              </span>
            </th>
            <th title="Lesson/activity history">H</th>
            <th title="Session details">S</th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("levels")}
              >
                Level(s)
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("products")}
              >
                Product(s)
              </span>
            </th>
            <th className={!isVirtual ? "hidden-column" : undefined}>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("virtualCentres")}
              >
                Countries
              </span>
            </th>
            <th>
              <span
                className="table-filter-column"
                onClick={() => getColumnFilter("credit")}
              >
                Cr.
              </span>
            </th>
            <th>
              <i
                className="fa fa-lg fa-lock"
                aria-hidden="true"
                size="lg"
                title="Closed"
              ></i>
            </th>
            <th>
              <span title="Export classes to new or existing schedule">→</span>
            </th>
            <th>
              <span
                className="delete-all-icon"
                title="Delete selected/all classes"
                onClick={(e) => confirmDeleteAllClasses(e)}
              >
                x
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {classList &&
            sortedClassList.map((item) => (
              <tr
                key={item.temp_id}
                className={`
                  ${
                    selectedClassList?.includes(item.temp_id)
                      ? "schedule-table-row-selected"
                      : undefined
                  } ${
                  clashClassList.find((clash) => {
                    return clash.id === item.temp_id;
                  })
                    ? "schedule-table-row-clash"
                    : undefined
                } ${
                  filterIdList.includes(item.temp_id)
                    ? "filtered-column"
                    : undefined
                }  schedule-table-row`}
              >
                <td
                  onClick={() => handleSelectedClassList(item.temp_id)}
                  className={`schedule-table-row-selector ${
                    item.class_id && "published-class"
                  }`}
                >
                  {" "}
                </td>
                <td className="class-id-cell">
                  <span
                    className={item.class_id ? "obs-link" : undefined}
                    title={item.class_id ? "Open CMS2 class page" : "Centre"}
                    onClick={() => obsClassLink(item.class_id, centre?.region)}
                  >
                    {item.class_id ? item.class_id : item.centre.centre_code}
                  </span>
                </td>
                <td
                  className={`${
                    item.dateStr < dayjs(new Date()).format() && "past-date"
                  } schedule-table-centred-column`}
                >
                  {dayjs(item.dateStr).format("DD/MM/YYYY")}
                </td>
                <td className="schedule-table-centred-column">
                  {dayjs(item.dateStr).format("HH:mm")}
                </td>
                <td className="schedule-table-centred-column">
                  {dayjs(item.dateStr).format("ddd")}
                </td>
                <td className="schedule-table-centred-column">
                  {item.duration.value}
                </td>
                <td>
                  <span
                    className="schedule-table-teacher"
                    onClick={() => showTeacherSchedule(item.teacher)}
                  >
                    {item.teacher && item.teacher.label}
                  </span>
                </td>
                <td className={isVirtual ? "hidden-column" : undefined}>
                  {item.classroom && item.classroom.value}
                </td>
                <td className="numeric-column">
                  {item.capacity && item.capacity.value}
                </td>
                <td className="schedule-table-content">
                  {item.module && item.module.label}
                </td>
                <td className="schedule-table-centred-column schedule-table-content">
                  {item.lesson?.type === "activity" ? (
                    <span title={item.lesson.category && item.lesson.category}>
                      {item.lesson.category && item.lesson?.category?.charAt(0)}
                    </span>
                  ) : (
                    <span title={item.pathway && item.pathway.label}>
                      {item.pathway && item.pathway?.label?.charAt(0)}
                    </span>
                  )}
                </td>
                <td className="schedule-table-content">
                  <span title={item.theme?.label}>{item.theme?.value}</span>
                </td>
                <td className="schedule-table-content">
                  {item.lesson && item.lesson.label}
                </td>
                <td className="schedule-table-content schedule-table-centred-column">
                  {item.lesson && (
                    <img
                      src={history_icon}
                      alt="Lesson history"
                      title="See lesson/activity history"
                      className="schedule-table-history-icon"
                      onClick={() =>
                        showLessonHistory(
                          item.lesson.value,
                          item.lesson.label,
                          item.lesson?.type,
                          item.teacher?.label
                        )
                      }
                    />
                  )}
                </td>
                <td className="schedule-table-content schedule-table-centred-column">
                  {item.lesson && item.lesson?.type === "activity" && (
                    <img
                      src={
                        item.lesson?.session_name
                          ? session_icon
                          : session_icon_off
                      }
                      alt="Session details icon"
                      title="Add/edit session details"
                      className="schedule-table-session-icon"
                      onClick={() => checkActivitySelection(item)}
                    />
                  )}
                </td>
                <td className="schedule-table-content">
                  {item.levels.length > 0 && arrToStr(item.levels)}
                </td>
                <td className="schedule-table-content">
                  {item.products.length > 0 && arrToStr(item.products)}
                </td>
                <td className={!isVirtual ? "hidden-column" : undefined}>
                  {item.virtualCentres.length > 0 &&
                    arrToStr(item.virtualCentres)}
                </td>
                <td className="schedule-table-content schedule-table-centred-column">
                  {item.credit.value}
                </td>
                <td className="schedule-table-centred-column schedule-table-image-cell">
                  {item.isClosed.value ? (
                    <img
                      src={closed_icon}
                      alt="Closed"
                      title="Closed class"
                      className="schedule-table-class-type-icon"
                    />
                  ) : (
                    <img
                      src={open_icon}
                      alt="Open"
                      title="Open class"
                      className="schedule-table-class-type-icon"
                    />
                  )}
                </td>
                <td className="schedule-table-centred-column icon-cell">
                  <img
                    src={migrate_icon}
                    alt="Migrate icon"
                    className="migrate-class-icon"
                    title="Migrate class"
                    onClick={() => confirmActionClass(item, "migrate")}
                  />
                </td>
                <td className="schedule-table-centred-column icon-cell">
                  {!item.class_id && (
                    <img
                      src={delete_icon}
                      alt="Delete icon"
                      className="delete-class-icon"
                      title="Delete class"
                      onClick={() => confirmActionClass(item, "delete")}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="schedule-container-div">
        <div className="schedule-container-left-div"></div>
        <div className="schedule-container-right-div"></div>
      </div>

      <ScheduleList
        centre={centre}
        schedules={schedules}
        isLoaded={isLoaded}
        loadSchedule={loadSchedule}
        viewSchedule={viewSchedule}
        mergeSchedule={mergeSchedule}
        archiveSchedule={archiveSchedule}
        confirmDeleteSchedule={confirmDeleteSchedule}
      />
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </>
  );
}
