import { v4 as uuid } from "uuid";

function replaceClashingTempIds(array1, array2) {
  const tempIds1 = new Set(array1.map((item) => item.temp_id));
  array2.forEach((item) => {
    if (tempIds1.has(item.temp_id)) {
      item.temp_id = generateNewTempId();
    }
  });
}

function generateNewTempId() {
  const newTempId = uuid();
  return newTempId;
}

export default function mergeModules(array1, array2) {
  let copiedArray1 = JSON.parse(JSON.stringify(array1));
  let copiedArray2 = JSON.parse(JSON.stringify(array2));
  let maxModuleNumber = Math.max(
    ...copiedArray1.map((item) =>
      item.module && !item.class_id
        ? parseInt(item.module.value.split(":")[0].substring(3))
        : 0
    )
  );
  const moduleMap = new Map();
  const updateModule = (item, modulePart) => {
    if (!moduleMap.has(modulePart)) {
      moduleMap.set(
        modulePart,
        item.module.label.substring(0, 3) + ++maxModuleNumber
      );
    }
    const newModulePart = moduleMap.get(modulePart);
    item.module.value = item.module.value.replace(modulePart, newModulePart);
    item.module.label = item.module.label.replace(modulePart, newModulePart);
  };
  copiedArray2.forEach((item) => {
    if (item.module && !item.class_id) {
      const moduleValue = item.module.value.split(":")[0];
      updateModule(item, moduleValue);
    }
  });
  replaceClashingTempIds(copiedArray1, copiedArray2);
  let mergedArray = [...copiedArray1, ...copiedArray2];
  return mergedArray;
}
