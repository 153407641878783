export default async function fetchData(url, method, body) {
  const token = localStorage.getItem("token");
  !token && redirectToLogin();
  const options = {
    method,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(url, options);
    if (!response.ok) {
      redirectToLogin();
      return;
    }
    return await response.json();
  } catch (error) {
    redirectToLogin();
  }
}

function redirectToLogin() {
  localStorage.removeItem("token");
  window.location.href = "/login";
}
