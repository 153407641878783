import { useState, useEffect } from "react";

export default function Spinner({ message, timer }) {
  const [timeElapsed, setTimeElapsed] = useState(0);

  useEffect(() => {
    if (timer) {
      const interval = setInterval(() => {
        setTimeElapsed((prevTime) => prevTime + 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  return (
    <div>
      <p>{message}</p>
      <div className="spinner-div">
        <i className="fa fa-spinner fa-spin" aria-hidden="true"></i>
      </div>
      {timer && (
        <div className="timer-div" id="time">{`${Math.floor(
          timeElapsed / 60
        )}m ${timeElapsed % 60}s`}</div>
      )}
    </div>
  );
}
