import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import "./style/selectors.css";
import setSyllabusLevelCache from "./scripts/setSyllabusLevelCache.js";

export default function PathwaySelector({
  centre,
  classObj,
  handleClassObj,
  isSelecting,
  setIsSelecting,
  isLoaded,
}) {
  const [pathwayOptions, setPathwayOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Pathway..");
  const selectRef = useRef();

  const getPathwayOptions = async () => {
    if (classObj.contentType?.value === 1) {
      if (!isSelecting) {
        setPathwayOptions([]);
        selectRef.current.clearValue();
      }
      if (centre && classObj.contentLevel) {
        let pathways = [];
        const levelId = classObj.contentLevel.value;
        pathways = JSON.parse(localStorage.getItem("level_" + levelId));
        if (!pathways) {
          pathways = await setSyllabusLevelCache(centre.centre_id, levelId);
        }
        const pathwayArray = Array.from(
          new Set(pathways.map((item) => item.pathway))
        );
        const sortedPathways = [...pathwayArray].sort((a, b) =>
          a > b ? 1 : -1
        );
        const pathwayList = sortedPathways.map((option) => {
          return {
            value: option,
            label: option,
          };
        });
        setPathwayOptions(pathwayList);
      }
    }
  };

  useEffect(() => {
    getPathwayOptions();
  }, [classObj.contentLevel]);

  return (
    <Select
      ref={selectRef}
      className="schedule-form-content-pathway-selector"
      value={classObj.pathway}
      placeholder={placeholder}
      isDisabled={!isLoaded}
      options={pathwayOptions}
      onFocus={() => setIsSelecting(false)}
      onChange={(data) => handleClassObj(data, { name: "pathway" })}
    />
  );
}
