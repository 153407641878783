export default function createLessonArray(data, type) {
  let lessonData = [];
  if (type !== 2) {
    lessonData[0] = data.map((option) => {
      return {
        value: option.lesson_id,
        label: option.lesson_code + ": " + option.lesson_title,
        type: "lesson",
      };
    });
    lessonData[1] = data.map((option) => {
      return {
        value: option.pathway,
        label: option.pathway,
        type: "pathway",
      };
    });
    lessonData[2] = data.map((option) => {
      return {
        value: option.theme_code,
        label: option.theme_code + ": " + option.theme_name,
        theme_id: option.theme_id,
        type: "theme",
      };
    });
  } else {
    lessonData[0] = data.map((option) => {
      return {
        value: option.activity_id,
        label: option.activity_name,
        category: option.category,
        type: "activity",
      };
    });
    lessonData[1] = data.map((option) => {
      return {
        value: option.category,
        label: option.category,
        type: "pathway",
      };
    });
    lessonData[2] = data.map((option) => {
      return {
        value: option.activity_type,
        label: option.activity_type,
        type: "theme",
      };
    });
  }
  return lessonData;
}
