import React, { useEffect, useState } from "react";
import PasswordReset from "./PasswordReset";
import MfaSetup from "./MfaSetup";

export default function Password({ userSettings, setUserSettings }) {
  const [mfaStatus, setMfaStatus] = useState();

  useEffect(() => {
    setMfaStatus(userSettings.mfa_enabled);
  }, []);

  return (
    <div>
      {mfaStatus === false ? (
        <MfaSetup
          userSettings={userSettings}
          setUserSettings={setUserSettings}
          setMfaStatus={setMfaStatus}
        />
      ) : (
        <PasswordReset
          userSettings={userSettings}
          setMfaStatus={setMfaStatus}
        />
      )}
    </div>
  );
}
