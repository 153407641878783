import React, { useEffect, useState } from "react";
import VerifyOtp from "./VerifyOtp.js";
import { useNavigate, useLocation } from "react-router-dom";
import { useToken } from "../auth/useToken";
import { useUserSettings } from "../auth/useUserSettings";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function Login() {
  const location = useLocation();
  const [token, setToken] = useToken();
  const [otpToken, setOtpToken] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [userSettings, setUserSettings] = useUserSettings();
  const [user, setUser] = useState();
  const [showpassword, setShowpassword] = useState(false);
  const [requireOtp, setRequireOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginData, setLoginData] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();

  function handleFormChange(e) {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  }

  /* Check user has valid token and redirect to schedule page or login */
  async function checkLoginStatus() {
    if (token) {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/verify-token/${token}`;
      const response = await fetchData(url, "GET");
      if (response.status !== 200) {
        localStorage.clear();
        setIsLoggedIn(false);
        navigate("/login");
      } else {
        setIsLoggedIn(true);
        navigate(location.state ? location.state : "/schedule");
      }
    }
    setIsLoggedIn(false);
  }

  /* If login process login form */
  const handleLogin = async (e) => {
    e.preventDefault();
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/login`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(loginData),
      });
      if (response.status === 200) {
        let token = await response.json();
        let tempToken = token;
        // document.cookie = `token=${token}`;
        let payload = JSON.parse(atob(token.split(".")[1]));
        const userObj = payload.user;
        setUser(userObj);
        let requireOtp = await checkRequireOtp(userObj.username, tempToken);
        if (!requireOtp) {
          setRequireOtp(true);
          setOtpToken(tempToken);
        } else {
          loginUser(userObj, tempToken);
        }
      } else {
        let responseObj = await response.json();
        setErrorMessage(responseObj.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  /* Check if the user is due an MFA check */
  async function checkRequireOtp(username, tempToken) {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/last-mfa`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tempToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ username: username }),
    }).then((data) => data.json());
    return response;
  }

  /* Log the user in checking if they needs news and logging the login */
  async function loginUser(userObj, tempToken) {
    let requireNewsUpdate = await checkUserNewsUpdate(
      userObj.username,
      tempToken
    );
    setToken(tempToken);
    delete userObj.password;
    setUserSettings(userObj);
    setIsLoggedIn(true);
    logLogin(userObj.username, requireOtp, "login");
    navigate(location.state ? location.state : "/schedule", {
      state: requireNewsUpdate,
    });
    // navigate("/schedule");
  }

  /* Check if the user needs to see the news modal */
  async function checkUserNewsUpdate(username, tempToken) {
    let url1 = `${process.env.REACT_APP_ROOT_URL}/schedule/news/last-update`;
    const response1 = await fetch(url1, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tempToken}`,
      },
    }).then((data) => data.json());
    let latestNews = response1.news_date;
    let url2 = `${process.env.REACT_APP_ROOT_URL}/schedule/admin/last-login/${username}`;
    const response2 = await fetch(url2, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${tempToken}`,
      },
    }).then((data) => data.json());
    let lastLogin = response2[0]?.updatedAt;
    return latestNews > lastLogin;
  }

  /* Log to the user's login */
  async function logLogin(username, mfa, route) {
    let loginObj = { username: username, mfa: mfa, route: route };
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/log-login`;
    await fetchData(url, "POST", loginObj);
  }

  useEffect(() => {
    checkLoginStatus();
  }, []);

  return !isLoggedIn ? (
    <div className="login-wrapper">
      <div className="login-form">
        {requireOtp ? (
          <VerifyOtp
            user={user}
            loginUser={loginUser}
            setRequireOtp={setRequireOtp}
            otpToken={otpToken}
          />
        ) : (
          <div>
            <h3> Please log in</h3>
            <form id="myFormloginForm" onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  name="username"
                  className="login-input"
                  id="username"
                  required
                  onChange={(e) => handleFormChange(e)}
                  value={loginData.username}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type={showpassword ? "text" : "password"}
                  name="password"
                  id="password"
                  className="form-control login-input"
                  onChange={(e) => handleFormChange(e)}
                  value={loginData.password}
                  required
                />
              </div>
              <div className="form-group">
                <label className="form-check-label" htmlFor="showpasswordcheck">
                  <input
                    type="checkbox"
                    checked={showpassword}
                    className="login-show-password-checkbox"
                    name="showpasswordcheck"
                    id="showpasswordcheck"
                    onChange={() => setShowpassword(!showpassword)}
                  />
                  Show password
                </label>
              </div>
              <div className="login-button-div">
                <button
                  type="submit"
                  id="loginBtn"
                  value="Log in"
                  className="schedule-form-button"
                >
                  Log in
                </button>
              </div>
              <div className="login-error-div">{errorMessage}</div>
            </form>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
}
