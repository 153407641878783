import React, { useEffect, useState } from "react";
import NewModal from "./NewModal.js";
import Teachers from "./Teachers.js";
import TeacherViewForm from "./TeacherViewForm.js";
import TeacherViewConfig from "./TeacherViewConfig.js";
import CentreSelector from "./CentreSelector.js";
import { nanoid } from "nanoid";
import { useUserSettings } from "../../auth/useUserSettings";
import setTeacherCache from "./scripts/setTeacherCache.js";
import encrypt from "./scripts/encrypt.js";
import fetchData from "./scripts/fetchData.js";
import convertMinsToTimeSlot from "./scripts/convertMinutesToTimeslot.js";

import hide from "./images/hide.svg";
import unhide from "./images/unhide.svg";
import select_all from "./images/select_all.png";
import deselect_all from "./images/deselect_all.png";

export default function TeacherView() {
  const [userSettings] = useUserSettings();
  const [newModalContent, setNewModalContent] = useState();
  const [dayParts, setDayParts] = useState([360, 540, 720, 1080, 1440]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [centre, setCentre] = useState();
  const [teacherArray, setTeacherArray] = useState([]);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [hideUnusedTeachers, setHideUnusedTeachers] = useState(false);
  const [orderedDays, setOrderedDays] = useState([1, 2, 3, 4, 5, 6, 0]);
  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [teacherSchedules, setTeacherSchedules] = useState([]);

  function closeModal() {
    setShowNewModal(false);
  }

  /* Display weekday order by user settings */
  function reorderOrderedDays() {
    let firstDay = userSettings?.user_settings.first_day_of_week;
    let days = [...orderedDays];
    let index = days.indexOf(firstDay);
    let newOrder = days.slice(index);
    let beforeDays = days.slice(0, index);
    newOrder.push(...beforeDays);
    setOrderedDays(newOrder);
  }

  /* Get list of active teachers */
  const getTeachers = async () => {
    let user_option = userSettings.user_settings.teacher_search;
    let teachers = [];
    if (centre) {
      teachers = JSON.parse(
        encrypt("decrypt", localStorage.getItem("teachers"))
      );
      if (!teachers) {
        teachers = await setTeacherCache(centre.country_id);
      }
      if (teachers) {
        let filteredTeachers = teachers;
        if (user_option === 0) {
          filteredTeachers = teachers.filter(
            (teacher) => teacher.centre_id === centre.centre_id
          );
        }
        const sortedTeachers = [...filteredTeachers].sort((a, b) =>
          a.first_name > b.first_name ? 1 : -1
        );
        getTeacherScheduleTemplates(sortedTeachers);
      }
    }
  };

  /* Get teacher schedules */
  const getTeacherScheduleTemplates = async (teachers) => {
    for (const teacher of teachers) {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/teachers-schedule-template/${teacher.teacher_id}`;
      const response = await fetchData(url, "GET");
      if (response.hasOwnProperty("teacher_id")) {
        const teacherIndex = teachers.findIndex(
          (item) => item.teacher_id === response.teacher_id
        );
        teachers[teacherIndex].schedule =
          teacherIndex !== -1 ? response.schedule : [];
      }
    }
    setTeacherArray(teachers);
  };

  function showTeacherTemplates(teacherId) {
    setNewModalContent(
      <Teachers
        centre={centre}
        isLoaded={false}
        teacherId={teacherId}
        closeModal={closeModal}
        refreshTeachers={getTeachers}
      />
    );
    setShowNewModal(true);
  }

  function showNewScheduleForm() {
    setNewModalContent(
      <TeacherViewForm
        centre={centre}
        teacherSchedules={teacherSchedules}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  }

  function showTeacherViewConfig() {
    setNewModalContent(
      <TeacherViewConfig
        dayParts={dayParts}
        handleDayPartChange={handleDayPartChange}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  }

  const handleDayPartChange = (newDayParts) => {
    setDayParts(newDayParts);
  };

  useEffect(() => {
    getTeachers();
  }, [centre]);
  useEffect(() => {
    reorderOrderedDays();
  }, [userSettings]);

  /* Display list of centre teachers in left column */
  const teacherListing = teacherArray?.map((teacher) => {
    return (
      <div
        key={teacher.teacher_id}
        className={`${
          !teacher.schedule?.length > 0 && hideUnusedTeachers
            ? "hide-unused-teacher"
            : undefined
        } teacher-name-div`}
      >
        {teacher.schedule?.length > 0 ? (
          <div
            className={`${
              selectedTeachers?.includes(teacher.teacher_id)
                ? "selected-teacher"
                : undefined
            } ${
              teacher.schedule?.length > 0 ? "teacher-has-schedule" : undefined
            }  teacher-name`}
            onClick={() => handleSelectedTeachers(teacher.teacher_id)}
          >
            {teacher.first_name + " " + teacher.last_name}
          </div>
        ) : (
          <div className="teacher-name">
            {teacher.first_name + " " + teacher.last_name}
          </div>
        )}
        <div
          className="teacher-name-config-div"
          onClick={() => showTeacherTemplates(teacher.teacher_id)}
        ></div>
      </div>
    );
  });

  //function to select all teachers who have schedule.length > 0
  const selectAllTeachers = () => {
    const teachersWithSchedule = teacherArray.filter(
      (teacher) => teacher.schedule?.length > 0
    );
    const teacherIds = teachersWithSchedule.map(
      (teacher) => teacher.teacher_id
    );
    for (const teacherId of teacherIds) {
      if (!selectedTeachers.includes(teacherId)) {
        handleSelectedTeachers(teacherId);
      }
    }
  };

  function deselectAllSelectedTeachers() {
    setSelectedTeachers([]);
    setTeacherSchedules([]);
  }

  const handleSelectedTeachers = (teacherId) => {
    if (!selectedTeachers.includes(teacherId)) {
      setSelectedTeachers((currentList) => [...currentList, teacherId]);
      addToTeacherSchedules(teacherId);
    } else {
      setSelectedTeachers((current) =>
        current.filter((id) => id !== teacherId)
      );
      removeFromTeacherSchedules(teacherId);
    }
  };

  /* Add selected teachers timeslots to the schedule array for display */
  const addToTeacherSchedules = (teacherId) => {
    const teacher = teacherArray.find(
      (teacher) => teacher.teacher_id === teacherId
    );
    const teacherSchedule = teacher.schedule.map((schedule) => {
      return {
        ...schedule,
        first_name: teacher.first_name,
        last_name: teacher.last_name,
        teacher_id: teacher.teacher_id,
      };
    });
    setTeacherSchedules((current) => [...current, ...teacherSchedule]);
  };

  /* Remove deselected teacher's timeslots from the schedule array */
  function removeFromTeacherSchedules(teacherId) {
    setTeacherSchedules((current) =>
      current.filter((item) => item.teacher_id !== teacherId)
    );
  }

  /* Display selected teachers timeslots in schedule table */
  const teacherScheduleDisplay = orderedDays.map((day) => {
    // Dynamically initialize displaySlots based on dayParts array
    const displaySlots = dayParts.slice(0, dayParts.length - 1).map(() => []);
    const orderedTeacherSchedules = teacherSchedules.sort(
      (a, b) => a.timeslot.value - b.timeslot.value
    );
    orderedTeacherSchedules?.forEach((item) => {
      if (item.day.value === day) {
        dayParts.every((dayPart, index) => {
          if (
            item.timeslot.value >= dayPart &&
            item.timeslot.value < dayParts[index + 1]
          ) {
            displaySlots[index].push(item);
            return false;
          }
          return true;
        });
      }
    });

    const renderTimeslots = (timeslots) =>
      timeslots.map((item) => (
        <span className="teacher-timeslot" key={nanoid()}>
          {item.timeslot.label + " (" + item.duration.value + ") - "}
          {item.first_name + " " + item.last_name}
        </span>
      ));

    return (
      <tr key={nanoid()}>
        <td className="teacher-table-day-name">{weekdays[day]}</td>
        {displaySlots.map((slot, index) => (
          <td className="teacher-table-cell" key={index}>
            {renderTimeslots(slot)}
          </td>
        ))}
      </tr>
    );
  });

  return (
    <div>
      <h2>Teacher overview</h2>
      <hr />
      <div className="schedule-container-div">
        <div className="centre-selector-div">
          <CentreSelector centre={centre} setCentre={setCentre} />
        </div>
        <div className="schedule-container-right-div">
          <button
            className="schedule-form-button"
            onClick={showNewScheduleForm}
          >
            Create schedule
          </button>
          <button
            className="schedule-form-button"
            onClick={showTeacherViewConfig}
          >
            Config
          </button>
        </div>
      </div>
      <div className="teacher-layout">
        <div>
          <div className="teacher-name-div">
            <div className="teacher-name-header-div">
              <img
                src={select_all}
                title="Select all teachers with schedule"
                alt="Select all teachers with schedule"
                className="teacher-hide-icon"
                onClick={() => selectAllTeachers()}
              />
              <img
                src={deselect_all}
                title="Deselect selected teachers"
                alt="Deselect selected teachers"
                className="teacher-hide-icon"
                onClick={() => deselectAllSelectedTeachers()}
              />
              <img
                src={hideUnusedTeachers ? unhide : hide}
                title={
                  hideUnusedTeachers
                    ? "Show all teachers"
                    : "Hide teachers without schedule"
                }
                alt="Hide"
                className="teacher-hide-icon"
                onClick={() =>
                  setHideUnusedTeachers(hideUnusedTeachers ? false : true)
                }
              />
            </div>
          </div>
          {teacherListing}
        </div>
        <table className="teacher-table">
          <thead>
            <tr>
              <th></th>
              {dayParts.slice(0, dayParts.length - 1).map((part) => (
                <th key={part}>{convertMinsToTimeSlot(part)}</th>
              ))}
            </tr>
          </thead>
          <tbody>{teacherScheduleDisplay}</tbody>
        </table>
      </div>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
