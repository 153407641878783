export default function DeleteConfirmation({
  scheduleId,
  deleteSchedule,
  setClassList,
  mergedClassList,
  setShowNewModal,
}) {
  const mergeAndDelete = async () => {
    await deleteSchedule(false, scheduleId);
    setClassList(mergedClassList);
    setShowNewModal(false);
  };

  const mergeAndKeep = () => {
    setClassList(mergedClassList);
    setShowNewModal(false);
  };

  return (
    <div>
      <h3>Schedule merge</h3>
      <div className="merge-message-div">
        <p>No clashes found.</p>
        <p>Do you want to delete or keep the schedule you are merging?</p>
        <p>
          The module codes of unpublished modules in the schedule your are
          merging may be changed to prevent clashes.
        </p>
      </div>
      <div className="delete-button-div">
        <button
          className="schedule-form-button delete-button"
          onClick={mergeAndDelete}
        >
          Delete
        </button>
        <button
          className="schedule-form-button keep-button"
          onClick={mergeAndKeep}
        >
          Keep
        </button>
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
