import fetchData from "./fetchData.js";
import encrypt from "./encrypt.js";

export default async function setTeacherCache(countryId) {
  let teacherList = [];
  let url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/teachers/${countryId}`;
  const response = await fetchData(url, "GET");
  if (response) {
    teacherList = encrypt("encrypt", JSON.stringify(response.data));
    localStorage.setItem("teachers", teacherList);
    return teacherList;
  }
}
