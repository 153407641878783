import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useToken } from "../auth/useToken";
import { useUserSettings } from "../auth/useUserSettings";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function LoginObs() {
  const [token, setToken] = useToken();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [userSettings, setUserSettings] = useUserSettings();
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const { obstoken } = useParams();

  async function logLogin(loginData) {
    let userObj = { username: loginData, mfa: false, route: "obs" };
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/log-login`;
    await fetchData(url, "POST", userObj);
  }

  async function checkUserNewsUpdate(username) {
    let url1 = `${process.env.REACT_APP_ROOT_URL}/schedule/news/last-update`;
    const response1 = await fetchData(url1, "GET");
    let latestNews = response1.news_date;
    let url2 = `${process.env.REACT_APP_ROOT_URL}/schedule/admin/last-login/${username}`;
    const response2 = await fetchData(url2, "GET");
    let lastLogin = response2[0].updatedAt;
    return latestNews > lastLogin;
  }

  const loginUser = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs-login`;
    try {
      const new_token = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${obstoken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: obstoken }),
      }).then((data) => data.json());
      setToken(new_token);
      let payload = JSON.parse(atob(new_token.split(".")[1]));
      setUserSettings(payload.user);
      logLogin(payload.user.username);
      let requireNewsUpdate = await checkUserNewsUpdate(payload.user.username);
      setIsLoggedIn(true);
      navigate("/schedule", {
        state: requireNewsUpdate,
      });
    } catch (error) {
      setErrorMessage("Whoops, something has gone wrong.");
    }
  };

  useEffect(() => {
    loginUser();
  }, []);

  return isLoggedIn ? (
    <>
      {errorMessage && (
        <>
          <h2>Error</h2>
          <p>{errorMessage}</p>
        </>
      )}
    </>
  ) : (
    <>Logging in ...</>
  );
}
