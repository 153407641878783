export default function PublishConfirmation({
  classCount,
  startPublishing,
  setShowNewModal,
}) {
  return (
    <div>
      <div className="delete-message-div">
        You are publishing {classCount} class{classCount > 1 && "es"}. Are you
        sure?
      </div>
      <div className="delete-button-div">
        <button
          className="schedule-form-button publish-button"
          onClick={startPublishing}
        >
          Sure
        </button>
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
