import React, { useEffect, useState, useRef } from "react";
import fetchData from "../components/schedule/scripts/fetchData.js";
import green_tick from "../components/schedule/images/green_tick.png";
import red_cross from "../components/schedule/images/red_cross.png";
import blank from "../components/schedule/images/blank.png";

export default function MfaSetup({
  userSettings,
  setUserSettings,
  setMfaStatus,
}) {
  const [qrCode, setQrCode] = useState();
  const [otp, setOtp] = useState("");
  const [otpIconSrc, setOtpIconSrc] = useState(blank);
  const [showReset, setShowReset] = useState();
  const otpRef = useRef();

  function checkMfaStatus() {
    if (userSettings.mfa_enabled === true) {
      setShowReset(true);
    } else {
      getMfaSecret();
    }
  }
  console.log(showReset);

  async function getMfaSecret() {
    setShowReset(false);
    setOtp("");
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/generate-secret`;
    const response = await fetchData(url, "POST", {
      username: userSettings.username,
    });
    if (response) {
      setQrCode(response.qrImageDataUrl);
      otpRef.current?.focus();
    }
  }

  function confirmOtpSuccess() {
    setUserSettings({ ...userSettings, mfa_enabled: true });
    setMfaStatus(true);
  }

  async function checkOtp() {
    if (otp?.length === 6) {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/verify-otp`;
      let otpObj = { username: userSettings.username, token: otp };
      const response = await fetchData(url, "POST", otpObj);
      if (response.verified === false) {
        setOtpIconSrc(red_cross);
        // setOtp("");
      } else if (response.verified === true) {
        setOtpIconSrc(green_tick);
        setTimeout(confirmOtpSuccess, 2000);
      }
    } else {
      setOtpIconSrc(blank);
    }
  }

  useEffect(() => {
    checkMfaStatus();
  }, []);
  useEffect(() => {
    checkOtp();
  }, [otp]);

  return (
    <div>
      <h3>Multi-factor authentication setup</h3>
      <div className="password-compliance-text">
        To use a password to log in, you must have multi-factor authentication
        (MFA) set up. Please use an authentication app such as Microsoft or
        Google Authenticator to scan the QR code and then enter the 6-digit
        number shown in the app.
      </div>
      <div className="centered-column">
        {showReset === true ? (
          <button className="schedule-form-button" onClick={getMfaSecret}>
            Generate new QR code
          </button>
        ) : (
          <div>
            <img alt="QR code for two-factor authentication" src={qrCode} />
            <form>
              <input
                ref={otpRef}
                type="text"
                name="otp"
                id="otp"
                value={otp}
                maxLength="6"
                className="mfa-otp-input"
                placeholder="Enter 6-digit number"
                onChange={(e) => setOtp(e.target.value)}
              />
              <img
                src={otpIconSrc}
                className="mfa-otp-icon"
                alt="OTP verification status"
              />
            </form>
          </div>
        )}
      </div>
    </div>
  );
}
