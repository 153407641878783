import { useEffect, useState } from "react";
import dayjs from "dayjs";
import fetchData from "../components/schedule/scripts/fetchData.js";
import "../components/schedule/style/schedule.css";

export default function LoginHistory({ username, setShowNewModal }) {
  const [loginHistory, setLoginHistory] = useState([]);
  const [rowLimit, setRowLimit] = useState(10);
  const [maxRows, setMaxRows] = useState();

  const getLoginHistory = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/admin/login-history/${username}/${rowLimit}`;
    const response = await fetchData(url, "GET");
    setMaxRows(response.count);
    setLoginHistory(response.results);
  };
  console.log(maxRows);

  function showMoreRows() {
    setRowLimit(rowLimit + 10);
  }

  console.log(rowLimit);

  const showLoginHistory = loginHistory?.map((login) => {
    return (
      <tr>
        <td className="centered-column">
          {dayjs(login.createdAt).format("DD/MM/YYYY")}
        </td>
        <td className="centered-column">
          {dayjs(login.createdAt).format("HH:mm:ss")}
        </td>
        <td>{dayjs(login.createdAt).format("ddd")}</td>
        <td>{login.mfa ? "Yes" : "No"}</td>
        <td>{login.route}</td>
      </tr>
    );
  });

  useEffect(() => {
    getLoginHistory();
  }, [username, rowLimit]);

  return (
    <div className="login-history-div">
      <h3>Login history - {username}</h3>
      <div className="login-history-heading">
        <div></div>
        <div className="right-aligned-column">
          {loginHistory?.length}/{maxRows}
        </div>
      </div>
      <table className="login-history-table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Time</th>
            <th>Day</th>
            <th>MFA</th>
            <th>Route</th>
          </tr>
        </thead>
        <tbody>{showLoginHistory}</tbody>
      </table>
      <div className="modal-button-div">
        {rowLimit < maxRows && (
          <button className="schedule-form-button" onClick={showMoreRows}>
            Show more
          </button>
        )}
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Close
        </button>
      </div>
    </div>
  );
}
