import React, { useState, useEffect, useRef } from "react";
import NewModalCloseButton from "./NewModalCloseButton";

import fetchData from "./scripts/fetchData.js";
import delete_icon from "../schedule/images/delete_icon.svg";

export default function Classroom({ centre, setShowNewModal }) {
  const centre_code = centre?.centre_code || "TBC";
  const [classrooms, setClassrooms] = useState([]);
  const [classroom, setClassroom] = useState({
    classroom_name: "",
    classroom_capacity: 0,
    centre: centre_code,
  });
  const divRef = useRef(null);
  const nameRef = useRef();
  const capacityRef = useRef();

  const getClassrooms = async () => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/classrooms/${centre_code}`;
    const response = await fetchData(url, "GET");
    setClassrooms(response);
  };

  useEffect(() => {
    getClassrooms();
  }, [centre]);

  const addClassroom = async () => {
    if (!classroom.classroom_name) {
      nameRef.current.focus();
    } else if (!classroom.classroom_capacity) {
      capacityRef.current.focus();
    } else {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/classrooms`;
      await fetchData(url, "POST", classroom);
      getClassrooms();
      setClassroom({
        ...classroom,
        classroom_name: "",
        classroom_capacity: 0,
      });
    }
  };

  const deleteClassroom = async (classroomId) => {
    const url = `${process.env.REACT_APP_ROOT_URL}/schedule/classrooms/${classroomId}`;
    await fetchData(url, "DELETE");
    getClassrooms();
  };

  function handleClassroomForm(e) {
    setClassroom({
      ...classroom,
      [e.target.name]: e.target.value,
    });
  }

  const orderedClassrooms = /\d/.test(classrooms[0]?.classroom_name)
    ? [...classrooms]?.sort((a, b) =>
        Number(a.classroom_name.replace(/[^0-9]/g, "")) >
        Number(b.classroom_name.replace(/[^0-9]/g, ""))
          ? 1
          : -1
      )
    : [...classrooms]?.sort((a, b) =>
        a.classroom_name > b.classroom_name ? 1 : -1
      );

  const classroomList = orderedClassrooms?.map((classroom) => {
    return (
      <tr key={classroom._id}>
        <td>{classroom.centre}</td>
        <td>{classroom.classroom_name}</td>
        <td className="numeric-column">{classroom.classroom_capacity}</td>
        <td className="centered-column icon-cell">
          <img
            src={delete_icon}
            alt="Delete classroom"
            title="Delete classroom"
            className="delete-icon"
            onClick={() => deleteClassroom(classroom._id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <div className="classrooms-div">
      <h2>Classrooms {centre && ` - ${centre.centre_code}`}</h2>
      <div className="schedule-form">
        <input
          ref={nameRef}
          name="classroom_name"
          id="classroom_name"
          value={classroom.classroom_name}
          placeholder="Classroom name"
          className="schedule-form-classroom-name"
          onChange={(e) => handleClassroomForm(e)}
        />
        <input
          ref={capacityRef}
          name="classroom_capacity"
          id="classroom_capacity"
          title="Class capacity"
          value={classroom.classroom_capacity}
          placeholder="Capacity"
          className="calendar-duration"
          onChange={(e) => handleClassroomForm(e)}
        />
        <button className="schedule-form-button" onClick={addClassroom}>
          Add classroom
        </button>
      </div>
      <div className="classroom-div">
        <table className="classroom-table">
          <thead>
            <tr>
              <th>Centre</th>
              <th>Classroom</th>
              <th>Capacity</th>
              <th>x</th>
            </tr>
          </thead>
          <tbody>{classroomList}</tbody>
        </table>
      </div>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </div>
  );
}
