import { useEffect, useState } from "react";
import Select from "react-select";
import fetchData from "./scripts/fetchData.js";

import "./style/selectors.css";

export default function CentreSelector({
  centre,
  classObj,
  handleClassObj,
  isVirtual,
  isLoaded,
}) {
  const [classroomOptions, setClassroomOptions] = useState([]);

  const getClassrooms = async () => {
    if (centre) {
      const centre_code = centre?.centre_code;
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/classrooms/${centre_code}`;
      let response = await fetchData(url, "GET");
      if (response) {
        let orderedClassrooms = orderClassrooms(response);
        const classroomList = orderedClassrooms.map((option) => {
          return {
            value: option.classroom_name,
            label:
              option.classroom_name + " (" + option.classroom_capacity + ")",
            capacity: option.classroom_capacity,
          };
        });
        setClassroomOptions(classroomList);
      }
    }
  };

  function orderClassrooms(list) {
    let orderedClassrooms = /\d/.test(list[0]?.classroom_name)
      ? [...list]?.sort((a, b) =>
          Number(a.classroom_name.replace(/[^0-9]/g, "")) >
          Number(b.classroom_name.replace(/[^0-9]/g, ""))
            ? 1
            : -1
        )
      : [...list]?.sort((a, b) =>
          a.classroom_name > b.classroom_name ? 1 : -1
        );
    return orderedClassrooms;
  }

  function handleClassroomCapacity() {
    if (classObj.classroom?.value) {
      handleClassObj(
        {
          value: classObj.classroom.capacity,
          label: classObj.classroom.capacity.toString(),
        },
        "capacity"
      );
    } else {
      handleClassObj("", "capacity");
    }
  }

  useEffect(() => {
    getClassrooms();
    handleClassroomCapacity();
  }, [centre, classObj.classroom]);

  return (
    <>
      <Select
        className="schedule-form-classroom-selector"
        value={classObj.classroom}
        placeholder={!isVirtual ? "Room" : "Zoom"}
        options={classroomOptions}
        isDisabled={isVirtual || !isLoaded}
        isClearable
        onChange={(data) => handleClassObj(data, { name: "classroom" })}
      />
      <input
        name="capacity"
        id="capacity"
        placeholder="Cap."
        disabled={!isLoaded}
        className="schedule-form-duration"
        value={classObj.capacity?.value || ""}
        onChange={(e) =>
          handleClassObj(
            { value: e.target.value, label: e.target.value },
            "capacity"
          )
        }
      />
    </>
  );
}
