export default function NewModalCloseButton({ setShowNewModal }) {
  return (
    <button
      className="schedule-form-button"
      onClick={() => setShowNewModal(false)}
    >
      Close
    </button>
  );
}
