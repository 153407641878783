import { useEffect, useState } from "react";
import NewModal from "./NewModal";
import LessonHistory from "./LessonHistory";
import SessionForm from "./SessionForm.js";
import dayjs from "dayjs";
import arrToStr from "./scripts/arrayToString.js";
import closed_icon from "./images/closed_icon.svg";
import open_icon from "./images/open_icon.svg";
import history_icon from "./images/history_icon.png";
import obsClassLink from "./scripts/obsClassLink.js";
import fetchData from "./scripts/fetchData.js";
import session_icon from "./images/session_icon.png";
import session_icon_off from "./images/session_icon_off.png";

export default function ScheduleView({
  centre,
  scheduleId,
  isLoaded,
  loadSchedule,
  mergeSchedule,
  closeModal,
}) {
  const [schedule, setSchedule] = useState();
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);

  const getSchedule = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
    const response = await fetchData(url, "GET");
    const sortedClassList = [...response?.schedule_classlist].sort((a, b) =>
      a.dateStr > b.dateStr ? 1 : -1
    );
    response.schedule_classlist = sortedClassList;
    setSchedule(response);
  };

  const showLessonHistory = async (
    lessonId,
    lessonTitle,
    classType,
    teacherName
  ) => {
    setNewModalContent(
      <LessonHistory
        centre={centre}
        lessonId={lessonId}
        lessonTitle={lessonTitle}
        classType={classType}
        teacherName={teacherName}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  };

  const showSessionDetails = async (activityDetails) => {
    let activityList = [];
    activityList.push(activityDetails);
    setNewModalContent(
      <SessionForm
        activityList={activityList}
        setShowNewModal={setShowNewModal}
        mode="view"
      />
    );
    setShowNewModal(true);
  };

  useEffect(() => {
    getSchedule();
  }, []);

  return (
    <div className="schedule-view-div">
      <h2>{schedule?.schedule_name}</h2>
      <div className="schedule-container-div">
        <div className="schedule-container-left-div">
          <div className="schedule-container-info-div">
            Last updated:{" "}
            {dayjs(schedule?.updatedAt).format("DD/MM/YYYY HH:mm")}
          </div>
          <div className="schedule-container-info-div">
            Updated by: {schedule?.schedule_author}
          </div>
        </div>
        <div className="schedule-container-right-div">
          Classes: {schedule?.schedule_classlist.length}
        </div>
      </div>
      <table className="schedule-table">
        <thead>
          <tr>
            <th></th>
            <th>Centre</th>
            <th>Date</th>
            <th>Timeslot</th>
            <th>Day</th>
            <th>Dur.</th>
            <th>Teacher</th>
            <th className={centre?.is_virtual ? "hidden-column" : undefined}>
              Room
            </th>
            <th>Cap.</th>
            <th>Mod.</th>
            <th>Pth.</th>
            <th>Theme</th>
            <th>Lesson</th>
            <th title="History">H</th>
            <th title="Session details">S</th>
            <th>Level(s)</th>
            <th>Product(s)</th>
            <th className={!centre?.is_virtual ? "hidden-column" : undefined}>
              Countries
            </th>
            <th>Cr.</th>
            <th>
              <i
                className="fa fa-lg fa-lock"
                aria-hidden="true"
                size="lg"
                title="Closed"
              ></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {schedule &&
            schedule.schedule_classlist.map((item) => (
              <tr key={item.temp_id}>
                <td
                  className={`schedule-table-row-selector ${
                    item.class_id && "published-class"
                  }`}
                >
                  {" "}
                </td>
                <td className="class-id-cell">
                  <span
                    className={item.class_id ? "obs-link" : undefined}
                    title={item.class_id ? "Open CMS2 class page" : "Centre"}
                    onClick={() => obsClassLink(item.class_id, centre?.region)}
                  >
                    {item.class_id ? item.class_id : item.centre.centre_code}
                  </span>
                </td>
                <td
                  className={`${
                    item.dateStr < dayjs(new Date()).format() && "past-date"
                  } schedule-table-centred-column`}
                >
                  {dayjs(item.dateStr).format("DD/MM/YYYY")}
                </td>
                <td className="schedule-table-centred-column">
                  {dayjs(item.dateStr).format("HH:mm")}
                </td>
                <td className="schedule-table-centred-column">
                  {dayjs(item.dateStr).format("ddd")}
                </td>
                <td className="schedule-table-centred-column">
                  {item.duration.value}
                </td>
                <td>{item.teacher && item.teacher.label}</td>
                <td
                  className={centre?.is_virtual ? "hidden-column" : undefined}
                >
                  {item.classroom && item.classroom.value.split(":")[0]}
                </td>
                <td className="numeric-column">{item.capacity.value}</td>
                <td>{item.module && item.module.label}</td>
                <td className="schedule-table-centred-column schedule-table-content">
                  {item.lesson?.type === "activity" ? (
                    <span title={item.lesson.category && item.lesson.category}>
                      {item.lesson.category && item.lesson.category.charAt(0)}
                    </span>
                  ) : (
                    <span title={item.pathway && item.pathway.label}>
                      {item.pathway && item.pathway.label.charAt(0)}
                    </span>
                  )}
                </td>
                <td className="schedule-table-content">
                  <span title={item.theme?.label}>{item.theme?.value}</span>
                </td>
                <td className="schedule-table-content">
                  {item.lesson && item.lesson.label}
                </td>
                <td className="schedule-table-content">
                  <img
                    src={history_icon}
                    alt="Lesson history"
                    className="schedule-table-history-icon"
                    onClick={() =>
                      showLessonHistory(
                        item.lesson.value,
                        item.lesson.label,
                        item.lesson.type,
                        item.teacher?.label
                      )
                    }
                    //lessonId, lessonTitle, classType
                  />
                </td>
                <td className="schedule-table-content schedule-table-centred-column">
                  {item.lesson && item.lesson?.type === "activity" && (
                    <img
                      src={
                        item.lesson?.session_name
                          ? session_icon
                          : session_icon_off
                      }
                      alt="Session details icon"
                      title="Session details"
                      className="schedule-table-session-icon"
                      onClick={() => showSessionDetails(item)}
                    />
                  )}
                </td>
                <td className="schedule-table-content">
                  {item.levels.length > 0 && arrToStr(item.levels)}
                </td>
                <td className="schedule-table-content">
                  {item.products.length > 0 && arrToStr(item.products)}
                </td>
                <td
                  className={!centre?.is_virtual ? "hidden-column" : undefined}
                >
                  {item.virtualCentres.length > 0 &&
                    arrToStr(item.virtualCentres)}
                </td>
                <td className="schedule-table-content schedule-table-centred-column">
                  {item.credit.value}
                </td>
                <td className="schedule-table-centred-column schedule-table-image-cell">
                  {item.isClosed.value ? (
                    <img
                      src={closed_icon}
                      alt="Closed"
                      title="Closed"
                      className="schedule-table-class-type-icon"
                    />
                  ) : (
                    <img
                      src={open_icon}
                      alt="Open"
                      title="Open"
                      className="schedule-table-class-type-icon"
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="close-modal-button-div">
        {!isLoaded && !schedule?.is_archive && (
          <button
            className="schedule-form-button"
            onClick={() => loadSchedule(scheduleId).then(closeModal)}
          >
            Load
          </button>
        )}
        {isLoaded && !schedule?.is_archive && (
          <button
            className="schedule-form-button"
            onClick={(event) => mergeSchedule(schedule._id, null, true)}
          >
            Merge
          </button>
        )}
        <button className="schedule-form-button" onClick={closeModal}>
          Close
        </button>
      </div>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </div>
  );
}
