import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import fetchData from "../components/schedule/scripts/fetchData.js";

export default function CreateAdminAccount() {
  const [countries, setCountries] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [centreOptions, setCentreOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCentre, setSelectedCentre] = useState();
  const [countryAccessList, setCountryAccessList] = useState([]);
  const adminAccount = {
    username: "",
    email: "",
    admin_id: "",
    centre_id: "",
    country_access: [],
  };
  const [message, setMessage] = useState();
  const [adminAccountData, setAdminAccountData] = useState(adminAccount);
  const navigate = useNavigate();

  const getCountries = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/country-list`;
    const response = await fetchData(url, "GET");
    setCountries(response);
    let countryList = response.map((option) => {
      return {
        value: option.country_id,
        label: option.country_name,
      };
    });
    setCountryOptions(countryList);
  };

  const updateCentres = async (country) => {
    setSelectedCountry(country);
    const country_id = country.value;
    let centreList = countries.find((item) => {
      return item.country_id === country_id;
    });
    setCentreOptions(
      centreList.centres?.map((option) => {
        return {
          value: option.centre_id,
          label: option.centre_name + " (" + option.centre_code + ")",
          centre_code: option.centre_code,
          is_virtual: option.is_virtual,
        };
      })
    );
  };

  function handleSelectedCentre(data) {
    setSelectedCentre(data);
    setAdminAccountData({
      ...adminAccountData,
      centre_id: data.value,
    });
  }

  function handleChange(e) {
    setAdminAccountData({
      ...adminAccountData,
      [e.target.name]: e.target.value,
    });
  }
  const clearForm = () => {
    setAdminAccountData(adminAccount);
  };

  function handleCountryAccess(e) {
    let country_id = Number(e.target.value);
    if (e.target.checked) {
      !countryAccessList.includes(e.target.value) &&
        setCountryAccessList((currentList) => [...currentList, country_id]);
    } else {
      setCountryAccessList((currentList) =>
        currentList.filter((item) => {
          return country_id !== item;
        })
      );
    }
  }
  console.log(adminAccountData);

  const handleCreateAdminAccount = async (e) => {
    console.log(adminAccountData);
    e.preventDefault();
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/admin`;
    const response = await fetchData(url, "POST", adminAccountData);
    setMessage(
      "New admin account created with ID " +
        response +
        ". Account details sent."
    );
    clearForm();
  };

  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    setAdminAccountData({
      ...adminAccountData,
      country_access: countryAccessList,
    });
  }, [countryAccessList]);

  const countryAccessTable = countries.map((country) => {
    return (
      <div key={country._id}>
        <label htmlFor={country.country_id} className="filter-checkbox-label">
          <input
            type="checkbox"
            id={country.country_id}
            className="filter-checkbox"
            // checked={countryAccessList.includes(country.country_id)}
            value={country.country_id}
            onChange={(e) => handleCountryAccess(e)}
          />
          {country.country_name}
        </label>
      </div>
    );
  });

  return (
    <div className="account-form-wrapper">
      <div className="account-form-detail">
        <h2>New admin account</h2>
        <form id="newAdminAccountForm" onSubmit={handleCreateAdminAccount}>
          <div className="account-form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              className="account-form-control"
              required
              onChange={(e) => handleChange(e)}
              value={adminAccountData.username}
            />
          </div>
          <div className="account-form-group">
            <label htmlFor="username">Email</label>
            <input
              type="text"
              name="email"
              id="email"
              className="account-form-control"
              required
              onChange={(e) => handleChange(e)}
              value={adminAccountData.email}
            />
          </div>
          <div className="account-form-group">
            <label htmlFor="username">OBS admin ID</label>
            <input
              type="text"
              name="admin_id"
              id="admin_id"
              className="account-form-control"
              required
              onChange={(e) => handleChange(e)}
              value={adminAccountData.admin_id}
            />
          </div>
          <div className="account-form-group">
            <label htmlFor="country"> Country</label>
            <Select
              className="account-form-country-selector account-form-control"
              value={selectedCountry}
              placeholder="Country"
              cacheOptions
              isSearchable
              options={countryOptions}
              onChange={(data) => updateCentres(data)}
            />
          </div>
          <div className="account-form-group">
            <label htmlFor="centre_id">Centre</label>
            <Select
              className="account-form-centre-selector account-form-control"
              value={selectedCentre}
              // defaultOptions
              placeholder="Centre"
              options={centreOptions}
              onChange={(data) => handleSelectedCentre(data)}
            />
          </div>
          <div id="account-form-group">
            <button
              id="registerBtn"
              className="schedule-form-button"
              onClick={(e) => handleCreateAdminAccount(e)}
            >
              Create account
            </button>
          </div>
          <div className="account-message-div">{message}</div>
        </form>
      </div>
      <div className="account-form-country-list">
        <h3>Country access</h3>
        <div>{countryAccessTable}</div>
      </div>
    </div>
  );
}
