export default function DeleteConfirmation({
  deleteMessage,
  deleteFunction,
  setShowNewModal,
}) {
  return (
    <div>
      <div className="delete-message-div"> {deleteMessage}</div>
      <div className="delete-button-div">
        <button
          className="schedule-form-button delete-button"
          onClick={deleteFunction}
        >
          Delete
        </button>
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
