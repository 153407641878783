import { useEffect, useState } from "react";
import Select from "react-select";
import "./style/selectors.css";
import setCountryLevelCache from "./scripts/setCountryLevelCache.js";

export default function ContentLevelSelector({
  centre,
  classObj,
  handleClassObj,
  setIsSelecting,
  isLoaded,
}) {
  const [contentLevelOptions, setContentLevelOptions] = useState([]);
  const placeholder = "Level..";

  const getContentLevelOptions = async () => {
    setContentLevelOptions([]);
    let levels = [];
    if (centre) {
      levels = JSON.parse(localStorage.getItem("levels"));
      if (!levels) {
        levels = await setCountryLevelCache(centre.centre_id);
      }
      const contentLevelList = levels.map((option) => {
        return {
          value: option.level_id,
          label: option.level_code,
        };
      });
      setContentLevelOptions(contentLevelList);
    }
  };

  useEffect(() => {
    getContentLevelOptions();
  }, [centre, classObj.contentType]);

  return (
    <Select
      className="schedule-form-level-code-selector"
      value={classObj.contentLevel}
      placeholder={placeholder}
      isDisabled={!isLoaded}
      options={contentLevelOptions}
      onFocus={() => setIsSelecting(false)}
      onChange={(data) => handleClassObj(data, { name: "contentLevel" })}
    />
  );
}
