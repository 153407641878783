import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useUserSettings } from "../../auth/useUserSettings";
import { createTimeSlots } from "./scripts/createTimeSlots";
import "./style/selectors.css";

export default function Timeslot({
  classObj,
  timeslotRef,
  handleClassObj,
  isLoaded,
}) {
  const [timeslotOptions, setTimeslotOptions] = useState([]);
  const [userSettings] = useUserSettings();

  useEffect(() => {
    const { user_settings } = userSettings;
    const slots = createTimeSlots(
      user_settings?.first_timeslot,
      user_settings?.last_timeslot,
      user_settings?.timeslot_interval || 5
    );
    setTimeslotOptions(slots);
  }, [userSettings]);

  return (
    <Select
      ref={timeslotRef}
      className="schedule-form-timeslot-selector"
      value={classObj.timeslot}
      placeholder="Time"
      cacheOptions
      isDisabled={!isLoaded}
      isSearchable
      isClearable
      options={timeslotOptions}
      onChange={(data) => handleClassObj(data, { name: "timeslot" })}
    />
  );
}
