import React, { useEffect, useState, useRef } from "react";

export default function VerifyOtp({
  user,
  loginUser,
  setRequireOtp,
  otpToken,
}) {
  const [otp, setOtp] = useState("");
  const [message, setMessage] = useState();
  const otpRef = useRef();

  async function checkOtp() {
    if (otp?.length === 6) {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/verify-otp`;
      let otpObj = { username: user.username, token: otp };
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${otpToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(otpObj),
      }).then((data) => data.json());
      if (response.verified === false) {
        setMessage("Incorrect number.");
      } else if (response.verified === true) {
        loginUser(user, otpToken);
      } else {
        setMessage("Something went wrong. Please try again.");
      }
    } else {
      setMessage("");
    }
  }

  useEffect(() => {
    checkOtp();
  }, [otp]);

  useEffect(() => {
    otpRef.current.focus();
  }, []);

  return (
    <div className="otp-div">
      <h3>Verify identity</h3>
      <div>Please enter the 6-digit number from your authentication app.</div>
      <div className="centered-column">
        <div className="otp-input-div">
          <input
            ref={otpRef}
            type="text"
            name="otp"
            id="otp"
            value={otp}
            placeholder="Enter 6-digit number"
            maxLength="6"
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
        {message && <div className="otp-message-div">{message}</div>}
        <div className="otp-message-div">
          <span className="link" onClick={() => setRequireOtp(false)}>
            Back to login
          </span>
        </div>
      </div>
    </div>
  );
}
