import { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import fetchData from "./scripts/fetchData.js";
import "./style/selectors.css";

export default function VirtualCentreSelector({
  centre,
  classObj,
  handleClassObj,
  isLoaded,
}) {
  const [virtualCentreOptions, setVirtualCentreOptions] = useState([]);
  const animatedComponents = makeAnimated();

  const sortArrayObjects = (arrayObjects) => {
    let newArrayObject = [];
    if (arrayObjects.length > 0) {
      newArrayObject = arrayObjects.sort((arrayObject1, arrayObject2) => {
        return arrayObject1.value - arrayObject2.value;
      });
    }
    return newArrayObject;
  };

  const getVirtualCentreOptions = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/virtual-centres`;
    const response = await fetchData(url, "GET");
    const optionsList = response.map((option) => {
      return {
        value: option.centre_id,
        // label: option.country_name + " (" + option.centre_code + ")",
        label: option.centre_code,
        country_name: option.country_name,
      };
    });
    setVirtualCentreOptions(optionsList);
  };
  useEffect(() => {
    getVirtualCentreOptions();
  }, []);

  return (
    <Select
      className="schedule-form-virtual-centre-selector"
      isMulti
      value={sortArrayObjects(classObj.virtualCentres)}
      placeholder="Countries"
      cacheOptions
      isDisabled={!isLoaded}
      options={virtualCentreOptions}
      components={animatedComponents}
      onChange={(data) => handleClassObj(data, { name: "virtualCentres" })}
    />
  );
}
