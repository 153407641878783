export default function getLessonsFromCacheByTheme(theme_code) {
  let levels = JSON.parse(localStorage.getItem("levels"));
  let lessons = [];
  for (const level of levels) {
    let levelId = level.level_id;
    let levelLessons = JSON.parse(localStorage.getItem("level_" + levelId));
    let themeLessons = levelLessons.filter((obj) => {
      return obj.theme_code === theme_code;
    });
    lessons = lessons.concat(themeLessons);
  }
  return lessons;
}
