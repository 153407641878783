import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import Content from "./Content";
import LessonHistory from "./LessonHistory.js";
import NewModal from "./NewModal";
import DeleteConfirmation from "./DeleteConfirmation.js";
import { useUserSettings } from "../../auth/useUserSettings";
import dayjs from "dayjs";
import delete_icon from "./images/delete_icon.svg";
import history_icon from "./images/history_icon.png";
import getLessonFromCache from "./scripts/getLessonFromCache.js";
import checkHistoryCache from "./scripts/checkHistoryCache.js";
import addHistoryData from "./scripts/addHistoryData.js";
import fetchData from "./scripts/fetchData.js";

export default function Strand() {
  const centre = JSON.parse(localStorage.getItem("centre"));
  const [strand, setStrand] = useState();
  const [lessons, setLessons] = useState([]);
  const [lessonCount, setLessonCount] = useState();
  const [newModalContent, setNewModalContent] = useState();
  const [showNewModal, setShowNewModal] = useState(false);
  const [excludedLessons, setExcludedLessons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dragRow, setDragRow] = useState();
  const [userSettings] = useUserSettings();
  const { strandId } = useParams();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const selectMode = true;

  const dragStart = (e) => {
    dragItem.current = e.target.id;
    setDragRow(e.target.id);
  };
  const dragEnter = (e) => {
    dragOverItem.current = e.currentTarget.id;
  };

  const drop = () => {
    const lessonList = [...lessons];
    const dragItemContent = lessonList[dragItem.current];
    lessonList.splice(dragItem.current, 1);
    lessonList.splice(dragOverItem.current, 0, dragItemContent);
    setDragRow(dragOverItem.current);
    dragItem.current = null;
    dragOverItem.current = null;
    setLessons(lessonList);
    updateStrand(lessonList);
  };

  const getStrand = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands/strand/${strandId}`;
    const response = await fetchData(url, "GET");
    setLessonCount(response.lessons.length);
    setStrand(response);
    getLessons(response.lessons);
  };

  const getLessons = async (lessons) => {
    let updatedLessons = [];
    for (const lesson of lessons) {
      let nextLesson = getLessonFromCache(lesson);
      updatedLessons.push(nextLesson);
    }
    updatedLessons = await getAllLessonHistories(updatedLessons);
    setLessons(updatedLessons);
  };

  const getAllLessonHistories = async (targetLessons) => {
    let lessonHistories = await checkHistoryCache(
      targetLessons,
      centre.country_id,
      userSettings.user_settings.history_since.toString()
    );
    if (lessonHistories) {
      let newLessons = addHistoryData(targetLessons, lessonHistories);
      return newLessons;
    }
  };

  const addLessonsToStrand = async (newLessons) => {
    let updatedLessons = [];
    for (const lesson of newLessons) {
      let nextLesson = getLessonFromCache(lesson);
      updatedLessons.push(nextLesson);
    }
    let lessonsToAdd = await getAllLessonHistories(updatedLessons);
    // let mergedLessons = [...tempLessons, ...lessonsToMerge];
    setLessons(lessonsToAdd);
    updateStrand(lessonsToAdd);
  };

  function closeContentModal() {
    setShowNewModal(false);
  }

  const updateStrand = async (updatedLessons) => {
    let lessonList = [];
    if (updatedLessons) {
      lessonList = updatedLessons.map((lesson) => {
        return lesson.lesson_id;
      });
    }
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands/${strandId}`;
    await fetchData(url, "PUT", lessonList);
  };

  const removeLessonFromStrand = async (lessonId) => {
    let newLessonList = lessons.filter((lesson) => {
      return lesson.lesson_id !== lessonId;
    });
    setDragRow();
    setLessons(newLessonList);
    updateStrand(newLessonList);
  };

  function confirmRemoveAllLessons() {
    setNewModalContent(
      <DeleteConfirmation
        deleteMessage={`Are you sure you want to remove all lessons?`}
        deleteFunction={removeAllStrandLessons}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  }

  async function removeAllStrandLessons(e) {
    e.preventDefault();
    setLessons([]);
    updateStrand();
    setShowNewModal(false);
  }

  function showSyllabus() {
    const strandLessons = lessons?.map((lesson) => lesson.lesson_id);
    setNewModalContent(
      <Content
        centre={centre}
        strandId={strand._id}
        addLessonsToStrand={addLessonsToStrand}
        lessonCount={lessonCount}
        setLessonCount={setLessonCount}
        selectMode={selectMode}
        strandLessons={strandLessons}
        closeContentModal={closeContentModal}
      />
    );
    setShowNewModal(true);
  }

  // function handleExcludedLessons(e, lesson_id) {
  //   const lesson = Number(lesson_id);
  //   if (!e.target.checked) {
  //     !excludedLessons.includes(lesson) &&
  //       setExcludedLessons((currentList) => [...currentList, lesson]);
  //   } else {
  //     setExcludedLessons((currentList) =>
  //       currentList.filter((item) => {
  //         return lesson !== item;
  //       })
  //     );
  //   }
  // }

  useEffect(() => {
    getStrand();
  }, [lessonCount]);

  const showLessonHistory = async (lessonId, lessonTitle, classType) => {
    setNewModalContent(
      <LessonHistory
        centre={centre}
        lessonId={lessonId}
        lessonTitle={lessonTitle}
        classType={classType}
        setShowNewModal={setShowNewModal}
      />
    );
    setShowNewModal(true);
  };

  const lessonList = lessons?.map((lesson, index) => {
    return (
      <tr
        key={index}
        id={index}
        onDragStart={(e) => dragStart(e)}
        onDragEnter={(e) => dragEnter(e)}
        onDragEnd={drop}
        draggable={
          strand.strand_type !== 0
            ? true
            : userSettings.roles.includes(5)
            ? true
            : false
        }
        className={index === Number(dragRow) ? "drag-row" : undefined}
      >
        <td className="centered-column">{index + 1}</td>
        <td>{lesson.content}</td>
        <td>{lesson.pathway}</td>
        <td className="centered-column">
          {lesson.theme_code && lesson.theme_code}
        </td>
        <td>{lesson.theme_name}</td>
        <td className="centered-column">{lesson.lesson_code}</td>
        <td>{lesson.lesson_title}</td>
        <td>
          {lesson.last_class
            ? `${dayjs(lesson.last_class).format("DD/MM/YYYY HH:mm")} (${
                lesson.past_class_count
              })`
            : isLoading
            ? "Loading ..."
            : null}
        </td>
        <td>
          {lesson.next_class
            ? `${dayjs(lesson.next_class).format("DD/MM/YYYY HH:mm")} (${
                lesson.future_class_count
              })`
            : isLoading
            ? "Loading ..."
            : null}
        </td>
        <td className="centered-column">
          <img
            src={history_icon}
            alt="Lesson history"
            title="Show lesson history"
            className="schedule-table-history-icon"
            onClick={() =>
              showLessonHistory(lesson.lesson_id, lesson.lesson_title, "lesson")
            }
          />
        </td>
        <td className="centered-column">
          <img
            src={delete_icon}
            className="delete-icon"
            title="Remove lesson from lesson group"
            alt="Delete lesson"
            onClick={() => removeLessonFromStrand(lesson.lesson_id)}
          />
        </td>
      </tr>
    );
  });

  return (
    <>
      {/* <button onClick={() => navigate(-1)}>Back</button> */}
      <h2>
        Lesson group: {strand?.strand_code}: {strand?.strand_name}
      </h2>
      <hr />
      <div className="schedule-container-div">
        <div className="schedule-container-info-div">
          Level: {strand?.level}
        </div>
        <div className="schedule-container-info-div">
          Lessons: {lessons?.length}
        </div>
        <div className="schedule-container-info-div">
          Updated: {dayjs(strand?.updatedAt).format("DD/MM/YYYY HH:mm")}
        </div>
        <div className="schedule-container-right-div">
          <button className="schedule-form-button" onClick={showSyllabus}>
            Add lessons
          </button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Type</th>
            <th>Pathway</th>
            <th>Theme code</th>
            <th>Theme name</th>
            <th>Lesson code</th>
            <th>Lesson title</th>
            <th>Last class</th>
            <th>Next class</th>
            <th title="Lesson history">H</th>
            <th>
              <span
                className="delete-all-icon"
                title="Delete selected/all classes"
                onClick={() => confirmRemoveAllLessons()}
              >
                x
              </span>
            </th>
          </tr>
        </thead>
        <tbody>{lessonList}</tbody>
      </table>
      <NewModal
        open={showNewModal}
        content={newModalContent}
        setShowNewModal={setShowNewModal}
        showCloseButton={true}
      />
    </>
  );
}
