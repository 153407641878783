export default function CreditSelector({ classObj, handleClassObj, isLoaded }) {
  return (
    <input
      name="credit"
      id="credit"
      title="Credit"
      placeholder="Cr."
      className="schedule-form-duration"
      value={classObj?.credit?.value || ""}
      onChange={(e) =>
        handleClassObj(
          { value: e.target.value, label: e.target.value },
          "credit"
        )
      }
      disabled={!isLoaded}
    />
  );
}
