export function createTimeSlots(rangeStart, rangeEnd, interval) {
  const timeslotList = [];
  for (let i = rangeStart; i <= rangeEnd; i += interval) {
    const minutes = i % 60;
    const hours = Math.floor(i / 60);
    const timeslot = {
      value: i,
      label: `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`,
    };
    timeslotList.push(timeslot);
  }
  return timeslotList;
}
