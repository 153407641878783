import { useEffect, useState } from "react";
import Select from "react-select";
import { useUserSettings } from "../../auth/useUserSettings";
import fetchData from "./scripts/fetchData.js";
import encrypt from "./scripts/encrypt.js";
import setTeacherCache from "./scripts/setTeacherCache.js";

import "./style/selectors.css";

export default function TeacherSelector({
  centre,
  classObj,
  handleClassObj,
  isLoaded,
}) {
  const [teacherOptions, setTeacherOptions] = useState([]);
  const [userSettings] = useUserSettings();

  const getTeachers = async () => {
    classObj.teacher = "";
    if (!centre) return;
    const { value: centreId, country_id, is_virtual } = centre;
    let teachers = await fetchTeachers(country_id, is_virtual);
    if (teachers.length > 0) {
      const filteredTeachers = filterTeachers(
        teachers,
        centreId,
        userSettings.user_settings.teacher_search,
        is_virtual
      );
      const sortedTeachers = sortTeachers(filteredTeachers);
      createTeacherOptions(sortedTeachers, is_virtual);
    }
  };

  const fetchTeachers = async (country_id, is_virtual) => {
    if (is_virtual) {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/virtual-teachers/${country_id}`;
      const response = await fetchData(url, "GET");
      return response?.data || [];
    } else {
      let encryptedTeachers = localStorage.getItem("teachers");
      if (!encryptedTeachers) {
        encryptedTeachers = await setTeacherCache(country_id);
      }
      return encryptedTeachers
        ? JSON.parse(encrypt("decrypt", encryptedTeachers))
        : [];
    }
  };

  const filterTeachers = (teachers, centreId, userOption, isVirtual) => {
    return userOption === 0 && !isVirtual
      ? teachers.filter((teacher) => teacher.centre_id === centreId)
      : teachers;
  };

  const sortTeachers = (teachers) => {
    return teachers.sort((a, b) => a.first_name.localeCompare(b.first_name));
  };

  function createTeacherOptions(teachers, virtual) {
    setTeacherOptions(
      teachers.map((option) => {
        return {
          value: option.teacher_id,
          label:
            virtual === true
              ? option.first_name +
                " " +
                option.last_name +
                " (" +
                option.country_code +
                ")"
              : option.first_name + " " + option.last_name,
        };
      })
    );
  }

  useEffect(() => {
    getTeachers();
  }, [centre]);

  return (
    <Select
      className="schedule-form-teacher-selector"
      value={classObj.teacher}
      placeholder="Teacher"
      cacheOptions
      isDisabled={!isLoaded}
      isSearchable
      isClearable
      options={teacherOptions}
      onChange={(data) => handleClassObj(data, { name: "teacher" })}
    />
  );
}
