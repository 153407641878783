import React from "react";

const MODAL_STYLE = {
  position: "absolute",
  backgroundColor: "#FFF",
  padding: "15px 35px",
  margin: "30px",
  zIndex: "1000",
  minWidth: "15%",
  borderRadius: ".5em",
};
const OVERLAY_STYLE = {
  position: "fixed",
  display: "flex",
  justifyContent: "center",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0,0,0, .4)",
  zIndex: "1000",
  overflowY: "auto",
};

const NewModal = ({ open, content, setShowNewModal }) => {
  if (!open) return null;
  return (
    <>
      <div style={OVERLAY_STYLE} onClick={() => setShowNewModal(false)}>
        <div
          style={MODAL_STYLE}
          className="modal-div"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="modal-content-div">{content}</div>
        </div>
      </div>
    </>
  );
};

export default NewModal;
