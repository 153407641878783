export default function DayPickerComponent({
  weekday,
  isEnabled,
  setWeekdayArray,
}) {
  const days = [
    [1, "M"],
    [2, "T"],
    [3, "W"],
    [4, "T"],
    [5, "F"],
    [6, "S"],
    [0, "S"],
  ];
  const showDays = days.map((day) => {
    return (
      <div key={day[0]}>
        <input
          key={day[0]}
          type="checkbox"
          id={"D" + day[0]}
          value={weekday.includes(day[0])}
          checked={weekday.includes(day[0])}
          disabled={!isEnabled}
          className="weekday-selector"
          onChange={(e) => setWeekdayArray(e.target.checked, day[0], true)}
        />
        <label htmlFor={"D" + day[0]}>{day[1]}</label>
      </div>
    );
  });

  return (
    <div className="weekday-selector">
      {showDays}
      <span
        title="Reset dates"
        onClick={(e) => setWeekdayArray(null, null, false)}
        className="weekday-selector"
      >
        R
      </span>
    </div>
  );
}
