import { useEffect, useState, useRef } from "react";
import Select from "react-select";
import "./style/selectors.css";
import setSyllabusLevelCache from "./scripts/setSyllabusLevelCache.js";
import setActivityCache from "./scripts/setActivityCache.js";
import getLessonFromCache from "./scripts/getLessonFromCache.js";
import getLessonsFromCacheByTheme from "./scripts/getLessonsFromCacheByTheme.js";
import fetchData from "./scripts/fetchData.js";
import module_icon from "./images/module_icon.svg";

export default function LessonSelector({
  centre,
  classObj,
  handleClassObj,
  setLessonArray,
  isSelecting,
  setIsSelecting,
  isLoaded,
  showLessonConfig,
  selectedLessonIdList,
}) {
  const [lessons, setLessons] = useState([]);
  const [lessonOptions, setLessonOptions] = useState([]);
  const [placeholder, setPlaceholder] = useState("Lesson or activity..");
  const selectRef = useRef();

  function clearDependencies() {
    if (!isSelecting) {
      classObj.lesson = "";
      classObj.module = "";
    }
  }

  const getLessons = async (lessons) => {
    let updatedLessons = [];
    for (const lesson of lessons) {
      let nextLesson = getLessonFromCache(lesson);
      updatedLessons.push(nextLesson);
    }
    return updatedLessons;
  };

  const setLessonsByTheme = async () => {
    if (centre && classObj.contentLevel && classObj.theme) {
      let results = [];
      const levelId = classObj.contentLevel.value;
      const theme_code = classObj.theme?.value;
      results = JSON.parse(localStorage.getItem("level_" + levelId));
      if (!results) {
        results = await setSyllabusLevelCache(centre.centre_id, levelId);
      }
      const filteredLessons = results.filter((lesson) => {
        return lesson.theme_code === theme_code;
      });
      const orderedLessons = [...filteredLessons].sort((a, b) =>
        a.seq > b.seq ? 1 : -1
      );
      setLessons(orderedLessons);
      let allLessonsOption = {
        value: "0",
        label: "Configure lessons/module (" + orderedLessons?.length + ")",
        type: "lesson",
      };
      let lessonList = orderedLessons.map((option) => {
        return {
          value: option.lesson_id,
          label: option.lesson_code + ": " + option.lesson_title,
          type: "lesson",
        };
      });
      setLessonOptions([allLessonsOption].concat(lessonList));
    }
  };

  const updateLessonOptions = async () => {
    clearDependencies();
    if (classObj.contentType?.value === 1) {
      setPlaceholder("Choose lesson(s)...");
      setLessonsByTheme();
    } else if (classObj.contentType.value === 2) {
      setPlaceholder("Choose activity ...");
      let activities = [];
      const activityType = classObj.theme?.value;
      activities = JSON.parse(localStorage.getItem("activities"));
      if (!activities) {
        activities = await setActivityCache(centre.centre_id);
      }
      const activityArray = activities.filter((activity) => {
        return activity.activity_type === activityType;
      });
      setLessons(activityArray);
      const sortedActivities = [...activityArray].sort((a, b) =>
        a.activity_name > b.activity_name ? 1 : -1
      );
      setLessonOptions(
        sortedActivities.map((option) => {
          return {
            value: option.activity_id,
            label: option.activity_name,
            category: option.category,
            type: "activity",
          };
        })
      );
    } else if (classObj.contentType.value === 3) {
      setPlaceholder("Choose lesson ...");
      const strandId = classObj.strand?.value;
      if (strandId) {
        let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strand/lessons/${strandId}`;
        const response = await fetchData(url, "GET");
        let orderedLessons = await getLessons(response?.lessons);
        setLessons(orderedLessons);
        let allLessonsOption = {
          value: "0",
          label: "Configure lessons (" + orderedLessons?.length + ")",
          type: "lesson",
        };
        let lessonList = orderedLessons?.map((option) => {
          return {
            value: option.lesson_id,
            label: option.lesson_code + ": " + option.lesson_title,
            type: "lesson",
          };
        });
        setLessonOptions([allLessonsOption].concat(lessonList));
      }
    }
  };

  useEffect(() => {
    updateLessonOptions();
  }, [
    classObj.contentType,
    classObj.contentLevel,
    classObj.pathway,
    classObj.theme,
    classObj.strand,
  ]);
  useEffect(() => {
    handleLessonChange();
  }, [classObj.lesson, selectedLessonIdList.length]);

  //handle manual lesson change using select
  function handleLessonSelection(data, name) {
    if (data === null) {
      updateLessonOptions();
    } else {
      if (data?.type === "lesson") {
        if (Number(data?.value) !== 0) {
          let selectedLesson =
            lessons.length > 0
              ? lessons.filter((item) => {
                  return data.type === "lesson"
                    ? item.lesson_id === data.value
                    : item.activity_id === data.value;
                })
              : [getLessonFromCache(data.value)];
          setLessonArray(selectedLesson);
        } else {
          setLessonArray([...lessons]);
        }
      } else {
        let selectedLesson = lessons.filter((item) => {
          return item.activity_id === data.value;
        });
        setLessonArray(selectedLesson);
      }
    }
    handleClassObj(data, name);
  }

  // handle non-manual lesson change
  function handleLessonChange() {
    if (isSelecting) {
      if (selectedLessonIdList.length > 1) {
        if ([1, 3].includes(classObj.contentType?.value) && classObj.theme) {
          let lessonList = [];
          if (lessons.length > 0) {
            lessonList = lessons.filter((lesson) =>
              selectedLessonIdList.includes(lesson.lesson_id)
            );
          } else {
            lessonList = getLessonsFromCacheByTheme(classObj.theme?.value);
          }
          setLessonArray(lessonList);
          handleClassObj(lessonOptions[0], { name: "lesson" });
        }
      } else if (selectedLessonIdList.length === 1) {
        handleLessonSelection(classObj.lesson, { name: "lesson" });
      }
    }
  }

  return (
    <>
      <Select
        ref={selectRef}
        className="schedule-form-lesson-selector"
        value={classObj.lesson}
        placeholder={placeholder}
        cacheOptions
        isDisabled={!isLoaded}
        isSearchable
        isClearable
        options={lessonOptions}
        onFocus={() => setIsSelecting(false)}
        onChange={(data) => handleLessonSelection(data, { name: "lesson" })}
      />
      {classObj.lesson && classObj.contentType?.value !== 2 && (
        <div
          className={`config-div ${classObj.module ? "config-on" : undefined}`}
        >
          <img
            src={module_icon}
            alt="Config icon"
            className="config-icon"
            title="Configure lessons"
            onClick={() => showLessonConfig(lessons)}
          />
        </div>
      )}
    </>
  );
}
