import { useState } from "react";

export default function DeleteClass({
  deleteList,
  deleteClass,
  isVirtual,
  clearClassColumns,
  setShowNewModal,
}) {
  const [isDelete, setIsDelete] = useState(true);
  const [clearArray, setClearArray] = useState([]);

  function handleIsDelete() {
    setIsDelete(true);
    setClearArray([]);
  }

  function handleClearList(e) {
    setIsDelete(false);
    if (e.target.checked) {
      !clearArray.includes(e.target.name) &&
        setClearArray((currentList) => [...currentList, e.target.name]);
    } else {
      setClearArray((currentList) =>
        currentList.filter((item) => {
          return e.target.name !== item;
        })
      );
    }
  }

  const updateLesson = () => {
    if (!isDelete) {
      for (const item of deleteList) {
        for (const prop of clearArray) {
          Object.assign(item, { [prop]: "" });
          prop === "classroom" && Object.assign(item, { capacity: "" });
          prop === "lesson" &&
            Object.assign(item, {
              contentType: "",
              contentLevel: "",
              pathway: "",
              theme: "",
              strand: "",
              module: "",
            });
          clearClassColumns(item.temp_id, item);
        }
      }
    }
    setShowNewModal(false);
  };

  const deleteClasses = () => {
    if (isDelete) {
      for (const item of deleteList) {
        deleteClass(item.temp_id);
      }
    }
    setShowNewModal(false);
  };

  return (
    <div>
      <div className="delete-class-form">
        <div className="delete-class-form-div">
          <label className="module-radio-label">
            <input
              type="radio"
              name="action"
              checked={isDelete}
              className="filter-checkbox"
              onChange={handleIsDelete}
            />
            Delete {deleteList.length} selected class
            {deleteList.length > 1 && "es"}
          </label>
        </div>
        <div className="delete-class-form-div">
          <label className="module-radio-label">
            <input
              type="radio"
              name="action"
              checked={!isDelete}
              className="filter-checkbox"
              onChange={() => setIsDelete(false)}
            />
            Clear columns only (please select)
          </label>
        </div>
      </div>
      <div className="delete-class-columns">
        <div>
          <label htmlFor="teacher" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="teacher"
              name="teacher"
              checked={clearArray.includes("teacher")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Teacher
          </label>
          <label htmlFor="lesson" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="lesson"
              name="lesson"
              checked={clearArray.includes("lesson")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Lesson
          </label>
          <label htmlFor="classroom" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="classroom"
              name="classroom"
              disabled={isVirtual !== 0}
              checked={clearArray.includes("classroom")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Classroom
          </label>
          <label htmlFor="room_capacity" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="room_capacity"
              name="capacity"
              checked={clearArray.includes("capacity")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Capacity
          </label>
        </div>
        <div>
          <label htmlFor="levels" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="levels"
              name="levels"
              checked={clearArray.includes("levels")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Levels
          </label>
          <label htmlFor="products" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="products"
              name="products"
              checked={clearArray.includes("products")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Products
          </label>
          <label htmlFor="credits" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="credits"
              name="credit"
              checked={clearArray.includes("credit")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Credit
          </label>
          <label htmlFor="countries" className="filter-checkbox-label">
            <input
              type="checkbox"
              id="countries"
              name="countries"
              disabled={isVirtual === 0}
              checked={clearArray.includes("countries")}
              className="filter-checkbox"
              onChange={(e) => handleClearList(e)}
            />
            Countries
          </label>
        </div>
      </div>
      <div>
        {isDelete ? (
          <button
            className="schedule-form-button delete-button"
            onClick={deleteClasses}
          >
            Delete
          </button>
        ) : (
          <button className="schedule-form-button" onClick={updateLesson}>
            Clear
          </button>
        )}
        <button
          className="schedule-form-button"
          onClick={() => setShowNewModal(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
