import { useState, useRef } from "react";
import { useUserSettings } from "../../auth/useUserSettings";
import fetchData from "./scripts/fetchData.js";

export default function FeedbackForm({ setShowNewModal }) {
  const [message, setMessage] = useState();
  const [responseText, setResponseText] = useState();
  const [userSettings] = useUserSettings();
  const messageRef = useRef();

  async function sendMessage() {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/feedback`;
    let messageBody = {
      subject: "t2 feedback: " + userSettings.username,
      text: message,
    };
    const response = await fetchData(url, "POST", messageBody);
    setResponseText(response.message);
  }

  return (
    <>
      {!responseText ? (
        <div className="feedback-div">
          <h2>Feedback</h2>
          <p className="feedback-text-div">
            We welcome feedback on the new timetabling tool, especially during
            the Beta phase, so if you have a comment on usability, a request, or
            an idea for a new or improved feature, then please send it to us.
            All feedback will be considered as given in confidence.
          </p>
          <div>
            <textarea
              ref={messageRef}
              className="feedback-form-textarea"
              rows={10}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="feedback-button-div">
              <button
                className="schedule-form-button"
                disabled={!message}
                onClick={() => sendMessage()}
              >
                Send
              </button>
              <button
                className="schedule-form-button"
                onClick={() => setShowNewModal(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <p>{responseText}</p>
          <div>
            <button
              className="schedule-form-button"
              onClick={() => setShowNewModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
}
