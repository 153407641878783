import { useState } from "react";
import encrypt from "../components/schedule/scripts/encrypt";

export const useUserSettings = () => {
  const [userSettings, setUserSettingsInternal] = useState(() => {
    let encryptedSettings = localStorage.getItem("user_settings");
    if (!encryptedSettings) {
      return null;
    } else {
      return JSON.parse(encrypt("decrypt", encryptedSettings));
    }
  });

  const setUserSettings = (newSettings) => {
    let encryptedSettings = encrypt("encrypt", JSON.stringify(newSettings));
    localStorage.setItem("user_settings", encryptedSettings);
    setUserSettingsInternal(newSettings);
  };
  return [userSettings, setUserSettings];
};
