import NewModalCloseButton from "./NewModalCloseButton";

export default function InvalidLessonWarning({
  invalidLessonList,
  setShowNewModal,
}) {
  console.log(invalidLessonList);
  const invalidLessonCodes = invalidLessonList.map((code) => {
    return <div className="modal-clashlist-item centered-column">{code}</div>;
  });
  return (
    <div>
      <h3>Invalid lesson warning</h3>
      <p>The following lesson codes entered are not valid</p>
      <div className="modal-clashlist">{invalidLessonCodes}</div>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </div>
  );
}
