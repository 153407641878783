import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUser } from "./useUser";

export const PrivateRoute = () => {
  const user = useUser();
  const location = useLocation();
  // console.log(location);

  return user ? <Outlet /> : <Navigate to="/login" state={location.pathname} />;
};
