import dayjs from "dayjs";
import NewModalCloseButton from "./NewModalCloseButton";

export default function ClashMessage({ clashList, setShowNewModal }) {
  const clashMessage = clashList.map((clash, index) => {
    return (
      <div className="modal-clashlist-item" key={index}>
        {clash.type} clash on {dayjs(clash.dateStr).format("DD/MM/YYYY")} at{" "}
        {dayjs(clash.dateStr).format("HH:mm")}
      </div>
    );
  });
  return (
    <div className="centered-column">
      <h3>Whoops!</h3>
      <p>
        You have {clashList.length} clash{clashList.length > 1 && "es"}
      </p>
      <div id="modal-content" className="modal-clashlist">
        {clashMessage}
      </div>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </div>
  );
}
