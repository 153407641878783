import { useState, useRef } from "react";
import Select from "react-select";
import fetchData from "./scripts/fetchData.js";

export default function MigrateClass({
  centre,
  migrateList,
  saveSchedule,
  deleteClass,
  getSchedules,
  loadedScheduleId,
  setShowNewModal,
}) {
  const [isNewSchedule, setIsNewSchedule] = useState(true);
  const [scheduleName, setScheduleName] = useState();
  const [selectedSchedule, setSelectedSchedule] = useState();
  const [scheduleOptions, setScheduleOptions] = useState([]);
  const [runClashCheck, setRunClashCheck] = useState(true);
  const [isMoved, setIsMoved] = useState(false);
  const scheduleNameRef = useRef();
  const selectedScheduleRef = useRef();

  const getScheduleList = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule-list/${centre?.centre_code}`;
    const response = await fetchData(url, "GET");
    const filteredScheduleList = response.filter(
      (schedule) => schedule._id !== loadedScheduleId
    );
    // const sortedScheduleList = [...filteredScheduleList].sort((a, b) =>
    //   b.updatedAt > a.updatedAt ? 1 : -1
    // );
    const scheduleList = filteredScheduleList.map((schedule) => {
      return {
        value: schedule._id,
        label: schedule.is_archive
          ? "Archive: " + schedule.schedule_name
          : "Working: " + schedule.schedule_name,
      };
    });
    setScheduleOptions(scheduleList);
  };

  async function migrateClasses() {
    if (isNewSchedule) {
      if (!scheduleName) {
        scheduleNameRef.current.focus();
      } else {
        for (const item of migrateList) {
          deleteClass(item.temp_id);
        }
        await saveSchedule(scheduleName, migrateList, false);
        getSchedules();
      }
    } else if (!isNewSchedule) {
      if (!selectedSchedule) {
        selectedScheduleRef.current.focus();
      } else {
        for (const item of migrateList) {
          deleteClass(item.temp_id);
        }
        moveClassesToSchedule();
      }
    }
    setIsMoved(true);
  }

  const moveClassesToSchedule = async () => {
    if (selectedSchedule) {
      let scheduleId = selectedSchedule?.value;
      let mergedClassList = [];
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
      const response = await fetchData(url, "GET");
      mergedClassList = [...response.schedule_classlist, ...migrateList];
      let putUrl = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule/${scheduleId}`;
      await fetchData(putUrl, "PUT", { schedule_classlist: mergedClassList });
      getSchedules();
    }
  };

  function handleOption(option) {
    if (option === "new") {
      setIsNewSchedule(true);
    } else {
      setIsNewSchedule(false);
      getScheduleList();
    }
  }

  function handleSelectedSchedule(data) {
    setSelectedSchedule(data);
  }

  return (
    <>
      {!isMoved ? (
        <div className="delete-class-form">
          <div className="move-form-heading">
            Move {migrateList.length} selected class
            {migrateList.length > 1 && "es"}
          </div>
          <div className="delete-class-form-div">
            <label className="module-radio-label">
              <input
                type="radio"
                name="option"
                checked={isNewSchedule}
                className="filter-checkbox"
                onChange={() => handleOption("new")}
              />
              Move to a new schedule
            </label>
          </div>
          <div className="delete-class-form-div">
            <input
              ref={scheduleNameRef}
              className={`schedule-form-schedule ${
                !isNewSchedule ? "hide-schedule-selector" : undefined
              }`}
              value={scheduleName}
              placeholder="Schedule name (optional) ..."
              disabled={!isNewSchedule}
              onChange={(e) => setScheduleName(e.target.value)}
            />
          </div>
          <div className="delete-class-form-div">
            <label className="module-radio-label">
              <input
                type="radio"
                name="option"
                checked={!isNewSchedule}
                className="filter-checkbox"
                onChange={() => handleOption("existing")}
              />
              Move to an existing schedule (please select)
            </label>
          </div>
          <div>
            <Select
              ref={selectedScheduleRef}
              className={`schedule-form-schedule-selector ${
                isNewSchedule && "hide-schedule-selector"
              }`}
              value={selectedSchedule}
              placeholder="Choose schedule"
              isDisabled={isNewSchedule}
              options={scheduleOptions}
              onChange={(data) => handleSelectedSchedule(data)}
            />
          </div>
          <div
            className={`move-schedule-checkbox-div ${
              isNewSchedule && "hide-schedule-selector"
            }`}
          >
            <label htmlFor="clashCheck" className="filter-checkbox-label">
              <input
                type="checkbox"
                id="clashCheck"
                name="clashCheck"
                checked={runClashCheck}
                className="filter-checkbox"
                onChange={() => setRunClashCheck(!runClashCheck)}
              />
              Run clash check (teacher, classroom)
            </label>
          </div>
        </div>
      ) : (
        <p>Classes moved!</p>
      )}

      <div className="modal-button-div">
        {!isMoved ? (
          <>
            <button className="schedule-form-button" onClick={migrateClasses}>
              Move
            </button>
            <button
              className="schedule-form-button"
              onClick={() => setShowNewModal(false)}
            >
              Cancel
            </button>
          </>
        ) : (
          <button
            className="schedule-form-button"
            onClick={() => setShowNewModal(false)}
          >
            Close
          </button>
        )}
      </div>
    </>
  );
}
