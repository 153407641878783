import { useState, useEffect, useRef } from "react";
import DatePicker from "react-datepicker";
import { v4 as uuid } from "uuid";
import LevelSelector from "./LevelSelector.js";
import ProductSelector from "./ProductSelector.js";
import ClassroomSelector from "./ClassroomSelector.js";
import Select from "react-select";
import dayjs from "dayjs";
import { useUserSettings } from "../../auth/useUserSettings.js";
import fetchData from "./scripts/fetchData.js";
import "react-datepicker/dist/react-datepicker.css";
// import "./style/schedule.css";

export default function TeacherViewForm({
  centre,
  teacherSchedules,
  setShowNewModal,
}) {
  const today = dayjs(new Date()).format();
  const [startDate, setStartDate] = useState(dayjs(today).valueOf());
  const [endDate, setEndDate] = useState(dayjs(today).valueOf());
  const [centreCalendar, setCentreCalendar] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [scheduleDates, setScheduleDates] = useState([]);
  const [sessionList, setSessionList] = useState([]);
  const [selectedSession, setSelectedSession] = useState();
  const [timeslotOverlap, setTimeslotOverlap] = useState(false);
  const [savedSchedule, setSavedSchedule] = useState();
  const [saveMessage, setSaveMessage] = useState("");
  const [levelOptions, setLevelOptions] = useState([]);
  const [formData, setFormData] = useState({
    schedule_name: "",
    classroom: "",
    capacity: "",
    levels: "",
    products: "",
  });
  const [userSettings] = useUserSettings();
  const nameRef = useRef();

  function handleStartDate(dateObj, source) {
    if (source === "start") {
      setStartDate(dateObj);
      dateObj > endDate && setEndDate(dayjs(dateObj).endOf().valueOf());
    } else {
      setEndDate(dateObj);
      dateObj < startDate && setStartDate(dayjs(dateObj).startOf().valueOf());
    }
  }

  /* Get the selected centre's calendar to get the list of non-teaching days */
  const getCentreCalendar = async () => {
    if (centre) {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/calendar/${centre?.centre_code}`;
      const response = await fetchData(url, "GET");
      const disabledDateList = response.reduce((list, item) => {
        if (item.is_non_teaching) {
          for (let i = 0; i <= item.duration - 1; i++) {
            list.push(
              new Date(
                dayjs(item.start_date).add(i, "day").format("YYYY/MM/DD")
              )
            );
          }
        }
        return list;
      }, []);
      setDisabledDates(disabledDateList);
    }
  };

  /* Check for timeslot overlap and disable classroom if true */
  const checkTimeslotOverlap = () => {
    let ranges = teacherSchedules.map((item) => [
      item.day.value * 1000 + item.timeslot.value,
      item.day.value * 1000 + item.timeslot.value + item.duration.value,
    ]);
    ranges.sort((a, b) => a[0] - b[0]);
    for (let i = 0; i < ranges.length - 1; i++) {
      if (ranges[i][1] > ranges[i + 1][0]) {
        return true;
      }
    }
    return false;
  };

  /* Get centre list of date ranges as option */
  const getCentreSessionList = async () => {
    if (centre) {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/sessions/${centre?.centre_id}`;
      const response = await fetchData(url, "GET");
      setSessionList(
        response.map((option) => {
          return {
            value:
              dayjs(option.from_date).valueOf() +
              ":" +
              dayjs(option.to_date).valueOf(),
            label:
              option.session_name +
              " (" +
              dayjs(option.from_date).format("ddd") +
              " " +
              dayjs(option.from_date).format("DD/MM/YYYY") +
              " - " +
              dayjs(option.to_date).format("ddd") +
              " " +
              dayjs(option.to_date).format("DD/MM/YYYY") +
              ")",
          };
        })
      );
    }
  };

  /* Loop through teacherSchedules to create date array based on selected start and end dates */
  const getDays = () => {
    const days = teacherSchedules.map((item) => item.day.value);
    const uniqueDays = [...new Set(days)];
    //create array of dates between start and end date where day is in uniqueDays
    const dates = [];
    for (let i = startDate; i <= endDate; i += 86400000) {
      const day = dayjs(i).day();
      if (
        uniqueDays.includes(day) &&
        !disabledDates
          .map((date) => date.getTime())
          .includes(dayjs(i).valueOf())
      ) {
        dates.push(i);
      }
    }
    setScheduleDates(dates);
  };

  useEffect(() => {
    setTimeslotOverlap(!checkTimeslotOverlap());
  }, [teacherSchedules]);
  useEffect(() => {
    getDays();
  }, [startDate, endDate]);
  useEffect(() => {
    getCentreCalendar();
    getCentreSessionList();
  }, [centre]);
  useEffect(() => {
    if (selectedSession) {
      const [fromDate, toDate] = selectedSession.value.split(":");
      setStartDate(Number(fromDate));
      setEndDate(Number(toDate));
    }
  }, [selectedSession]);

  function handleFormData(data, formElement) {
    if (formElement.name) {
      setFormData((prevState) => ({
        ...prevState,
        [formElement.name]: data,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [formElement]: data,
      }));
    }
  }

  function createClassObject(item, schedule) {
    const dateStr = dayjs(item)
      .startOf("day")
      .add(schedule.timeslot.value, "minute")
      .format();
    return {
      temp_id: uuid(),
      centre,
      dateStr,
      timeslot: schedule.timeslot,
      duration: {
        value: schedule.duration.value,
        label: schedule.duration.value,
      },
      teacher: {
        value: schedule.teacher_id,
        label: `${schedule.first_name} ${schedule.last_name}`,
      },
      levels: formData.levels || "",
      products: formData.products || "",
      classroom: formData.classroom || "",
      capacity: formData.capacity || "",

      credit: "",
      isClosed: { value: false },
      contentType: "",
      contentLevel: "",
      module: "",
      pathway: "",
      theme: "",
      lesson: "",
      virtualCentres: "",
    };
  }

  function generateScheduleClassList() {
    return scheduleDates.flatMap((item) =>
      teacherSchedules
        .filter((schedule) => schedule.day.value === dayjs(item).day())
        .map((schedule) => createClassObject(item, schedule))
    );
  }

  async function saveSchedule(scheduleClassList) {
    const newSchedule = {
      centre: centre?.centre_code,
      schedule_name:
        formData.schedule_name ||
        `${centre?.centre_code}_schedule_${dayjs().format("YYYYMMDDHHmmss")}`,
      schedule_author: userSettings.username,
      schedule_classlist: scheduleClassList,
    };
    try {
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/schedule`;
      const response = await fetchData(url, "POST", newSchedule);
      setSavedSchedule(response.data);
      setSaveMessage(
        `Scehdule '${response.schedule_name}' (${scheduleClassList.length} classes) saved successfully.`
      );
    } catch (error) {
      console.error("Error saving schedule", error);
    }
  }

  function createSchedule() {
    if (!formData.schedule_name) {
      nameRef.current.focus();
      return;
    }
    const scheduleClassList = generateScheduleClassList();
    /* Check for past classes */
    const checkedScheduleClassList = scheduleClassList.filter((item) =>
      dayjs(item.dateStr).isAfter(dayjs(new Date()))
    );
    saveSchedule(checkedScheduleClassList);
  }

  const getLevelOptions = async () => {
    if (centre) {
      const centreId = centre?.centre_id;
      const url = `${process.env.REACT_APP_ROOT_URL}/schedule/obs/levels/${centreId}`;
      const response = await fetchData(url, "GET");
      if (response) {
        const levelList = response.data.map((option) => {
          return {
            value: option.level_code,
            label: option.level_name,
            level_id: option.level_id,
          };
        });
        setLevelOptions(levelList);
      }
    }
  };

  useEffect(() => {
    getLevelOptions();
  }, [centre]);

  return (
    <div>
      <h3>Create new schedule</h3>
      <div className="new-strand-form">
        <input
          ref={nameRef}
          name="schedule_name"
          id="schedule_name"
          maxLength={50}
          className="session-input"
          placeholder="New schedule name ..."
          value={formData.schedule_name}
          excludeDates={disabledDates}
          highlightDates={disabledDates}
          onChange={(e) => handleFormData(e.target.value, "schedule_name")}
          required
        />
        <div>
          <Select
            className="schedule-form-date-range-selector"
            value={selectedSession}
            placeholder="Select saved dates ... (Optional)"
            isSearchable
            isClearable
            options={sessionList}
            onChange={(data) => setSelectedSession(data)}
          />
        </div>
        <div className="schedule-form-class-div">
          <span className="teacher-from-label">From:</span>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={startDate}
            placeholderText="Start date"
            todayButton="Today"
            calendarStartDay={1}
            className="schedule-form-date teacher-datepicker"
            monthsShown={2}
            excludeDates={disabledDates}
            highlightDates={disabledDates}
            onChange={(startDate) =>
              handleStartDate(
                dayjs(startDate).startOf("date").valueOf(),
                "start"
              )
            }
          />
          <span className="teacher-to-label">To:</span>
          <DatePicker
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            placeholderText="End date"
            className="schedule-form-date teacher-datepicker"
            calendarStartDay={1}
            excludeDates={disabledDates}
            highlightDates={disabledDates}
            monthsShown={2}
            onChange={(endDate) =>
              handleStartDate(dayjs(endDate).endOf("date").valueOf(), "end")
            }
          />
        </div>
        <div className="teacher-classroom-div">
          <ClassroomSelector
            centre={centre}
            classObj={formData}
            handleClassObj={handleFormData}
            isLoaded={timeslotOverlap}
          />
          {!timeslotOverlap && (
            <p className="teacher-timeslot-overlap-text">Timeslot overlap!</p>
          )}
        </div>
        <div>
          <LevelSelector
            centre={centre}
            classObj={formData}
            handleClassObj={handleFormData}
            isLoaded={true}
          />
        </div>
        <div>
          <ProductSelector
            centre={centre}
            classObj={formData}
            handleClassObj={handleFormData}
            isLoaded={true}
          />
        </div>

        <div className="form-action-button-div">
          <button className="schedule-form-button" onClick={createSchedule}>
            Create schedule
          </button>
          <button
            className="schedule-form-button"
            onClick={() => setShowNewModal(false)}
          >
            Close
          </button>
        </div>
        {saveMessage && <p>{saveMessage}</p>}
      </div>
    </div>
  );
}
