import { useState, useEffect, useRef } from "react";
import { useUserSettings } from "../../auth/useUserSettings";
import Select from "react-select";
import getLessonFromCacheByCode from "./scripts/getLessonFromCacheByCode.js";
import fetchData from "./scripts/fetchData.js";

export default function NewStrandForm({
  setNewStrandCount,
  setShowNewModal,
  newStrandCount,
  strand_id,
  localeArray,
  levelOptions,
  showInvalidLessonWarning,
}) {
  const [formData, setFormData] = useState({
    strand_name: "",
    level: "",
    strand_type: 0,
  });
  const [copyStrand, setCopyStrand] = useState();
  const [lessonCodes, setLessonCodes] = useState();
  const [nameCharCount, setNameCharCount] = useState(0);
  const [userSettings] = useUserSettings();
  const nameRef = useRef();
  const typeRef = useRef();
  const levelRef = useRef();

  const getStrand = async () => {
    if (strand_id) {
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands/strand/${strand_id}`;
      const response = await fetchData(url, "GET");
      setCopyStrand(response);
    }
  };

  useEffect(() => {
    getStrand();
  }, [strand_id]);

  useEffect(() => {
    setNameCharCount(formData.strand_name?.value?.length);
  }, [formData]);

  const getNextStrandID = async () => {
    let url = `${process.env.REACT_APP_ROOT_URL}/schedule/last-strand-id`;
    const response = await fetchData(url, "GET");
    return response.strand_id;
  };

  function handleFormData(data, formElement) {
    setFormData((prevState) => ({
      ...prevState,
      [formElement.name]: data,
    }));
  }

  function getLessonCodeArray(str) {
    str = str.replaceAll("\n", ",");
    let newArray = [];
    let separatedArray = str.split(",");
    for (const item of separatedArray) {
      item.trim() && newArray.push(item.trim());
    }
    return newArray;
  }

  const getLessonsByCode = async (lessonCodeArray) => {
    if (lessonCodeArray) {
      let resultArray = [];
      let invalidList = [];
      for (const code of lessonCodeArray) {
        let lesson = getLessonFromCacheByCode(code);
        lesson ? resultArray.push(lesson.lesson_id) : invalidList.push(code);
      }
      return [resultArray, invalidList];
    }
  };

  const createNewStrand = async () => {
    if (!formData.strand_name) {
      nameRef.current.focus();
    } else if (!formData.strand_type) {
      typeRef.current.focus();
    } else if (!formData.level) {
      levelRef.current.focus();
    } else {
      let lessonList = [];
      if (strand_id) {
        lessonList.push(copyStrand?.lessons);
      } else if (lessonCodes) {
        let codes = getLessonCodeArray(lessonCodes);
        lessonList = await getLessonsByCode(codes);
      }
      console.log(lessonList);
      let lastID = await getNextStrandID();
      let newID = lastID + 1;
      let newStrand = {
        strand_name: formData.strand_name?.value,
        strand_code:
          localeArray[formData.strand_type?.value] +
          "-" +
          formData.level?.value +
          newID,
        strand_id: newID,
        strand_type: formData.strand_type?.value,
        strand_locale: localeArray[formData.strand_type?.value],
        level: formData.level?.value,
        createdBy: userSettings.username,
        lessons: lessonList[0] && lessonList[0],
      };
      let url = `${process.env.REACT_APP_ROOT_URL}/schedule/strands`;
      await fetchData(url, "POST", newStrand);
      setNewStrandCount(newStrandCount + 1);
      setShowNewModal(false);
      if (lessonList[1]?.length > 0) {
        showInvalidLessonWarning(lessonList[1]);
      }
    }
  };

  const strandTypeOptions = [
    { value: 3, label: "Centre" },
    { value: 2, label: "Country" },
  ];
  const clusterOption = { value: 1, label: "Cluster" };
  localeArray[1] !== "NA" && strandTypeOptions.push(clusterOption);
  const globalOption = { value: 0, label: "Global" };
  userSettings.roles.includes(5) && strandTypeOptions.push(globalOption);

  return (
    <div>
      <h3>{strand_id ? "Copy" : "New"} lesson group</h3>
      <div className="new-strand-form">
        {strand_id && (
          <input
            name="copy_from"
            className="schedule-form-title strand-form-title"
            disabled={true}
            value={copyStrand?.strand_code + ": " + copyStrand?.strand_name}
          />
        )}
        <input
          ref={nameRef}
          name="strand_name"
          id="strand_name"
          maxLength={30}
          className="session-input"
          placeholder="New lesson group name ..."
          value={formData.strand_name?.value}
          onChange={(e) =>
            handleFormData({ value: e.target.value }, { name: "strand_name" })
          }
          required
        />
        <div className="group-type-div">
          <Select
            ref={typeRef}
            className="schedule-form-strand-type-selector"
            value={formData.strand_type}
            placeholder="Type"
            cacheOptions
            options={strandTypeOptions}
            onChange={(data) => handleFormData(data, { name: "strand_type" })}
          />
          <div className="char-count-div">{nameCharCount || 0}/30</div>
        </div>
        <Select
          ref={levelRef}
          className="schedule-form-level-name-selector"
          value={formData.level}
          placeholder="Level"
          cacheOptions
          options={levelOptions}
          onChange={(data) => handleFormData(data, { name: "level" })}
        />
        {!strand_id && (
          <div>
            <textarea
              placeholder="Comma-separated lesson codes ... (optional)"
              rows="8"
              value={lessonCodes}
              className="schedule-form-strand-textarea session-input"
              onChange={(e) => setLessonCodes(e.target.value)}
            />
          </div>
        )}
        <div className="form-action-button-div">
          <button className="schedule-form-button" onClick={createNewStrand}>
            Create group
          </button>
          <button
            className="schedule-form-button"
            onClick={() => setShowNewModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}
