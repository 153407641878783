export default function obsClassLink(class_id, region) {
  if (class_id) {
    let classId = class_id.slice(3, -2);
    let url =
      region === "eu"
        ? process.env.REACT_APP_OBS_EU_URL
        : process.env.REACT_APP_OBS_EA_URL;
    let obsUrl = `${url}/classes/class-detail/${classId}`;
    window.open(obsUrl, "_blank", "noopener");
  }
}
