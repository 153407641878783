import queryString from "query-string";
import dayjs from "dayjs";
import fetchData from "./fetchData.js";

export default async function checkHistoryCache(
  checkList,
  countryId,
  startDate
) {
  const reqObj = {
    country_id: countryId,
    id: [],
    type: "lesson",
    from_date: dayjs(startDate).startOf("year").format("YYYY-MM-DD HH:mm:ss"),
  };
  let outputArray = [];
  let getArray = [];
  if (checkList.length > 0) {
    let lessonCache = [];
    let cache = JSON.parse(localStorage.getItem("histories"));
    for (const item of checkList) {
      if (cache) {
        lessonCache = cache.filter((h) => {
          return h.lesson_id === item.lesson_id;
        });
      }
      lessonCache.length > 0
        ? (outputArray = [...outputArray, ...lessonCache])
        : getArray.push(item);
    }
    if (getArray.length > 0) {
      let newCache = [];
      reqObj["id"] = [];
      for (const item of getArray) {
        reqObj["id"].push(item.lesson_id);
      }
      let url = `${
        process.env.REACT_APP_ROOT_URL
      }/schedule/obs/${countryId}/history?${queryString.stringify(reqObj, {
        arrayFormat: "bracket",
      })}`;
      const history = await fetchData(url, "GET");
      newCache = history.data;
      outputArray = [...outputArray, ...newCache];
      localStorage.setItem("histories", JSON.stringify(outputArray));
    }
    return outputArray;
  }
}
