import NewModalCloseButton from "./NewModalCloseButton";

export default function ValidationErrorList({ errors, setShowNewModal }) {
  // console.log(errors);
  return (
    <>
      <h2>Whoops!</h2>
      <p>You have the following validation errors.</p>
      <div className="modal-clashlist">
        {errors.a > 0 && (
          <div className="modal-clashlist-item">
            You have {errors.a} incomplete class{errors.a > 1 && "es"}.
          </div>
        )}
        {errors.b > 0 && (
          <div className="modal-clashlist-item">
            You have {errors.b} past class{errors.b > 1 && "es"}.
          </div>
        )}
        {errors.c > 0 && (
          <div className="modal-clashlist-item">
            You have {errors.c} published class{errors.c > 1 && "es"}. You
            cannot publish classes again.
          </div>
        )}
      </div>
      <div className="modal-button-div">
        <NewModalCloseButton setShowNewModal={setShowNewModal} />
      </div>
    </>
  );
}
