import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { nanoid } from "nanoid";
import "./style/selectors.css";

export default function AddTimeslot({ addTimeslot, userSettings, isEnabled }) {
  const [timeslotOptions, setTimeslotOptions] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [slot, setSlot] = useState({
    timeslot: "",
    duration: "",
  });
  const timeslotRef = useRef();
  const durationRef = useRef();
  const weekdays = [
    [1, "M", "Mon"],
    [2, "T", "Tue"],
    [3, "W", "Wed"],
    [4, "T", "Thu"],
    [5, "F", "Fri"],
    [6, "S", "Sat"],
    [0, "S", "Sun"],
  ];

  const handleSelectedDays = (checked, day, mode) => {
    if (mode === true) {
      if (checked === true) {
        setSelectedDays([...selectedDays, day]);
      } else {
        setSelectedDays((currentArray) =>
          currentArray.filter((val) => {
            return val !== day;
          })
        );
      }
    } else if (mode === false) {
      setSelectedDays([]);
    }
  };

  const showDays = weekdays.map((day) => {
    return (
      <div key={day[0]}>
        <input
          key={day[0]}
          type="checkbox"
          id={"TD" + day[0]}
          value={selectedDays.includes(day[0])}
          checked={selectedDays.includes(day[0])}
          disabled={!isEnabled}
          className="weekday-selector"
          onChange={(e) => handleSelectedDays(e.target.checked, day[0], true)}
        />
        <label htmlFor={"TD" + day[0]}>{day[1]}</label>
      </div>
    );
  });

  function handleSlotForm(data, formElement) {
    setSlot((prevState) => ({
      ...prevState,
      [formElement.name]: data,
    }));
  }

  function clearTimeslot() {
    setSlot({ ...slot, timeslot: "", duration: "" });
    handleSelectedDays(null, null, false);
  }

  function checkTimeslot() {
    if (selectedDays.length > 0) {
      if (!slot.timeslot) {
        timeslotRef.current.focus();
      } else if (!slot.duration) {
        durationRef.current.focus();
      } else {
        addAllTimeslots();
      }
    }
  }

  function addAllTimeslots() {
    let slotList = [];
    for (const day of selectedDays) {
      let dayList = [];
      weekdays.forEach((x) => dayList.push(x[0]));
      let dayIndex = dayList.indexOf(day);
      let slotCopy = { ...slot };
      slotCopy["id"] = nanoid();
      slotCopy["day"] = {
        value: day,
        label: weekdays[dayIndex][2],
      };
      slotList.push(slotCopy);
    }
    addTimeslot(slotList);
  }

  function createTimeSlotOptions(rangeStart, rangeEnd, interval) {
    let timeslotList = [];
    for (let i = rangeStart; i <= rangeEnd; i += interval) {
      let minutes = i % 60;
      let hours = Math.floor(i / 60);
      let timeslot = {
        value: i,
        label:
          hours.toString().padStart(2, "0") +
          ":" +
          minutes.toString().padStart(2, "0"),
      };
      timeslotList.push(timeslot);
    }
    setTimeslotOptions(timeslotList);
  }

  useEffect(() => {
    createTimeSlotOptions(
      userSettings.user_settings.first_timeslot,
      userSettings.user_settings.last_timeslot,
      userSettings.user_settings.timeslot_interval
    );
  }, []);

  return (
    <div className="selectors-div">
      <div className="weekday-selector">{showDays}</div>
      <Select
        ref={timeslotRef}
        className="schedule-form-timeslot-selector"
        value={slot.timeslot}
        placeholder="Time"
        cacheOptions
        // defaultOptions
        isSearchable
        isClearable
        options={timeslotOptions}
        onChange={(data) => handleSlotForm(data, { name: "timeslot" })}
      />
      <div>
        <input
          type="text"
          id="duration"
          ref={durationRef}
          className="schedule-form-duration"
          value={slot.duration?.value || ""}
          placeholder="Dur."
          onChange={(e) =>
            handleSlotForm(
              { value: Number(e.target.value) },
              { name: "duration" }
            )
          }
        />
      </div>
      <div>
        <button
          className="schedule-form-button"
          onClick={() => checkTimeslot()}
        >
          Add
        </button>
        <button
          className="schedule-form-button"
          onClick={() => clearTimeslot()}
        >
          Clear
        </button>
      </div>
    </div>
  );
}
